// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-details h1{
    font-size: 25px;
}

.email-highlight{
    color: #0B3763;
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/PaymentModal/PaymentModal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB","sourcesContent":[".payment-details h1{\n    font-size: 25px;\n}\n\n.email-highlight{\n    color: #0B3763;\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
