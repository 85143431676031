import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useState, useEffect } from "react";

const Testimonials = () => {

    const options = {
        items: 3,
        loop: true,
        smartSpeed: 1500,
        autoplay: true,
        dots: true,
        margin:24,
        nav: false,
        navText : ["<i class='ts ts-1 bx bxs-left-arrow-alt'></i>","<i class='ts ts-2 bx bxs-right-arrow-alt' ></i>"], 
        responsive:{
            0:{
                items:1,
                autoplay: true
            },
            480:{
                items:1,
                autoplay: true
            },
      
            768:{
                items:1
            },
            992:{
                items:2
            },
            1200:{
                items:3
            },
            1400:{
                items:3
            }
      
        }
            }



  const [testimonials, setTestimonials] = useState([])

  useEffect(() => {

    const content = [
        {
          name: "Rajib",
          image: "/assets/images/about/rajib.png",
          text: "Prateek's goal-oriented approach was absolutely phenomenal for the success of my Part 1 Exam! 😃 With his infectious enthusiasm, he kept me motivated throughout my preparation by conducting regular catchups! 💪 Any doubts I had were swiftly dealt with, leaving me feeling confident and ready to conquer the FRM.",
          stars: 5,
          description: "Cleared FRM Part 1"
        },
        {
          name: "Jayshree",
          image: "/assets/images/about/jayshree.png",
          text: "My journey with Prateek during FRM Exam Preparation was nothing short of transformative! With a busy schedule, the personalised planner tailored by Prateek became my roadmap to success! 🗺️ His unwavering support and disciplined approach kept me driven and confident throughout my preparation.",
          stars: 5,
          description: "Cleared FRM Part 1"
        },
        {
          name: "Lalit",
          image: "/assets/images/about/lalit.png",
          text: "Prateek's guidance during my preparation was instrumental in my FRM Part 1 success! 😃 His tailored planner and focused mock paper practice sessions were a differentiator for my results.",
          stars: 5,
          description: "Cleared FRM Part 1"
        },
        {
          name: "Subramaniam",
          image: "/assets/images/about/subramaniam.png",
          text: "Prateek's support was invaluable for my FRM Part 1 success! 😃 His personalised planner kept me on track, complementing my extensive questions practice and thorough study material coverage.",
          stars: 5,
          description: "Cleared FRM Part 1"
        },
        {
          name: "Sushil",
          image: "/assets/images/about/sushil.png",
          text: "I'm immensely grateful to Prateek for his personalised support during my exam journey! ⏰ He took the time to understand my commitments and strengths, and crafted a personalised planner for me.",
          stars: 5,
          description: "Cleared FRM Part 1"
        },
        {
          name: "Ansh",
          image: "/assets/images/about/ansh.png",
          text: "Prateek's guidance in solving mock papers and facilitating group study was pivotal in my FRM Part 1 success! 📝 Also, the support I obtained from the Telegram community of PyFin Academy played a key role in my path to success.",
          stars: 5,
          description: "Cleared FRM Part 1"
        },
        {
          name: "Tanmay",
          image: "/assets/images/about/tanmay.png",
          text: "Prateek's last-minute guidance was a lifesaver for me! 🙌 He directed me to focus on high-impact chapters first, optimising my time usage.",
          stars: 5,
          description: "Cleared FRM Part 1"
        }
      ];
    setTestimonials(content)
    setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
    }, 0);

  }, [])

  return (
    <div className="edu-testimonial-style">
      <div className="vactor-shape position-absolute d-none d-lg-block end-0 wow fadeInRight">
        <img src="/assets/images/education/shapes/edu-vactor4.svg" alt="Shape" />
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-4 col-xxl-12 col-lg-6">
            <h2 className="edu-section-title">Our Success Stories</h2>
          </div>
        </div>
        <div className="row pt-50">
          <div className="col-xxl-12 col-lg-12">
            {testimonials.length > 0 && (
            <OwlCarousel className="inner-slide-wrap owl-carousel" loop margin={10} autoplay {...options}>
              {testimonials.map((testimonial, index) => (
                <div className="single-client-testimonial text-center" key={index}>
                  <div className="client-testimonial-img">
                    <img src={testimonial.image} alt={testimonial.name} />
                  </div>
                  <div className="client-testimonial-info">
                    <h2>{testimonial.name}</h2>
                    <p>{testimonial.description}</p>
                  </div>
                  <div className="client-testimonial-rating">
                    <ul>
                      {[...Array(testimonial.stars)].map((star, i) => (
                        <li key={i}>
                          <a href="#"><i className="bi bi-star-fill"></i></a>
                        </li>
                      ))}
                    </ul>
                    <p>{testimonial.text}</p>
                  </div>
                </div>
              ))}
            </OwlCarousel>)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;




{/* <div class="edu-testimonial-style">
        <div class="vactor-shape position-absolute d-none d-lg-block end-0 wow fadeInRight" data-wow-duration="2000ms">
            <img src="{{asset('assets/images/education/shapes/edu-vactor4.svg')}}" alt="">
        </div>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xxl-4 col-xxl-12 col-lg-6 ">
                    <h2 class="edu-section-title">Our Success Stories</h2>
                </div>
            </div>
            <div class="row pt-50">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="inner-slide-wrap owl-carousel">
                        <div>
                            <div class="single-client-testimonial text-center">
                                <div class="client-testimonial-img">
                                    <img src="{{asset('assets/images/about/rajib.png')}}" alt="">
                                </div>
                                <div class="client-testimonial-info">
                                    <h2>Rajib</h2>
                                    <p>Cleared FRM Part 1</p>
                                </div>
                                <div class="client-testimonial-rating">
                                    <ul>
                                        <li><a href="#"><i class="bi bi-star-fill"></i></a></li>
                                        <li><a href="#"><i class="bi bi-star-fill"></i></a></li>
                                        <li><a href="#"><i class="bi bi-star-fill"></i></a></li>
                                        <li><a href="#"><i class="bi bi-star-fill"></i></a></li>
                                        <li><a href="#"><i class="bi bi-star-fill"></i></a></li>
                                    </ul>
                                    <p>Prateek's goal-oriented approach was absolutely phenomenal for the success of my Part 1 Exam! 😃 With his infectious enthusiasm, he kept me motivated throughout my preparation by conducting regular catchups! 💪 Any doubts I had were swiftly dealt with, leaving me feeling confident and ready to conquer the FRM. I can't recommend PyFin Academy enough - it's a game-changer! 🚀</p>
                                </div>
                            </div>
                        </div>
                        <div class="single-client-testimonial text-center">
                            <div class="client-testimonial-img">
                                <img src="{{asset('assets/images/about/jayshree.png')}}" alt="">
                            </div>
                            <div class="client-testimonial-info">
                                <h2>Jayshree</h2>
                                <p>Cleared FRM Part 1</p>
                            </div>
                            <div class="client-testimonial-rating">
                                <ul>
                                    <li><a href="#"><i class="bi bi-star-fill"></i></a></li>
                                    <li><a href="#"><i class="bi bi-star-fill"></i></a></li>
                                    <li><a href="#"><i class="bi bi-star-fill"></i></a></li>
                                    <li><a href="#"><i class="bi bi-star-fill"></i></a></li>
                                    <li><a href="#"><i class="bi bi-star-fill"></i></a></li>
                                </ul>
                                <p>My journey with Prateek during FRM Exam Preparation was nothing short of transformative! With a busy schedule, the personalised planner tailored by Prateek became my roadmap to success! 🗺️ His unwavering support and disciplined approach kept me driven and confident throughout my preparation, making FRM preparation a breeze! 📊 Prateek’s Planner based approach truly revamped my exam prep! 🎯</p>
                            </div>
                        </div>
                        <div class="single-client-testimonial text-center">
                            <div class="client-testimonial-img">
                                <img src="{{asset('assets/images/about/lalit.png')}}" alt="">
                            </div>
                            <div class="client-testimonial-info">
                                <h2>Lalit</h2>
                                <p>Cleared FRM Part 1</p>
                            </div>
                            <div class="client-testimonial-rating">
                                <ul>
                                    <li><a href="#"><i class="bi bi-star-fill"></i></a></li>
                                    <li><a href="#"><i class="bi bi-star-fill"></i></a></li>
                                    <li><a href="#"><i class="bi bi-star-fill"></i></a></li>
                                    <li><a href="#"><i class="bi bi-star-fill"></i></a></li>
                                    <li><a href="#"><i class="bi bi-star-fill"></i></a></li>
                                </ul>
                                <p>Prateek's guidance during my preparation was instrumental in my FRM Part 1 success! 😃 His tailored planner and focused mock paper practice sessions were a differentiator for my results. His enthusiasm kept me motivated, and his insights on important exam topics were invaluable. 💪 I highly recommend PyFin Academy for anyone who is looking to prepare for the FRM Exam! 🚀</p>
                            </div>
                        </div>
                        <div class="single-client-testimonial text-center">
                            <div class="client-testimonial-img">
                                <img src="{{asset('assets/images/about/subramaniam.png')}}" alt="">
                            </div>
                            <div class="client-testimonial-info">
                                <h2>Subramaniam</h2>
                                <p>Cleared FRM Part 1</p>
                            </div>
                            <div class="client-testimonial-rating">
                                <ul>
                                    <li><a href="#"><i class="bi bi-star-fill"></i></a></li>
                                    <li><a href="#"><i class="bi bi-star-fill"></i></a></li>
                                    <li><a href="#"><i class="bi bi-star-fill"></i></a></li>
                                    <li><a href="#"><i class="bi bi-star-fill"></i></a></li>
                                    <li><a href="#"><i class="bi bi-star-fill"></i></a></li>
                                </ul>
                                <p> Prateek's support was invaluable for my FRM Part 1 success! 😃 His personalised planner kept me on track, complementing my extensive questions practice and thorough study material coverage. 📚 Prateek's group study sessions fueled my determination, ensuring that my doubts were promptly addressed. 💪 PyFin Academy is truly a game-changer for FRM exam preparation - highly recommended to anyone looking for FRM Coaching!🏅
                                </p>
                            </div>
                        </div>
                        <div class="single-client-testimonial text-center">
                            <div class="client-testimonial-img">
                                <img src="{{asset('assets/images/about/sushil.png')}}" alt="">
                            </div>
                            <div class="client-testimonial-info">
                                <h2>Sushil</h2>
                                <p>Cleared FRM Part 1</p>
                            </div>
                            <div class="client-testimonial-rating">
                                <ul>
                                    <li><a href="#"><i class="bi bi-star-fill"></i></a></li>
                                    <li><a href="#"><i class="bi bi-star-fill"></i></a></li>
                                    <li><a href="#"><i class="bi bi-star-fill"></i></a></li>
                                    <li><a href="#"><i class="bi bi-star-fill"></i></a></li>
                                    <li><a href="#"><i class="bi bi-star-fill"></i></a></li>
                                </ul>
                                <p>I'm immensely grateful to Prateek for his personalised support during my exam journey! ⏰ He took the time to understand my commitments and strengths, and crafted a personalised planner for me. 💼 His constant motivation and doubt-clearing ensured disciplined adherence to the plan, leading to my successful exam clearance. 🌟 Thank you Prateek for your wonderful support! 🚀</p>
                            </div>
                        </div>
                        <div class="single-client-testimonial text-center">
                            <div class="client-testimonial-img">
                                <img src="{{asset('assets/images/about/ansh.png')}}" alt="">
                            </div>
                            <div class="client-testimonial-info">
                                <h2>Ansh</h2>
                                <p>Cleared FRM Part 1</p>
                            </div>
                            <div class="client-testimonial-rating">
                                <ul>
                                    <li><a href="#"><i class="bi bi-star-fill"></i></a></li>
                                    <li><a href="#"><i class="bi bi-star-fill"></i></a></li>
                                    <li><a href="#"><i class="bi bi-star-fill"></i></a></li>
                                    <li><a href="#"><i class="bi bi-star-fill"></i></a></li>
                                    <li><a href="#"><i class="bi bi-star-fill"></i></a></li>
                                </ul>
                                <p>Prateek's guidance in solving mock papers and facilitating group study was pivotal in my FRM Part 1 success! 📝 Also, the support I obtained from the Telegram community of PyFin Academy played a key role in my path to success. I am excited and looking forward to continuing this approach for Part 2! 👨‍🏫
                                </p>
                            </div>
                        </div>
                        <div class="single-client-testimonial text-center">
                            <div class="client-testimonial-img">
                                <img src="{{asset('assets/images/about/tanmay.png')}}" alt="">
                            </div>
                            <div class="client-testimonial-info">
                                <h2>Tanmay</h2>
                                <p>Cleared FRM Part 1</p>
                            </div>
                            <div class="client-testimonial-rating">
                                <ul>
                                    <li><a href="#"><i class="bi bi-star-fill"></i></a></li>
                                    <li><a href="#"><i class="bi bi-star-fill"></i></a></li>
                                    <li><a href="#"><i class="bi bi-star-fill"></i></a></li>
                                    <li><a href="#"><i class="bi bi-star-fill"></i></a></li>
                                    <li><a href="#"><i class="bi bi-star-fill"></i></a></li>
                                </ul>
                                <p>Prateek's last-minute guidance was a lifesaver for me! 🙌 He directed me to focus on high-impact chapters first, optimising my time usage. ⏳ His focused approach augmented by group study sessions turned out to be game changer for me!🎯 </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> */}