import React from 'react';

const PrimeFeatures = () => {
  return (
    <div className="prime-area trip-service-area positioning">
      <div className="container">
        <div className="row align-items-end">
          <div className="col-xxl-5 col-xl-6 col-lg-7 col-md-12">
            <div className="hrdd-section-title">
              <h1>Prime Features</h1>
            </div>
          </div>
        </div>
        <div className="row prime-cards-bg">
          <div className="col-xl-3 col-lg-4 col-md-6">
            <div className="trip-service-box text-center">
              <div className="trip-service-content">
                <h2>Performance Analysis</h2>
              </div>
              <div className="trip-service-icon">
                <img src="assets/images/pyfinimg/performance-analysis.png" alt="Performance Analysis" />
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6">
            <div className="trip-service-box text-center">
              <div className="trip-service-content">
                <h2>Meticulously Designed</h2>
              </div>
              <div className="trip-service-icon">
                <img src="assets/images/pyfinimg/expert-design.png" alt="Expert Design" />
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6">
            <div className="trip-service-box text-center">
              <div className="trip-service-content">
                <h2><a href="services.html">Flexibility</a></h2>
              </div>
              <div className="trip-service-icon">
                <img src="assets/images/pyfinimg/flexible.png" alt="Flexibility" />
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6">
            <div className="trip-service-box text-center">
              <div className="trip-service-content">
                <h2><a href="services.html">24X7 Whatsapp Support</a></h2>
              </div>
              <div className="trip-service-icon">
                <img src="assets/images/pyfinimg/whatsapp.png" alt="WhatsApp Support" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrimeFeatures;
