import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Defaults to localStorage for web
import authReducer from '../redux/auth/authSlice';
import answerReducer from '../redux/auth/answerSlice';
import programReducer from '../redux/auth/programSlice';
import paidProgramReducer from '../redux/auth/paidProgramSlice';
import redirectUrlReducer from '../redux/auth/redirectSlice';
import logger from 'redux-logger';
import { createTransform } from 'redux-persist';



// Unique persist configurations for each reducer
const authPersistConfig = { key: 'auth', storage, whitelist: ['auth'] };
const answerPersistConfig = { key: 'answers', storage, whitelist: ['answer'] };
const programPersistConfig = {
  key: 'programDetails',
  storage,
  whitelist: ['programName']
};
const redirectUrlPersistConfig = { key: 'redirectUrl', storage, whitelist: ['redirectUrl']};
const paidProgramPersistConfig = { key: 'paidPrograms', storage, whitelist: ['paidPrograms'] };

const persistedAuthReducer = persistReducer(authPersistConfig, authReducer);
const persistedAnswerReducer = persistReducer(answerPersistConfig, answerReducer);
const persistProgramDetails = persistReducer(programPersistConfig, programReducer);
const persistPaidProgramReducer = persistReducer(paidProgramPersistConfig, paidProgramReducer);
const persistRedirectUrlReducer = persistReducer(redirectUrlPersistConfig, redirectUrlReducer);

const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    answer: persistedAnswerReducer,
    programDetails: persistProgramDetails,
    paidPrograms: persistPaidProgramReducer,
    redirectUrl: persistRedirectUrlReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export const persistor = persistStore(store);
export default store;
