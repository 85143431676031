import { fetchUtils } from 'ra-core';
import { stringify } from 'query-string';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;
const apiUrl = `${baseUrl}/api/admin`; // Backend API base URL
// const httpClient = fetchUtils.fetchJson;
const httpClient = (url, options = {}) => {
  const token = localStorage.getItem('authToken'); // Retrieve the token from local storage

  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  
  // Add Authorization header with Bearer token
  options.headers.set('Authorization', `Bearer ${token}`);

  return fetchUtils.fetchJson(url, options);
};

const dataProvider = {
  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const query = {
      _sort: field,
      _order: order,
      _page: page,
      _limit: perPage,
      ...params.filter,
    };

    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    const { headers, json } = await httpClient(url);
    console.log("This is the response JSON:", json);
    return {
      data: json.data,
      total: parseInt(headers.get('x-total-count'), 10),
    };
  },

  getOne: async (resource, params) =>{
    const url = `${apiUrl}/${resource}/${params.id}`
    const { json } = await httpClient(url);
    console.log("JSON:", json);
    return {
      data: json.data,
    };
  },

  getMany: (resource, params) => {
    const query = {
      id: params.ids,
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const query = {
      _sort: field,
      _order: order,
      _page: page,
      _limit: perPage,
      ...params.filter,
      [params.target]: params.id,
    };

    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ headers, json }) => ({
      data: json.data,
      total: parseInt(headers.get('x-total-count'), 10),
    }));
  },

  update: async (resource, params) => {
    const response = await httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: 'PUT',
        body: JSON.stringify(params.data),
    });
    
    return { data: response.json };
  },


  updateMany: (resource, params) => {
    const query = {
      id: params.ids,
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  create: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    })),

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json })),

  deleteMany: (resource, params) => {
    const query = {
      id: params.ids,
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json }));
  },
};

export default dataProvider;