import React, { useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import CardList from './components/Cards/Cards';
import FRM_I_Test_Series from './components/FRM_I_Integerated_Test_Series/FRM_I_Test_Series';
import HomePage from './components/Home/HomePage';
import NavBar from './components/NavBar/NavBar';
import HeadComponent from './components/Partials/HeadComponent';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'boxicons/css/boxicons.min.css';
import TimerCard from './components/TimerCard/TimerCard';
import WebinarCard from './components/WebinarCard/WebinarCard';
import IntegeratedTestSeries from './components/Integerated-test-series/Integerated-test-series';
import LoginPage from './components/LoginPage/LoginPage';
import Settings from './components/Settings/Settings';
import Profile from './components/Profile/Profile';
import HeadingCarousal from './components/HeadingCarousal/HeadingCarousal';
import TestPage from './components/TestPage/TestPage';
import TestStartPage from './components/TestStartPage/TestStartPage';
import ResultsPage from './components/ResultsPage/ResultsPage';
import TestListPage from './components/TestListPage/TestListPage';
import FRM_II_Test_Series from './components/FRM_II_Integerated_Test_Series/FRM_II_Test_Series';
import NavBarLogin from './components/NavBarLogin/NavBarLogin';
import FoundationOfRiskManagement from './components/FoundationOfRiskManagement/FoundationOfRiskManagement';
import GoogleCallback from './components/GoogleCallback/GoogleCallback';
import Signup from './components/Signup/Signup';
import { useDispatch, useSelector } from 'react-redux';
import { clearAuthState } from './redux/auth/authSlice';
import AdminApp from './components/Admin/AdminApp';

const user = {
  'email': 'akshat@gmail.com',
  'otp_verified': 'Yes',
  'name': 'Akshat',
  'user_name': 'Akshat',
  'contact_number': '12345',
  'logindate': '2024/10/25',
  'country': 'India',
  'exam_date': '2024/10/20',
  'profile_image': null,
}

const purchasedPrograms = [
  {
    'exam_id': '1', 'program_name': 'FRM I Integrated Test Series', 'program_cost': '4500', 'joindate': '30-1-2014',
  }
]

const otherPrograms = [
  {
    'exam_id': '1', 'program_name': 'FRM I Integrated Test Series', 'program_cost': '4500', 'joindate': '30-1-2014',
  },
  {
    'exam_id': '2', 'program_name': 'Foundations of Risk Management', 'program_cost': '4500', 'joindate': '30-1-2014',
  },
  {
    'exam_id': '3', 'program_name': `Quantitative ${'\n'} Analysis`, 'program_cost': '4500', 'joindate': '30-1-2014',
  },
  {
    'exam_id': '4', 'program_name': 'Financial Markets & Products', 'program_cost': '4500', 'joindate': '30-1-2014',
  },
  {
    'exam_id': '5', 'program_name': 'Valuation and Risk Models', 'program_cost': '4500', 'joindate': '30-1-2014',
  }
]

const module = {
  id: 1,
  module_name: 'Introduction to React',
  program: {
    id: 101,
    program_name: 'Web Development'
  }
};

const count = 20; // Number of questions
const timerDuration = 30;

const App = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  useEffect(() => {
    const handleBeforeUnload = () => {
      dispatch(clearAuthState());
    }

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  }, [dispatch]);

  return (
    <div className="App" style={{ "background-color": "#f7f8f9" }}>
      <Router>
        <AppContent isLoggedIn={isLoggedIn} user={user} />
      </Router>
    </div>
  );
}

const AppContent = ({ isLoggedIn, user }) => {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/admin');

  return (
    <>
      
      {!isAdminRoute ? !isLoggedIn ? <NavBar /> : <NavBarLogin user={user} />: <></>}
      <Routes>
        <Route path="/" element={isLoggedIn ? <Navigate to='/public/profile' /> : <HomePage />} />
        <Route path="frm-i/frm-i-integrated-test-series" element={<FRM_I_Test_Series />} />
        <Route path="frm-ii/frm-ii-integrated-test-series" element={<FRM_II_Test_Series />} />
        <Route path="frm-i/foundations-of-risk-management" element={<FoundationOfRiskManagement />} />
        <Route path="integrated-test-series" element={<IntegeratedTestSeries />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="signup" element={<Signup />} />
        <Route path="/auth/google/callback" element={<GoogleCallback />} />
        <Route path="public/profile" element={<Profile otherPrograms={otherPrograms} />} />
        <Route path="public/setting/edit" element={<Settings user={user} />} />
        <Route path="public/frm-i/frm-i-integrated-test-series/all/:id" element={<TestListPage />} />
        <Route path="public/frm-i/frm-i-integrated-test-series/launch/test/:moduleId" element={<TestStartPage module={module} count={count} timerDuration={timerDuration} />} />
        <Route path="public/frm-i/frm-i-integrated-test-series/:moduleId" element={<TestPage />} />
        <Route path="public/frm-i/frm-i-integrated-test-series/:moduleId/submit" element={<ResultsPage />} />
        <Route path="/admin/*" element={
          <Suspense fallback={<div>Loading Admin Dashboard...</div>}>
            <AdminApp />
          </Suspense>
        } />
      </Routes>
    </>
  );
}

export default App;