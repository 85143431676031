import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import Footer2 from '../Footer/Footer2';
import { useNavigate, useParams } from 'react-router-dom';
import "katex/dist/katex.min.css";
import parse from "html-react-parser";
import axios from 'axios';
import MathJaxRenderer from '../MathJaxRenderer/MathJaxRenderer';
import { useDispatch, useSelector } from 'react-redux';
import { setAnswer } from '../../redux/auth/answerSlice';
import style from './TestPage.module.css'
import './TestPage.css';

export const TestPage = () => {
  const { examType, seriesType, id, moduleId } = useParams(); 
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const baseUrl = process.env.REACT_APP_BASE_URL
  const answers = useSelector((state) => state.answer.answer)
  const programName = useSelector((state) => state.programDetails.programName)
  const [timerDuration, setTimerDuration] = useState(0)
  const [moduleData, setModuleData] = useState({})
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showQuestionsMenu, setShowQuestionsMenu] = useState(false)
  const [timer, setTimer] = useState(0)

  useEffect(() => {
    const fetchQuestions = async () => {
      try{

        const token = localStorage.getItem('authToken');
        if(!token)
        {
          console.log('Token not found');
        }
        console.log('Module ID:', moduleId)
        console.log('Before making API call')
        const [ questionsResponse, moduleInfoResponse ] = await Promise.all([
          axios.get(`${baseUrl}/api/module/${moduleId}/questions`, {
            headers: {
              'x-auth-token': token,
            }
          }),
          axios.get(`${baseUrl}/api/module/${moduleId}/info`, {
            headers: {
              'x-auth-token': token,
            }
          })
        ])
        console.log('questions:', questionsResponse)
        console.log('module info:', moduleInfoResponse)
        const questionsData = await questionsResponse.data
        const moduleData = await moduleInfoResponse.data

        const sortedQuestionsData = [...questionsData].sort((a, b) => a.id - b.id);
        
        setQuestions(sortedQuestionsData);
        setModuleData(moduleData)
        console.log('questions:', sortedQuestionsData);
        console.log('Info:', moduleData);
        setTimerDuration(moduleData.duration)
      } catch (err) {
        console.log(err)
      }
    }
    fetchQuestions();
  }, [])

  useEffect(() => {
    if (timerDuration > 0) {
      // If no existing endTime in localStorage, calculate a new one
      if (!localStorage.getItem('endTime')) {
        const endTime = new Date().getTime() + timerDuration * 1000;
        localStorage.setItem('endTime', new Date(endTime).toISOString());
      }
  
      // Initialize the timer based on either the stored endTime or the timerDuration
      const storedEndTime = new Date(localStorage.getItem('endTime'));
      const remainingTime = Math.floor((storedEndTime - new Date()) / 1000);
  
      setTimer(remainingTime > 0 ? remainingTime : 0);
  
      // Start countdown
      const interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer > 0) {
            return prevTimer - 1;
          } else {
            clearInterval(interval);
            navigate(`/${examType}/${seriesType}/${id}/${moduleId}/submit`);
            return 0;
          }
        });
      }, 1000);
  
      return () => {
        clearInterval(interval);
        localStorage.removeItem('endTime');
      };
    }
  }, [timerDuration, navigate]);



  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const toggleShowQuestionsMenu = () => {
    setShowQuestionsMenu(!showQuestionsMenu);
  }

  const handleOptionChange = (questionId, option) => {
    // setAnswers(prevAnswers => ({
    //   ...prevAnswers,
    //   [questionId]: option
    // }));
    // console.log(answers)
    const answers = {questionId, option}
    dispatch(setAnswer({...answers}))
    console.log(answers)
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(prevIndex => (prevIndex < questions.length - 1 ? prevIndex + 1 : prevIndex));
  };

  const handlePrevQuestion = () => {
    setCurrentQuestionIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  const handleQuestionClick = (index) => {
    setCurrentQuestionIndex(index);
    // console.log(answers);
  };

  const handleSubmitExam = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Once submitted, you cannot change your answers.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, submit it!'
    }).then((result) => {
      if (result.isConfirmed) {
        // Submit exam logic here (e.g., API call)
        Swal.fire('Submitted!', 'Your exam has been submitted.', 'success');
        // console.log('Exam data:', answers);
        navigate(`/${examType}/${seriesType}/${id}/${moduleId}/submit`);
      }
    });
  };

  return (
    <div className="test-page" style={{'background-color':'#ffffff'}}>
      {/* NavBar */}

        {/* Check this code below */}
        <div class="view-all-question">
            <button class={showQuestionsMenu ? "view-all-questions show-questions" : "view-all-questions"} id="view-hide-questions" onClick={toggleShowQuestionsMenu}>{!showQuestionsMenu ? 'Show All Questions' : 'Hide All Questions'}</button> { /* CSS 1 */ }
        </div>

      <div className="edu-hero-style"> { /* CSS 2 */ }
        <div className="container"> { /* CSS 3 */ }
           <div class="justify-content-center align-items-center test-start-hero-content"> { /* CSS 4 */ }
                <div class="col-lg-12 col-md-10">
                    <div className="edu-hero-content"> { /* CSS 5 */ }
                        <h2 class="edu-section-title text-center"> { /* CSS 6 */ }
                            {programName} - {moduleData.module_name}
                        </h2>
                        <div className="test-start-subheding"> { /* CSS 7 */ }
                            <div className="test-subhedings"> { /* CSS 8 */ }
                                <ul>
                                    <li className="text-center">
                                        <i className="bi bi-clock"></i> Time Remaining: <span id="timer">{formatTime(timer)}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      

      {/* Questions Section */}
        {questions.length > 0 && <div className="mt-50">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="test-questions"> { /* CSS 9 */ }
                        <div className="question-container">
                            <p className={style.fullWidthText}> { /* CSS 10 */ }
                            Q.{currentQuestionIndex + 1}. <MathJaxRenderer content={questions[currentQuestionIndex].question}/>
                            {/* {questions[currentQuestionIndex].question} */}
                            </p>
                            <div className="options"> { /* CSS 11 */ }
                            {['A', 'B', 'C', 'D'].map((option) => 
                               <div key={option}>
                                <label key={option} className="option"> { /* CSS 12 */ }
                                <input
                                    key={`${questions[currentQuestionIndex].id}_${option}`}
                                    type="radio"
                                    name={`answer_${questions[currentQuestionIndex].id}`}
                                    value={option}
                                    checked={answers[questions[currentQuestionIndex].id] === option}
                                    onChange={() => handleOptionChange(questions[currentQuestionIndex].id, option)}
                                />
                                <span key={`${questions[currentQuestionIndex].id}_${option}_span_option`}>{option}</span>. <span key={`${questions[currentQuestionIndex].id}_${option}_span`}> {questions[currentQuestionIndex][`option_${option}`]} </span>
                                </label>
                              </div>
                             
                            )}
                            </div>
                        </div>
                        </div>

                        <div className="previous-next-btn"> { /* CSS 13 */ }
                            <div className='prev-next'>
                                <button id="prev-question" onClick={handlePrevQuestion} disabled={currentQuestionIndex === 0}>
                                &lt;&lt; Previous
                                </button>
                                <button id="next-question" onClick={handleNextQuestion} disabled={currentQuestionIndex === questions.length - 1}>
                                Next &gt;&gt;
                                </button>
                            </div>
                            <div className="submit-exam-btn"> { /* CSS 14 */ }
                            <button onClick={handleSubmitExam}>Submit Exam</button>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <div className="select-questions">
                            <div className={`chose-question-card ${showQuestionsMenu ? 'active' : ''}`}> { /* CSS 15 */ }
                                <div className="questions-heading">
                                <button className="close-all-buttons" onClick={toggleShowQuestionsMenu}>&times;</button> { /* CSS 16 */ }
                                <h5 className="select-questions-heading">Questions</h5> { /* CSS 17 */ }
                                </div>
                                <div className="chose-questions-grid"> { /* CSS 18 */ }
                                {questions.map((question, index) => (
                                    <button
                                  
                                    key={question.id}
                                    className={`choose-question-btn 
                                        ${currentQuestionIndex === index ? 'highlight-border' : ''}
                                        ${answers[questions[index].id] ? 'selected-question' : ''}`
                                      
                                      }
                                    onClick={() => handleQuestionClick(index)}
                                    > { /* CSS 19 */ }
                                    {index + 1}
                                    </button>
                                ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>}
        <Footer2/>
    </div>
  );
};

export default TestPage;
