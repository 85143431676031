import React from 'react';
import { Carousel, Button } from 'react-bootstrap';
import './HeadingCarousal.css'; // Custom CSS file

const items = [
    {
      image: '/assets/images/carousal-images/business-meeting.jpg',
      heading: 'GARP Risk Career Center',
      paragraph: 'Connecting top talent with the best available risk management opportunities.',
    },
    {
      image: '/assets/images/carousal-images/business-meeting.jpg', // Another image link
      heading: 'Slide 2',
      paragraph: 'This is the second slide',
    },
    {
      image: '/assets/images/carousal-images/business-meeting.jpg', // Another image link
      heading: 'Slide 3',
      paragraph: 'This is the third slide',
    },
  ];  


const CarouselComponent = () => {
  return (
    <Carousel interval={4000}>
      {items.map((item, index) => (
        <Carousel.Item key={index} className={`carousel-item-${index}`}>
          <img
            className="d-block w-100"
            src={item.image}
            alt={`Slide ${index}`}
          />
          <Carousel.Caption>
            <h3>{item.heading}</h3>
            <p>{item.paragraph}</p>
            <Button href='#' className="btn btn-primary">
              Explore Now
            </Button>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default CarouselComponent;
