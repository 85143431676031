import React from 'react'

function Footer2() {
  return (
    <div className="footer-area">
        <div className="container">       
            <div className="col-md-12 col-sm-12 col-12">
                <div className="trip-section-title">
                    <p className="footer-content">About <a href="#"> Risk Hub</a>: A learning ecosystem to clear
                        FRM exam - powered by Personalised Guidance and Community Support
                    </p>
                    <ul className="footer-social-links d-flex gap-3 ">
                        <li className="social-icons"><a href="http://t.me/FRM_I" target="_blank"><i className="bi bi-telegram"></i></a></li>
                        <li className="social-icons"><a href="https://www.linkedin.com/in/prateek964/" target="_blank"><i className="bi bi-linkedin"></i></a></li>
                    </ul>
                </div>
                <hr/>
                {/* <hr style={{height: "2px", color: "black"}}/> */}
                <p>Copyright © 2024 <a href="#"> Risk Hub.</a></p>
                {/* <p>Copyright © {{ now()->year }} <a href="{{route('home')}}" style="color: #0b3763;"> Risk Hub.</a></p> */}
            </div>
        </div>
    </div>
  )
}

export default Footer2
