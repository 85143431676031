import axios from "axios";
import { useEffect, useState } from "react";
import { Create, NumberInput, SelectInput, SimpleForm, TextInput } from "react-admin";


const TransactionCreate = () => {
    
    const [programs, setPrograms] = useState([]);
    const baseUrl = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        const fetchPrograms = async () => {
            try{
                const response = await axios.get(`${baseUrl}/api/users/all`);
                const programs = response.data.programs;
                setPrograms(programs);
            } catch(error) {
                console.error(error);
            }
        }

        fetchPrograms();

    }, []);

    return (
    <Create>
        <SimpleForm>
            <TextInput source="email" label="Email" required/>
            <SelectInput 
                source="program_id"
                label="Program"
                choices={programs}
                optionText="program_name"
                optionValue="id"
                required
            />
            <SelectInput 
                source="payment_status"
                label="Payment Status"
                choices={[
                    {'id': 'paid', 'name': 'Paid'},
                    {'id': 'pending', 'name': 'Pending'},
                    {'id': 'failed', 'name': 'Failed'}
                ]}
            />
            <NumberInput source="amount" label="Amount"/>
        </SimpleForm>
    </Create>
    )
}

export default TransactionCreate;