import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import Footer2 from '../Footer/Footer2';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import './TestListPage.css';

const TestListPage = () => {
  const { id } = useParams()
  const [testModules, setTestModules] = useState([])
  const [quizModules, setQuizModules] = useState([])
  const [testSeriesName, setTestSeriesName] = useState('');
  const [examName, setExamName] = useState('')
  const { examType, seriesType } = useParams()
  const baseUrl = process.env.REACT_APP_BASE_URL
  const user = useSelector((state) => state.auth.user)

  useEffect(() => {
    const fetchModules = async () => {
      const token = localStorage.getItem('authToken')

      if(!token){
        console.log('Token not found')
      }

      try{
        const [testResponse, quizResponse] = await Promise.all([
          axios.get(`${baseUrl}/api/module/all`, 
            {
              params: {
                'program_id':id,
                'module_type':'test' 
              }, 
              headers: {
                'x-auth-token': token,
              }
            }),
          axios.get(`${baseUrl}/api/module/all`,
            {
              params: {
                'program_id':id,
                'module_type':'quiz' 
              }, 
              headers: {
                'x-auth-token': token,
              }
            }),
        ])
        const [testData, quizData] = await Promise.all([testResponse.data, quizResponse.data])
        setTestModules(testData)
        setQuizModules(quizData)
        console.log('test data:', testData)
        console.log('quiz data:', quizData)
        // console.log('module id check:', testData[0].module_id)
      }catch(error){
        console.error(error)
      }
    }
    if(examType === "frm-i")
    {
      setTestSeriesName("FRM I Test Series")
      setExamName("FRM I")
    } else if(examType === "frm-ii") {
      setTestSeriesName("FRM II Test Series")
      setExamName("FRM II")
    } else if(examType === "scr") {
      setTestSeriesName("SCR Test Series")
      setExamName("SCR")
      }
    fetchModules()
  }, [])


  // State for load more/less functionality
  const [showAllTests, setShowAllTests] = useState(false);
  const [showAllQuizzes, setShowAllQuizzes] = useState(false);
  const navigate = useNavigate();

  const handleToggleTests = () => setShowAllTests(!showAllTests);
  const handleToggleQuizzes = () => setShowAllQuizzes(!showAllQuizzes);

  const handleReAttempt = (event, module) => {
    event.preventDefault();
    Swal.fire({
      title: 'Are you sure?',
      text: `Do you really want to reattempt ${module?.module_name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, reattempt it!',
      cancelButtonText: 'No, cancel!'
    }).then((result) => {
      if (result.isConfirmed) {
        // Redirect or perform action
        console.log(`Reattempting ${module?.module_name}`);
        navigate(`/public/${examType}/${seriesType}/launch/test/${id}/${module.module_id}`)
      }
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString)

    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = String(date.getFullYear())
    return `${day}-${month}-${year}`
  }

  return (

    <>
    <div className="edu-hero-style"> { /* CSS 1 */ }
      <div className="container"> { /* CSS 2 */ }
        {/* Breadcrumb navigation */}
        <div className="sub-menu-bar all-test-navtags">
          <ul className="nav-tags">
            <li><a className="active"><Link to='/public/profile'>Home &gt; </Link></a></li>
            <li>{examName} &gt; </li>
            <li>{testSeriesName}</li>
          </ul>
          <div className="date-time-section">
            <ul>
              <li>Exam Date: {user?.exam_date ? new Date(user.exam_date).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }) : ' '}</li>

              <li>Days Remaining: {user?.days_remaining}</li>
            </ul>
          </div>
        </div>

        <h2 className="edu-section-title text-start">My Tests</h2>
        <div className="row justify-content-lg-between align-items-center table-bg"> { /* CSS 5 */ }
          <div className="col-lg-12">
            <div className="card-body">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="table-responsive border-bottom table-text"> { /* CSS 6 */ }
                            <table className="table table-striped"> { /* CSS 7 */ }
                                <thead className="my-test-table-head"> { /* CSS 8 */ }
                                <tr>
                                    <th scope="col">Tests</th>
                                    <th scope="col">Marks</th>
                                    <th scope="col">Percentile</th>
                                    <th scope="col">Rank</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Action</th>
                                </tr>
                                </thead>
                                <tbody id="test-modules">
                                {testModules.slice(0, showAllTests ? testModules.length : 3).map((module, index) => (
                                    <tr className="table-active init-visible" key={index}>
                                    <th scope="row">{module.module_name}</th>
                                    {module.result ? (
                                          <>
                                            <td>{module.result.marks}</td>
                                            <td>{module.result.percentile}</td>
                                            <td>{module.result.rank}</td>
                                            <td>{formatDate(module.result.attended_date)}</td>
                                            <td>
                                            <a
                                                onClick={(event) => handleReAttempt(event, module)}
                                                className="re-attempt-btn retestbtn"
                                            > { /* CSS 9 */ }
                                                Re-Attempt
                                            </a>
                                            </td>
                                            </>
                                    ) : (
                                        <>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>
                                            <a className="re-attempt-btn"><Link to={`/public/${examType}/${seriesType}/launch/test/${id}/${module.module_id}`}>Attempt</Link></a>
                                        </td>
                                        </>
                                    )}
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div className="load-more-test-btns"> { /* CSS 10 */ }
            <button onClick={handleToggleTests} className="load-more-test-btn"> { /* CSS 11 */ }
              {showAllTests ? 'Load Less' : 'Load More'}
              <i className={`bi bi-chevron-${showAllTests ? 'up' : 'down'}`}></i>
            </button>
          </div>
        </div>

        {/* My Quizzes section */}
        <div className="mt-50">
            <div className="container">
                <h2 className="edu-section-title text-start">My Quizzes</h2>
                <div className="row justify-content-lg-between align-items-center table-bg">
                    <div className="col-lg-12">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                <div className="card">
                                    <div className="table-responsive border-bottom table-text">
                                    <table className="table table-striped">
                                        <thead className="my-test-table-head">
                                        <tr>
                                            <th scope="col">Quiz</th>
                                            <th scope="col">Marks</th>
                                            <th scope="col">Percentile</th>
                                            <th scope="col">Rank</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                        </thead>
                                        <tbody id="quiz-modules">
                                        {quizModules.slice(0, showAllQuizzes ? quizModules.length : 3).map((module, index) => (
                                            <tr className="table-active init-visible" key={index}>
                                            <th scope="row">{module.module_name}</th>
                                            {module.result ? (
                                                  <>
                                                    <td>{module.result.marks}</td>
                                                    <td>{module.result.percentile}</td>
                                                    <td>{module.result.rank}</td>
                                                    <td>{formatDate(module.result.attended_date)}</td>
                                                    <td>
                                                    <a
                                                        onClick={(event) => handleReAttempt(event, module)}
                                                        className="re-attempt-btn retestbtn"
                                                    >
                                                        Re-Attempt
                                                    </a>
                                                    </td>
                                                  </>

                                            ) : (
                                                <>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>
                                                    <a className="re-attempt-btn"><Link to={`/public/${examType}/${seriesType}/launch/test/${id}/${module.module_id}`}>Attempt</Link></a>
                                                </td>
                                                </>
                                            )}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="load-more-test-btns">
                        <button onClick={handleToggleQuizzes} className="load-more-test-btn">
                        {showAllQuizzes ? 'Load Less' : 'Load More'}
                        <i className={`bi bi-chevron-${showAllQuizzes ? 'up' : 'down'}`}></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    <Footer2/>
    </>
  );
};

export default TestListPage;