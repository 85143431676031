import React, { useEffect, useState } from 'react'
import Footer2 from '../Footer/Footer2'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import FRMCard from '../Integerated-test-series/FRMCard/FRMCard'
import './TestSeries.css'
import { FaLock } from 'react-icons/fa'
import axios from 'axios'
import Swal from 'sweetalert2'
import style from './TestSeries.module.css';
import { clearAnswer } from '../../redux/auth/answerSlice'
import LoginPage from '../LoginPage/LoginPage'
import Signup from '../Signup/Signup'
import { setRedirectUrl } from '../../redux/auth/redirectSlice'
import { setProgramDetails } from '../../redux/auth/programSlice'
import PaymentModal from '../PaymentModal/PaymentModal'


function TestSeries() {

    const test = [
        {module_id: 1, module_name: 'Test 1', program_id: 1},
        {module_id: 2, module_name: 'Test 2', program_id: 1},
        {module_id: 3, module_name: 'Test 3', program_id: 1},
        {module_id: 4, module_name: 'Test 4', program_id: 1},
        {module_id: 5, module_name: 'Test 5', program_id: 1},
        {module_id: 6, module_name: 'Test 6', program_id: 1},
        {module_id: 7, module_name: 'Test 7', program_id: 1},
        {module_id: 8, module_name: 'Test 8', program_id: 1},
        {module_id: 9, module_name: 'Test 9', program_id: 1},
        {module_id: 10, module_name: 'Test 10', program_id: 1},
    ]

    const quiz = [
        {module_id: 1, module_name: 'Quiz 1', program_id: 1},
        {module_id: 2, module_name: 'Quiz 2', program_id: 1},
        {module_id: 3, module_name: 'Quiz 3', program_id: 1},
        {module_id: 4, module_name: 'Quiz 4', program_id: 1},
        {module_id: 5, module_name: 'Quiz 5', program_id: 1},
        {module_id: 6, module_name: 'Quiz 6', program_id: 1},
        {module_id: 7, module_name: 'Quiz 7', program_id: 1},
        {module_id: 8, module_name: 'Quiz 8', program_id: 1},
        {module_id: 9, module_name: 'Quiz 9', program_id: 1},
        {module_id: 10, module_name: 'Quiz 10', program_id: 1},
    ]

    const { id } = useParams()
    // if(!user){
    // const [testModules, setTestModules] = useState(test)
    // const [quizModules, setQuizModules] = useState(quiz)
    // }else{
    const [testModules, setTestModules] = useState([])
    const [quizModules, setQuizModules] = useState([])
    // }
    const [testSeriesName, setTestSeriesName] = useState('');
    const [examName, setExamName] = useState('')
    const { examType, seriesType } = useParams()
    const baseUrl = process.env.REACT_APP_BASE_URL
    const user = useSelector((state) => state.auth.user)
    const [showAllTests, setShowAllTests] = useState(false);
    const [showAllQuizzes, setShowAllQuizzes] = useState(false);
    const [userProgress, setUserProgress] = useState({testProgress: 0, quizProgress: 0, testCompleted: 0, quizCompleted: 0, totalProgress: 0});
    const [totalModules, setTotalModules] = useState({testTotal: 0, quizTotal: 0});
    const [isLoginOpen, setIsLoginOpen] = useState(false);
    const [isSignupOpen, setIsSignupOpen] = useState(false);
    const [isPaymentOpen, setIsPaymentOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const paidPrograms = useSelector((state) => state.paidPrograms.paidPrograms);
    console.log("These are the Paid Programs:", paidPrograms);
    const authorizedUser = paidPrograms.includes(Number(id));
    console.log("Authorized User:", authorizedUser);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    let examObj = {}

    // examObj

    if( examType === 'frm-i' )
    {
        examObj = {
            'examName': 'FRM I',
            'seriesName': 'FRM I Test Series',
            'tests': '10',
            'quizzes': '50',
            'questionsCount': '1500',
            'overview1': "Our FRM I Test Series is designed to help you master key concepts and ace the exam with confidence. It covers all four books—Foundations of Risk Management, Quantitative Analysis, Financial Markets & Products, and Valuation & Risk Models.",
            'overview2': "The test series includes short quizzes, full-length Tests and detailed performance analysis. The understanding of concepts is tested via topic-wise Quizzes and Tests. Detailed performance analysis is shared for all the Quizzes and Tests which includes scores, ranking and percentile for each Quiz and Test. Detailed Explanation is also provided for all the questions. Upon submission, a candidate can also view the responses based on Correct, Incorrect and Unattempted responses.",
            'syllabus' : "FRM I Test Series Tracker  - Syllabus.pdf"
        }
    }else if( examType === 'frm-ii' )
    {
        examObj = {
            'examName': 'FRM II',
            'seriesName': 'FRM II Test Series',
            'tests': '10',
            'quizzes': '50',
            'questionsCount': '1500',
            'overview1': "Our FRM II Test Series is designed to help you master key concepts and ace the exam with confidence. It covers all five books— Market Risk, Credit Risk, Operational Risk, Liquidity and Treasury Risk, and Investment and Risk Management.",
            'overview2':"The test series includes short quizzes, full-length Tests and detailed performance analysis. The understanding of concepts is tested via topic-wise Quizzes and Tests. Detailed performance analysis is shared for all the Quizzes and Tests which includes scores, ranking and percentile for each Quiz and Test. Detailed Explanation is also provided for all the questions. Upon submission, a candidate can also view the responses based on Correct, Incorrect and Unattempted responses.",
            'syllabus' : "FRM II Test Series Tracker - Syllabus.pdf"
        }
    }else if( examType === 'scr' )
    {
        examObj = {
            'examName': 'SCR',
            'seriesName': 'SCR Test Series',
            'tests': '4',
            'quizzes': '20',
            'questionsCount': '560',
            'overview1': "Our SCR Test Series is designed to help you master key concepts and ace the exam with confidence. It covers all the major topics such as Climate Risk, Sustainability, Scenario Analysis, Regulatory initiatives, Reporting requirements, Green finance instruments, Net Zero and Transition planning.",
            'overview2':"The test series includes short quizzes, full-length Tests and detailed performance analysis. The understanding of concepts is tested via topic-wise Quizzes and Tests. Detailed performance analysis is shared for all the Quizzes and Tests which includes scores, ranking and percentile for each Quiz and Test. Detailed Explanation is also provided for all the questions. Upon submission, a candidate can also view the responses based on Correct, Incorrect and Unattempted responses.",
            'syllabus' : "SCR Test Series Tracker - Syllabus.pdf"
        }
    }
    useEffect(() => {
        if (paidPrograms.length > 0) {  // Ensure paidPrograms is loaded
            setLoading(false);
        }
    }, [paidPrograms]);
    
    useEffect(() => {
        if (!loading) {  // Ensure it runs only after paidPrograms is loaded
            const authorizedUser = paidPrograms.includes(Number(id));
            setIsPaymentOpen(user ? !authorizedUser : false);
        }
    }, [id, paidPrograms, user, loading]);
    

    useEffect(() => {
        const fetchModules = async () => {
            console.log("Inside Fetch Modules");
            console.log("Id:", id);
          const token = localStorage.getItem('authToken')
    
          if(!token){
            console.log('Token not found')
          }
    
          try{
            const [testResponse, quizResponse] = await Promise.all([
              axios.get(`${baseUrl}/api/module/all`, 
                {
                  params: {
                    'program_id':id,
                    'module_type':'test' 
                  }, 
                  headers: {
                    'x-auth-token': token,
                  }
                }),
              axios.get(`${baseUrl}/api/module/all`,
                {
                  params: {
                    'program_id':id,
                    'module_type':'quiz' 
                  }, 
                  headers: {
                    'x-auth-token': token,
                  }
                }),
            ])
            // console.log('test data:', testData)
            // console.log('quiz data:', quizData)
            const [testData, quizData] = await Promise.all([testResponse.data, quizResponse.data])
            setTestModules(testData)
            setQuizModules(quizData)
            console.log("Getting total tests");
            
            const testTotal = testData.length;
            const quizTotal = quizData.length;
            setTotalModules({ testTotal, quizTotal });

            let testCompleted = testData.filter(test => test.result).length;
            let quizCompleted = quizData.filter(quiz => quiz.result).length;

            const testProgress = (testCompleted / testTotal) * 100 || 0;
            const quizProgress = (quizCompleted / quizTotal) * 100 || 0;
            const totalProgress = ((testProgress + quizProgress) / 2).toFixed(0);

            setUserProgress({
              testProgress,
              quizProgress,
              testCompleted,
              quizCompleted,
              totalProgress,
            });

            console.log("Module Data");
            console.log('test data:', testData)
            console.log('quiz data:', quizData)
            // console.log('module id check:', testData[0].module_id)
          }catch(error){
            console.error(error)
          }
        }
        if(user)
        {
            console.log("User is defined:", user);
            if(examType === "frm-i")
            {
                setTestSeriesName("FRM I Test Series")
                setExamName("FRM I")
            } else if(examType === "frm-ii") {
                setTestSeriesName("FRM II Test Series")
                setExamName("FRM II")
            } else if(examType === "scr") {
                setTestSeriesName("SCR Test Series")
                setExamName("SCR")
            }
            fetchModules()
            
            console.log("Total Data:", totalModules);
            console.log("User Progress:", userProgress);
        } else {
            setTestModules(test)
            setQuizModules(quiz)
        }
      }, [id, examType, seriesType])
  
    const handleToggleTests = () => setShowAllTests(!showAllTests);
    const handleToggleQuizzes = () => setShowAllQuizzes(!showAllQuizzes);
  
    const handleReAttempt = (event, module) => {
      event.preventDefault();
      Swal.fire({
        title: 'Are you sure?',
        text: `Do you really want to reattempt ${module?.module_name}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, reattempt it!',
        cancelButtonText: 'No, cancel!'
      }).then((result) => {
        if (result.isConfirmed) {
          // Redirect or perform action
          console.log(`Reattempting ${module?.module_name}`);
        //   dispatch(clearAnswer);
        //   navigate(`/${examType}/${seriesType}/launch/test/${id}/${module.module_id}`)
        dispatch(clearAnswer({ callback: () => navigate(`/${examType}/${seriesType}/launch/test/${id}/${module.module_id}`) }));
        }
      });
    };

    const openPaymentModal = () => {
        setIsPaymentOpen(true);
    }

    const openSignupModal = () => {
        setIsLoginOpen(false);
        setIsSignupOpen(true);
    };
    const openLoginModal = () => {
        setIsSignupOpen(false);
        setIsLoginOpen(true);
    }

    const handleRedirect = () => {
        if(!user)
            setIsLoginOpen(true);
        // setRedirectUrl(`/${examType}/${seriesType}/all/${id}`); 
        console.log("Setting Redirect Url");
        const navigateUrl = `/${examType}/${seriesType}/all/${id}`;
        localStorage.setItem('redirectUrl', navigateUrl);
    }

    const handleLogoutAttempt = (event, module) => {
        event.preventDefault();
        Swal.fire({
          title: 'Are you sure?',
          text: `You must login to attempt`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Take me to login',
          cancelButtonText: 'Cancel'
        }).then((result) => {
          if (result.isConfirmed) {
            // Redirect or perform action
            console.log(`Reattempting ${module?.module_name}`);
            navigate(`/login`)
          }
        });
      };
  
    const formatDate = (dateString) => {
      const date = new Date(dateString)
  
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = String(date.getFullYear())
      return `${day}-${month}-${year}`
    }

  return (
    <>
    <div>
        <div className="edu-hero-style"> { /* CSS 1 */ }
            {/* <div className="container"> */}
            <div className={style.testSeriesHeader}>
                <div className="row" style={{"padding-top":"10px"}}>
                    <div className="col-lg-6 content-positioning">
                        <ul className={style.navTags}> { /* CSS 2 */ }
                            <li><a href="/" className={style.navTagsActive}>Home {'>'} </a></li>
                            <li><a href="#" className={style.navTagsActive}>{examObj.examName} {'>'} </a></li>
                            <li>{examObj.seriesName}</li>
                        </ul>
                    </div>
                    <div className="col-lg-4 hidden-content-value"> { /* CSS 3 */ }
                        <li>Hidden</li>
                    </div>
                </div>
                <div className="row">
                <div className='col-lg-6 content-positioning'>
                    <h2 className={style.testHeading}>{examObj.seriesName}</h2> { /* CSS 5 */ }
                    <div className="test-details">
                        <ul className={style.testDetail}>
                            <li className={style.testDetailItem}>{examObj.examName}</li>
                            <li className={style.testDetailItem}>{examObj.tests} tests</li>
                            <li className={style.testDetailItem}>{examObj.quizzes} quizzes</li>
                            <li className={style.testDetailItem}>{examObj.questionsCount} questions</li>
                        </ul>
                    </div>
                    <h2 className={style.testContentHeading}>Master key concepts, tackle your weak areas, and gear up for exam success with our expertly crafted test series.</h2>
                    <div className={style.enrollNowBtn}> { /* CSS 16 */ }
                        <a href={authorizedUser ? "#my-tests-id" : '#'} onClick={() => {
                                                                                            handleRedirect();
                                                                                            if (user && !authorizedUser) {
                                                                                                openPaymentModal();
                                                                                            }
                                                                                        }}
                                                                                        >{authorizedUser ? 'Continue Learning' : 'Enroll Now'}</a>
                    </div>
                                {/* <div className="exam-info-card">
                                    <ul className="exam-infos">
                                        <li className="exam-info-item">
                                        <h3 className="exam-info-heading">Exam</h3>
                                        <p className="exam-info-text">{examObj.examName}</p>
                                        <img className="exam-info-image" src="/assets/images/chapters/topper.png" alt="Test Analysis" />
                                        <p className="exam-info-text">Designed by</p> 
                                        <p className="exam-info-text">certified FRMs</p>
                                        </li>
                                        <li className="exam-info-item">
                                        <h3 className="exam-info-heading">Tests</h3>
                                        <p className="exam-info-text">{examObj.tests}</p>
                                        <img className="exam-info-image" src="/assets/images/chapters/topper.png" alt="Chapters Icon" />
                                        <p className="exam-info-text">Exam Tips</p>
                                        </li>
                                        <li className="exam-info-item">
                                        <h3 className="exam-info-heading">Quizzes</h3>
                                        <p className="exam-info-text">{examObj.quizzes}</p>
                                        <img className="exam-info-image" src="/assets/images/chapters/topper.png" alt="Hours Icon" />
                                        <p className="exam-info-text">Content updated with</p>
                                        <p className="exam-info-text"> latest syllabus</p>
                                        </li>
                                        <li className="exam-info-item">
                                        <h3 className="exam-info-heading">Questions</h3>
                                        <p className="exam-info-text">{examObj.questionsCount}</p>
                                        <img className="exam-info-image" src="/assets/images/chapters/whatsapp.png" alt="Weightage Icon" />
                                        <p className="exam-info-text">24X7 Support</p>
                                        </li>
                                    </ul>
                                    </div> */}
                                    </div>
                    <div className="col-lg-4 hidden-content-value"> { /* CSS 3 */ }
                        <li>Hidden</li>
                    </div>                
                </div>
                </div>
                {/* <div className="row justify-content-center">
                    <div className=" col-lg-6  order-1 order-lg-0"> */}
                        <div className="edu-hero-content "> { /* CSS 4 */ }
                        <div className="row" style={{"margin":"0", "padding":"0"}}>
                            <div className=" col-lg-6  order-1 order-lg-0 content-positioning">

                                <h2 className="edu-section-title text-start mt-26  frm-i-heading">Overview </h2> { /* CSS 11 */ }
                                <div className={style.overview}> { /* CSS 12 */ }
                                    <p>{examObj.overview1}</p>
                                    <p>{examObj.overview2}</p>
                                    <div className="my-test-id" id="my-tests-id"></div>
                                </div>
                                <div className="row justify-content-center" style={{"margin-top":"50px", "padding":"0"}}>
                            {!authorizedUser ? (
                            
                            <div className="test-quiz-bg">
                            {/* Test List Start */}
                                <h2 className="edu-section-title text-start">Tests</h2>
                                        {/* <div className="row justify-content-lg-between align-items-center table-bg"> */}
                                          <div className="col-lg-12">
                                                          <div className="row">
                                                              <div className="col-lg-12">
                                                                  <div className="card">
                                                                      <div className="table-responsive border-bottom table-text"> { /* CSS 6 */ }
                                                                      <table className="table table-striped"> { /* CSS 7 */ }
                                                                          <thead className="my-test-table-head"> { /* CSS 8 */ }
                                                                          <tr>
                                                                              <th scope="col">Tests</th>
                                                                              <th scope="col">Marks</th>
                                                                              <th scope="col">Percentile</th>
                                                                              <th scope="col">Rank</th>
                                                                              {/* <th scope="col">Date</th> */}
                                                                              <th scope="col">Action</th>
                                                                          </tr>
                                                                          </thead>
                                                                          <tbody id="test-modules">
                                                                          {testModules.slice(0, showAllTests ? testModules.length : 3).map((module, index) => (
                                                                              <tr className="table-active init-visible" key={index}>
                                                                              <th scope="row">{module.module_name}</th>
                                                                                  <>
                                                                                  <td><FaLock className='chapter-lock-icon'/></td>
                                                                                  <td><FaLock className='chapter-lock-icon'/></td>
                                                                                  <td><FaLock className='chapter-lock-icon'/></td>
                                                                                  {/* <td><FaLock className='chapter-lock-icon'/></td> */}
                                                                                  <td>
                                                                                    <a
                                                                                        onClick={() => {
                                                                                            handleRedirect();
                                                                                            if (user && !authorizedUser) {
                                                                                                openPaymentModal();
                                                                                            }
                                                                                        }}
                                                                                        className={style.reAttemptBtn}
                                                                                    > { /* CSS 9 */ }
                                                                                        Attempt
                                                                                    </a>
                                                                                    </td>
                                                                                  </>
                                                                              </tr>
                                                                          ))}
                                                                          </tbody>
                                                                      </table>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                    </div>
                                                    <div className={style.loadMoreTestBtns}> { /* CSS 10 */ }
                                                      <button onClick={handleToggleTests} className={style.loadMoreTestBtn}> { /* CSS 11 */ }
                                                        {showAllTests ? 'Load Less' : 'Load More'}
                                                        <i className={`bi bi-chevron-${showAllTests ? 'up' : 'down'}`}></i>
                                                      </button>
                                                    </div>
                                        {/* </div> */}
                            {/* Test List End */}

                            {/* Quiz List Start */}
                            {/* <h2 className="edu-section-title text-start">Quizzes</h2> */}
                                        {/* <div className="row justify-content-lg-between align-items-center table-bg"> */}
                                          <div className="mt-50">
                                                          <h2 className="edu-section-title text-start">Quizzes</h2>
                                                          {/* <div className="row justify-content-lg-between align-items-center table-bg"> */}
                                                              <div className="col-lg-12">
                                                                      <div className="row">
                                                                          <div className="col-lg-12">
                                                                          <div className="card">
                                                                              <div className="table-responsive border-bottom table-text">
                                                                              <table className="table table-striped">
                                                                                  <thead className="my-test-table-head">
                                                                                  <tr>
                                                                                      <th scope="col">Quiz</th>
                                                                                      <th scope="col">Marks</th>
                                                                                      <th scope="col">Percentile</th>
                                                                                      <th scope="col">Rank</th>
                                                                                      {/* <th scope="col">Date</th> */}
                                                                                      <th scope="col">Action</th>
                                                                                  </tr>
                                                                                  </thead>
                                                                                  <tbody id="quiz-modules">
                                                                                  {quizModules.slice(0, showAllQuizzes ? quizModules.length : 3).map((module, index) => (
                                                                                      <tr className="table-active init-visible" key={index}>
                                                                                      <th scope="row">{module.module_name}</th>
                                                                                          <>
                                                                                          <td><FaLock className='chapter-lock-icon'/></td>
                                                                                          <td><FaLock className='chapter-lock-icon'/></td>
                                                                                          <td><FaLock className='chapter-lock-icon'/></td>
                                                                                          {/* <td><FaLock className='chapter-lock-icon'/></td> */}
                                                                                          <td>
                                                                                            <a
                                                                                                onClick={() => {
                                                                                                    handleRedirect();
                                                                                                    if (user && !authorizedUser) {
                                                                                                        openPaymentModal();
                                                                                                    }
                                                                                                }}
                                                                                                className={style.reAttemptBtn}
                                                                                            > { /* CSS 9 */ }
                                                                                                Attempt
                                                                                            </a>
                                                                                          </td>
                                                                                          </>
                                                                                      </tr>
                                                                                  ))}
                                                                                  </tbody>
                                                                              </table>
                                                                              </div>
                                                                          </div>
                                                                          </div>
                                                                      </div>
                                                              </div>
                                                              <div className={style.loadMoreTestBtns}>
                                                                  <button onClick={handleToggleQuizzes} className={style.loadMoreTestBtn}>
                                                                  {showAllQuizzes ? 'Load Less' : 'Load More'}
                                                                  <i className={`bi bi-chevron-${showAllQuizzes ? 'up' : 'down'}`}></i>
                                                                  </button>
                                                              </div>
                                                          {/* </div> */}
                                                  </div>
                                        {/* </div> */}
                            {/* Quiz List End */}
                            </div>) : 
                        
                        ( 
                        <>
                        <div className="test-quiz-bg">
                        <h2 className="edu-section-title text-start">My Tests</h2>
                                {/* <div className="row justify-content-lg-between align-items-center table-bg"> */}
                                  <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="card">
                                                    <div className="table-responsive border-bottom table-text"> { /* CSS 6 */ }
                                                    <table className="table table-striped"> { /* CSS 7 */ }
                                                        <thead className="my-test-table-head"> { /* CSS 8 */ }
                                                        <tr>
                                                            <th scope="col">Tests</th>
                                                            <th scope="col">Marks</th>
                                                            <th scope="col">Percentile</th>
                                                            <th scope="col">Rank</th>
                                                            {/* <th scope="col">Date</th> */}
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody id="test-modules">
                                                        {testModules.slice(0, showAllTests ? testModules.length : 3).map((module, index) => (
                                                            <tr className="table-active init-visible" key={index}>
                                                            <th scope="row">{module.module_name}</th>
                                                            {module.result ? (
                                                                  <>
                                                                    <td>{module.result.marks}</td>
                                                                    <td>{module.result.percentile}%</td>
                                                                    <td>{module.result.rank}</td>
                                                                    {/* <td>{formatDate(module.result.attended_date)}</td> */}
                                                                    <td>
                                                                    <a
                                                                        onClick={(event) => handleReAttempt(event, module)}
                                                                        className={style.reAttemptBtn}
                                                                    > { /* CSS 9 */ }
                                                                        Re-Attempt
                                                                    </a>
                                                                    </td>
                                                                    </>
                                                            ) : (
                                                                <>
                                                                <td>-</td>
                                                                <td>-</td>
                                                                <td>-</td>
                                                                {/* <td>-</td> */}
                                                                <td>
                                                                    <a className={style.reAttemptBtn}><Link to={`/${examType}/${seriesType}/launch/test/${id}/${module.module_id}`}>Attempt</Link></a>
                                                                </td>
                                                                </>
                                                            )}
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                  </div>
                                  <div className={style.loadMoreTestBtns}> { /* CSS 10 */ }
                                    <button onClick={handleToggleTests} className={style.loadMoreTestBtn}> { /* CSS 11 */ }
                                      {showAllTests ? 'Load Less' : 'Load More'}
                                      <i className={`bi bi-chevron-${showAllTests ? 'up' : 'down'}`}></i>
                                    </button>
                                  </div>
                                {/* </div> */}
                                
                        
                                {/* My Quizzes section */}
                                <div className="mt-50">
                                        <h2 className="edu-section-title text-start">My Quizzes</h2>
                                        {/* <div className="row justify-content-lg-between align-items-center table-bg"> */}
                                            <div className="col-lg-12">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                        <div className="card">
                                                            <div className="table-responsive border-bottom table-text">
                                                            <table className="table table-striped">
                                                                <thead className="my-test-table-head">
                                                                <tr>
                                                                    <th scope="col">Quiz</th>
                                                                    <th scope="col">Marks</th>
                                                                    <th scope="col">Percentile</th>
                                                                    <th scope="col">Rank</th>
                                                                    {/* <th scope="col">Date</th> */}
                                                                    <th scope="col">Action</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody id="quiz-modules">
                                                                {quizModules.slice(0, showAllQuizzes ? quizModules.length : 3).map((module, index) => (
                                                                    <tr className="table-active init-visible" key={index}>
                                                                    <th scope="row">{module.module_name}</th>
                                                                    {module.result ? (
                                                                          <>
                                                                            <td>{module.result.marks}</td>
                                                                            <td>{module.result.percentile}%</td>
                                                                            <td>{module.result.rank}</td>
                                                                            {/* <td>{formatDate(module.result.attended_date)}</td> */}
                                                                            <td>
                                                                            <a
                                                                                onClick={(event) => handleReAttempt(event, module)}
                                                                                className={style.reAttemptBtn}
                                                                            >
                                                                                Re-Attempt
                                                                            </a>
                                                                            </td>
                                                                          </>
                        
                                                                    ) : (
                                                                        <>
                                                                        <td>-</td>
                                                                        <td>-</td>
                                                                        <td>-</td>
                                                                        {/* <td>-</td> */}
                                                                        <td>
                                                                            <a className={style.reAttemptBtn}><Link to={`/${examType}/${seriesType}/launch/test/${id}/${module.module_id}`}>Attempt</Link></a>
                                                                        </td>
                                                                        </>
                                                                    )}
                                                                    </tr>
                                                                ))}
                                                                </tbody>
                                                            </table>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                {/* </div> */}
                                            </div>
                                            <div className={style.loadMoreTestBtns}>
                                                <button onClick={handleToggleQuizzes} className={style.loadMoreTestBtn}>
                                                {showAllQuizzes ? 'Load Less' : 'Load More'}
                                                <i className={`bi bi-chevron-${showAllQuizzes ? 'up' : 'down'}`}></i>
                                                </button>
                                            </div>
                                        {/* </div> */}
                                </div>
                                </div>
                                </>
                                )

                        }
                            {/* </div> */}
                            <div className={style.downloadSyllabusBtn}> { /* CSS 17 */ }
                                <a href={`/assets/syllabus/${examObj.syllabus}`} target="_blank">Download Syllabus <i className="bi bi-download"></i> </a>
                            </div>
                            <h2 className="edu-section-title text-start mt-26 frm-i-heading">Your Learning Environment</h2> { /* CSS 18 */ }
                            <div className="prime-feature-bg" style={{"background-color":"#FDF1DB"}}> { /* CSS 19 */ }
                                <div className="prime-features mt-26"> { /* CSS 20 */ }
                                    <div className="prime-feature"> { /* CSS 21 */ }
                                        <h3>Study Notes</h3>
                                        <h5>Comprehensive study material  which covers detailed reading notes, revision notes and formula
                                        sheets.</h5>
                                    </div>
                                    <div className="prime-feature-image">
                                        <img src="/assets/images/chapters/study-notes.png" alt="Test Anylysis" srcset=""/>
                                    </div>
                                </div>
                                <div className="prime-features mt-26">
                                    <div className="prime-feature">
                                        <h3>Practice Questions</h3>
                                        <h5>A rich question bank with answers and detailed explanations including solved examples, in-chapter
                                        quizzes and practice questions are included with each chapter.</h5>
                                    </div>
                                    <div className="prime-feature-image">
                                        <img src="/assets/images/chapters/revision-notes.png" alt="Test Anylysis" srcset=""/>
                                    </div>
                                </div>
                                <div className="prime-features mt-26">
                                    <div className="prime-feature">
                                        <h3>Gap Analysis</h3>
                                        <h5>Designed to analyse the gaps in all learning objectives and accordingly provide an effort
                                        estimation required to complete the relevant topics.</h5>
                                    </div>
                                    <div className="prime-feature-image">
                                        <img src="/assets/images/chapters/gap-analysis.png" alt="Test Anylysis" srcset=""/>
                                    </div>
                                </div>
                                <div className="prime-features mt-26">
                                    <div className="prime-feature">
                                        <h3>Interactive Learning</h3>
                                        <h5>Risk Hub’s interactive, text-based content accelerate learning by providing flexibility, convenience, and accessibility without any setup or dowload.</h5>
                                    </div>
                                    <div className="prime-feature-image">
                                        <img src="/assets/images/chapters/flexible.png" alt="Test Anylysis" srcset=""/>
                                    </div>
                                </div>
                                <div className="prime-features mt-26">
                                    <div className="prime-feature">
                                        <h3>Demonstrable Progress</h3>
                                        <h5>Track your learning journey with milestones and tangible results via visual progress indicators
                                        as you advance through the course. </h5>
                                    </div>
                                    <div className="prime-feature-image">
                                        <img src="/assets/images/chapters/formula.png" alt="Test Anylysis" srcset=""/>
                                    </div>
                                </div>
                            </div>

                            
                        </div>
                        <div className="featured-area positioning"> { /* CSS 22 */ }
                            <div className="container"> { /* CSS 23 */ }
                                <div className="row">
                                <div className="col-12">
                                    <div className="hrdd-section-title"> { /* CSS 24 */ }
                                    <h1>Take the Next Step Towards FRM Certification</h1>
                                    </div>
                                </div>
                                </div>
                                <div className={style.cardView}> { /* CSS 25 */ }
                                <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                <FRMCard
                                    imgSrc="/assets/images/course-cover/frmi-quantitative-analysis.jpg" 
                                    title={"Quantitative Analysis\n"} 
                                    content1="15 Chapters" 
                                    content2="Detailed Notes"
                                    link="/frm-i/frm-i-test-series"
                                />
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                <FRMCard 
                                    imgSrc="/assets/images/course-cover/frmi-financial-markets-and-products.jpg" 
                                    title={"Financial Markets and Products"} 
                                    content1="20 Chapters" 
                                    content2="Detailed Notes" 
                                    link="/frm-ii/frm-ii-test-series"
                                />
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                <FRMCard
                                    imgSrc="/assets/images/course-cover/frmi-valuation-and-risk-models.jpg" 
                                    title={"Valuation and Risk Models\n"} 
                                    content1="16 Chapters" 
                                    content2="Detailed Notes" 
                                    link="/frm-i/frm-i-test-series"
                                />
                                </div>
                                </div>
                                </div>
                            </div>
                        </div>
                                </div>
                                
                                {!authorizedUser ? (<div className="col-lg-4 order-0 order-lg-1">
                        {/* <div className="sticky-card">  */}
                            {/* <div className="test-fees-card sticky-card"> */}
                                {/* <ul className="test-fees-card-heading">
                                    <li>Test Fee </li>
                                    <li>₹9,999</li>
                                </ul> */}
                                {/* <div className="sticky-card"> */}
                                <div className={style.testFeesCardOuter}> { /* CSS 15 */ }
                                    {/* <div className="test-fees-card-inner"> */}
                                        <h3>{examObj.seriesName}</h3>
                                        <img src="/assets/images/chapters/foundationsOfRiskManagement.jpg" alt="" srcset=""/>
                                    {/* </div> */}
                                
                                <div className="test-fees-btn mt-24"> { /* CSS 16 */ }
                                    <a href="#" onClick={() => {
                                                                    handleRedirect();
                                                                    if (user && !authorizedUser) {
                                                                        openPaymentModal();
                                                                    }
                                                                }}>Enroll Now</a>
                                    {/* <a href="{{route('login')}}">Enroll Now</a> */}
                                </div>
                                </div>
                                {/* </div> */}
                            {/* </div> */}
                        {/* </div> */}
                        </div>) : (
                            <div className="col-lg-4 order-0 order-lg-1">
                              <div className={style.testProgressCard}>
                                <div className={style.testProgressCardOuter}>
                                  <h3>{examObj.seriesName}</h3>
                                  <p><span>Tests Completed: {userProgress.testCompleted}/{totalModules.testTotal}</span></p>
                                  <div className={style.progressBar}>
                                    {/* Tests Progress Bar */}
                                    <div className={style.progress} style={{ width: `${userProgress.testProgress}%`, backgroundColor: '#2d7a46' }}></div>
                                  </div>
                                  <p><span>Quizzes Completed: {userProgress.quizCompleted}/{totalModules.quizTotal}</span></p>
                                  <div className={style.progressBar}>
                                    {/* Quizzes Progress Bar */}
                                    <div className={style.progress} style={{ width: `${userProgress.quizProgress}%`, backgroundColor: '#2d7a46' }}></div>
                                  </div>
                                  <p><span>{userProgress.totalProgress}% Completed</span></p>
                                  <div className={style.progressBar}>
                                    {/* Total Progress Bar */}
                                    <div className={style.progress} style={{ width: `${userProgress.totalProgress}%`, backgroundColor: '#2d7a46' }}></div>
                                  </div>
                                  <div className={style.continueLearningBtn}>
                                    {/* CSS 16 */}
                                    <a href="#my-tests-id" >Continue Learning</a>
                                  </div>
                                </div>
                              </div>
                          </div>
                          
                        )}

                        
                            {/* ** */}
                            </div>
                </div>
        </div>
        <Footer2/>
    </div>
    <LoginPage isOpen={isLoginOpen} onClose={() => setIsLoginOpen(false)} openSignupModal={openSignupModal} />
    <Signup isOpen={isSignupOpen} onClose={() => setIsSignupOpen(false)} openLoginModal={openLoginModal} />
    <PaymentModal isOpen={isPaymentOpen} onClose={() => setIsPaymentOpen(false)} />
    </>
  )
}

export default TestSeries;