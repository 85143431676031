// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TestPage_fullWidthText__D1Xr4 {
    width: 100%; /* Full width */
    border-radius: 4px; /* Optional: rounded corners */
    padding: 10px; /* Optional: padding */
    background-color: #EDF2FA; /* Optional: background color */
    overflow: hidden; /* Hide overflow */
    white-space: pre-wrap; /* Preserve whitespace and line breaks */
    word-wrap: break-word; /* Break long words if necessary */
    margin: 0; /* Remove default margin */
    font-weight: bold;
    overflow-x: auto;
  }`, "",{"version":3,"sources":["webpack://./src/components/TestPage/TestPage.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW,EAAE,eAAe;IAC5B,kBAAkB,EAAE,8BAA8B;IAClD,aAAa,EAAE,sBAAsB;IACrC,yBAAyB,EAAE,+BAA+B;IAC1D,gBAAgB,EAAE,kBAAkB;IACpC,qBAAqB,EAAE,wCAAwC;IAC/D,qBAAqB,EAAE,kCAAkC;IACzD,SAAS,EAAE,0BAA0B;IACrC,iBAAiB;IACjB,gBAAgB;EAClB","sourcesContent":[".fullWidthText {\n    width: 100%; /* Full width */\n    border-radius: 4px; /* Optional: rounded corners */\n    padding: 10px; /* Optional: padding */\n    background-color: #EDF2FA; /* Optional: background color */\n    overflow: hidden; /* Hide overflow */\n    white-space: pre-wrap; /* Preserve whitespace and line breaks */\n    word-wrap: break-word; /* Break long words if necessary */\n    margin: 0; /* Remove default margin */\n    font-weight: bold;\n    overflow-x: auto;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullWidthText": `TestPage_fullWidthText__D1Xr4`
};
export default ___CSS_LOADER_EXPORT___;
