import { Edit, SaveButton, SelectInput, SimpleForm, TextInput, Toolbar } from "react-admin";

const CustomToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton />
        {/* No DeleteButton here */}
    </Toolbar>
);

const TransactionEdit = () => {
    return (
        <Edit>
            <SimpleForm toolbar={<CustomToolbar />}>
                <TextInput source="email" label="Email" disabled/> {/* Email cannot be edited */}
                <TextInput source="program_name" label="Program Name" disabled /> {/* Program ID is non-editable */}
                <TextInput source="amount" label="Amount" disabled /> {/* Amount is read-only */}

                {/* Only Payment Status is Editable */}
                <SelectInput 
                    source="payment_status"
                    label="Payment Status"
                    choices={[
                        { id: "paid", name: "Paid" },
                        { id: "pending", name: "Pending" },
                        { id: "failed", name: "Failed" }
                    ]}
                    required
                />
            </SimpleForm>
        </Edit>
    );
};

export default TransactionEdit;
