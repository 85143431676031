import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Defaults to localStorage for web
import authReducer from '../redux/auth/authSlice';
import answerReducer from '../redux/auth/answerSlice';
import programReducer from '../redux/auth/programSlice';

const persistConfig = {
  key: 'root',
  storage, // Use localStorage
  // whitelist: ['auth', 'answers']
};

const persistedAuthReducer = persistReducer(persistConfig, authReducer);
const persistedAnswerReducer = persistReducer(persistConfig, answerReducer);
const persistProgramDetails = persistReducer(persistConfig, programReducer);

const store = configureStore({
  reducer: {
    auth: persistedAuthReducer, // Add the persisted auth reducer
    answer: persistedAnswerReducer,
    programDetails: persistProgramDetails
  },
});

export const persistor = persistStore(store);
export default store;
