import React from 'react';

const HeroSection = () => {
  return (
    <div className="edu-hero-style">
      <div className="container">
        <ul className="nav-tags">
          <li><a href="/" className="active">Home {'>'} </a></li>
          <li>Integrated Test Series</li>
        </ul>
        <div className="row justify-content-center align-items-center test-series-hero-content">
          <div className="col-lg-9 order-1 order-lg-0">
            <div className="edu-hero-content hrdd-section-title">
              <h1 className="text-start">Prepare Yourself For FRM Exam</h1>
              <ul className="test-series-head-list">
                <li>Comprehensive preparation of the FRM exam</li>
                <li>Curated questions with detailed explanations</li>
                <li>Timed practice tests that simulate the actual exam environment</li>
                <li>Immediate feedback and performance analysis</li>
                <li>Regular updates, online forums, webinars and doubt-clearing sessions</li>
                <li>Accessible anytime and anywhere for flexible learning</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 order-0 order-lg-1">
            <div className="ag-hero-figure test-series-head-image">
              <img src="assets/images/pyfinimg/integrated-test-series-cover.png" alt="Test Series" className="img-fluid test-series-image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
