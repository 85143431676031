// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .featured-content-price{
    text-align: left;
    margin:10px 0;
  }
  p.featured-content-price span{
    color: #b9b8b6;
    text-decoration: line-through;
  }
  .featured-content-rating{
    text-align: left;
    margin: 10px 0;
  }
  p.featured-content-rating span{
    color: #b9b8b6;
  }


  .featured-inner-content{
    text-align: left;
    color: black;
    font-size: 17px;
  }`, "",{"version":3,"sources":["webpack://./src/components/Integerated-test-series/FRMCard/FRMCard.css"],"names":[],"mappings":"EAAE;IACE,gBAAgB;IAChB,aAAa;EACf;EACA;IACE,cAAc;IACd,6BAA6B;EAC/B;EACA;IACE,gBAAgB;IAChB,cAAc;EAChB;EACA;IACE,cAAc;EAChB;;;EAGA;IACE,gBAAgB;IAChB,YAAY;IACZ,eAAe;EACjB","sourcesContent":["  .featured-content-price{\n    text-align: left;\n    margin:10px 0;\n  }\n  p.featured-content-price span{\n    color: #b9b8b6;\n    text-decoration: line-through;\n  }\n  .featured-content-rating{\n    text-align: left;\n    margin: 10px 0;\n  }\n  p.featured-content-rating span{\n    color: #b9b8b6;\n  }\n\n\n  .featured-inner-content{\n    text-align: left;\n    color: black;\n    font-size: 17px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
