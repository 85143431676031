import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  paidPrograms: []
};

const paidProgramReducer = createSlice({
  name: 'paidPrograms',
  initialState,
  reducers: {
    setPaidPrograms: (state, action) => {
      state.paidPrograms = action.payload;
    },
    clearPaidPrograms: () => initialState,
  },
});

export const { setPaidPrograms, clearPaidPrograms } = paidProgramReducer.actions;

export default paidProgramReducer.reducer;