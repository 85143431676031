import React from 'react';
import './Footer.css'

const Footer = () => {
  return (
    <div className="edu-footer-style"> { /* CSS 1 */ }
      <div className="container"> { /* CSS 2 */ }
        <div className="edu-footer-main-wrap"> { /* CSS 3 */ }
          <div className="row">
            {/* Logo and About Section */}
            <div className="col-lg-3 col-md-7">
              <div className="edu-footer-about">
                <div className="logo footer-logo"> { /* CSS 4 */ }
                  <a href="#">
                    <img src="/assets/images/risk-hub.png" alt="Risk Hub Logo" />
                  </a>
                </div>
                <p className="f-about-texts"> { /* CSS 5 */ }
                  Empowering Risk Management professionals through upskilling and career development
                </p>
                <ul className="footer-social-links d-flex gap-3"> { /* CSS 6 */ }
                  <li>
                    <a href="#" target="_blank" rel="noopener noreferrer">
                    {/* <a href="http://t.me/FRM_I" target="_blank" rel="noopener noreferrer"> */}
                      <i className="bi bi-telegram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/riskhubb" target="_blank" rel="noopener noreferrer">
                      <i className="bi bi-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {/* Explore Programs */}
            <div className="col-lg-2">
              <div className="col-lg-8 d-flex justify-content-lg-center pt-4 pt-lg-0">
                <div className="footer-widget"> { /* CSS 7 */ }
                  <h4 className="footer-widget-title">Explore Programs</h4> { /* CSS 8 */ }
                  <ul className="footer-links"> { /* CSS 9 */ }
                    {/* <li><a href="#our-program">Mentorship and Guidance Program</a></li> */}
                    <li><a href="/frm-i/frm-i-test-series">FRM I Test Series</a></li>
                    <li><a href="/frm-ii/frm-ii-test-series"> FRM II Test Series</a></li>
                    <li><a href="/scr/scr-test-series">SCR Test Series</a></li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Free Material */}
            {/* <div className="col-lg-2">
              <div className="col-8 d-flex justify-content-lg-center pt-4 pt-lg-0">
                <div className="footer-widget">
                  <h4 className="footer-widget-title">Free Material</h4>
                  <ul className="footer-links">
                    <li><a href="#" target="_blank">Quant Planner</a></li>
                    <li><a href="#" target="_blank">Non-Quant Planner</a></li>
                    <li><a href="#" target="_blank">LOs Analysis</a></li>
                    <li><a href="#" target="_blank">Calculator Usage Notes</a></li>
                  </ul>
                </div>
              </div>
            </div> */}

            {/* Company Info */}
            <div className="col-lg-2">
              <div className="col-lg-8 col-md-4 col-6 d-flex justify-content-lg-center pt-4 pt-lg-0">
                <div className="footer-widget">
                  <h4 className="footer-widget-title">Company</h4>
                  <ul className="footer-links">
                    <li><a href="#">About</a></li>
                    <li><a href="#">Why Risk Hub</a></li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6  justify-content-end pt-4 pt-lg-0">
              <div className="footer-widget">
                  <h4 className="footer-widget-title">Contact</h4>
                  <ul className="footer-contact-links"> { /* CSS 10 */ }
                      {/* <li><span>Call :</span><a href="tel:7017234484"> +91 70172 34484</a></li> */}
                      <li><span>Email :</span><a href="mailto:riskhuborg@gmail.com"> riskhuborg@gmail.com<span className="__cf_email__"></span></a></li>
                  </ul>
              </div>
          </div>
          </div>

          {/* Footer Copyright */}
          <div className="row">
            <div className="col-lg-12">
              <div className="footer-copyright text-center"> { /* CSS 11 */ }
                <p>Copyright©<a href="#">Risk Hub</a>. All rights reserved</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;