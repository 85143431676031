import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;

const authProvider = {
  // Called when the user attempts to log in
  login: async ({ username, password }) => {
    try {
      const response = await axios.post(`${baseUrl}/api/admin/login`, {
        username,
        password,
      });

      const { token } = response.data;
      localStorage.setItem('authToken', token); // Store the token locally
    } catch (error) {
      throw new Error('Invalid username or password');
    }
  },

  // Called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem('authToken');
    return Promise.resolve();
  },

  // Called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('authToken');
      return Promise.reject();
    }
    return Promise.resolve();
  },

  // Called when the user navigates to a new location
  checkAuth: () => {
    return localStorage.getItem('authToken')
      ? Promise.resolve()
      : Promise.reject();
  },

  // Called to get the user's identity
  getIdentity: () => {
    const token = localStorage.getItem('authToken');
    if (!token) return Promise.reject();
    return Promise.resolve({ id: 'user', fullName: 'User' });
  },

  // Called to get permissions for the current user
  getPermissions: () => Promise.resolve(),
};

export default authProvider;
