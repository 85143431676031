import React from 'react'
import HeadComponent from '../Partials/HeadComponent'
import NavBar from '../NavBar/NavBar'
import Footer from '../Footer/Footer'
import RegisterSection from '../Registration/RegistrationSection'
import Testimonials from '../Testimonials/Testimonials'
import OurPrograms from '../OurPrograms/OurPrograms'
import WebinarCard from '../WebinarCard/WebinarCard'
import TimerCard from '../TimerCard/TimerCard'
import CompanyBanner from '../CompanyBanner/CompanyBanner'
import HeadingCarousal from '../HeadingCarousal/HeadingCarousal'


function HomePage() {
  return (
    <div>
        <HeadingCarousal/>
      <div>
        <div class="edu-hero-style">
        <div class="container">
            <div class=" justify-content-lg-between pb-5 pb-lg-0 item-alignment">
                <div class="col-lg-12 order-1 order-lg-0">
                    <div class="edu-hero-content">
                        <h2 class="edu-hero-title">Clear <span class="title-mark title-mark-section">FRM Exam<br/>
                            </span>
                            <p>Embark on a journey of focused and disciplined learning guided by our
                                experienced mentors</p>
                        </h2>
                        <div class="edu-hero-btn">
                            <a href="http://t.me/FRM_I" target="_blank" class="edu-btn-fill">Join Our Community</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-10 py-lg-5 py-4 order-0 order-lg-1">
                    <div class="ag-hero-figure">
                        <img src="assets/images/education/edu-hero-figure.png" alt="" class="img-fulid"/>
                        {/* <img src="{{asset('assets/images/education/edu-hero-figure.png')}}" alt="" class="img-fulid"> */}

                        <div class="florting-mail-label">
                            <div class="icon"><i class="bi bi-geo-alt-fill"></i></div>

                            <h6>Congratulations!</h6>
                            <p>You are on track</p>
                        </div>
                        <div class="florting-mail-label-two">
                            <div class="icon"><i class="bi bi-heart"></i></div>
                            <h4>3k+</h4>
                            <p>Happy Students</p>
                            <img src="assets/images/education/avater-group.png" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

        <OurPrograms/>

        <Testimonials/>

        <WebinarCard/>

        {/* <TimerCard/> */}

        <CompanyBanner/>

        <RegisterSection/>

        <Footer/>
      </div>
    </div>
  )
}

export default HomePage
