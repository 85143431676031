// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { Link, useNavigate } from 'react-router-dom';
// import { clearAuthState } from '../../redux/auth/authSlice';
// import { persistor } from '../../store/store';
// import './NavBarLogin.css'
// import axios from 'axios';
// import { clearPaidPrograms } from '../../redux/auth/paidProgramSlice';

// const NavBarLogin = () => {
//     const dispatch = useDispatch();
//     const navigate = useNavigate();
//     const user = useSelector((state) => state.auth.user);
//     const profileImage = user?.profileImage || '/assets/images/cart/avatarmenprofile.png';
//     const baseUrl = process.env.REACT_APP_BASE_URL;
//     const [menuActive, setMenuActive] = useState(false);
//     const [paidPrograms, setPaidPrograms] = useState([]);
//     const toggleMenu = () => {
//         setMenuActive(!menuActive)
//     }

//     useEffect(() => {
//       const fetchPrograms = async () => {
//         try{
//           const response = await axios.get(`${baseUrl}/api/users/all`)
  
//           const programs = await response.data.programs;
//           console.log("Purchased Programs:", programs);
//           setPaidPrograms(programs);
          
//         } catch (err) {
//           console.error(err);
//         }
//       }
//       fetchPrograms();
//     }, [])

//     useEffect(() => {
//         const handleScroll = () => {
//           const header = document.getElementById('header');
//           if (window.scrollY > 0) {
//             header.classList.add('sticky');
//           } else {
//             header.classList.remove('sticky');
//           }
//         };
    
//         // Add the scroll event listener
//         window.addEventListener('scroll', handleScroll);
    
//         // Clean up the event listener on component unmount
//         return () => {
//           window.removeEventListener('scroll', handleScroll);
//         };
//       }, []);

//     const getUrl = (program) => {
//       if(program.program_name === 'FRM I Test Series')
//       return `/frm-i/frm-i-test-series/all/${program.id}`;
//       else if(program.program_name === 'FRM II Test Series')
//       return `/frm-ii/frm-ii-test-series/all/${program.id}`;
//       else if(program.program_name === 'SCR Test Series')
//       return `/scr/scr-test-series/all/${program.id}`;
//     }  

//     const handleLogout = async () => {
//         persistor.pause();
//         persistor.flush().then(() => {
//         return persistor.purge();
//         });
//         localStorage.clear();
//         dispatch(clearAuthState());
//         dispatch(clearPaidPrograms());
//         navigate('/');
//     }

//     return (
//         <header>
//             {/* Menu */}
//             <nav>
//                 <div className="header-menu-area eu-menu" id="header"> { /* CSS 1 */}
//                     <div className="container"> { /* CSS 2 */}
//                         <div className="row align-items-center justify-content-between">
//                             {/* <div className="col-xxl-3 col-xl-2 col-lg-2 col-sm-6 col-6"> */}
//                             <div className="col-lg-1 col-sm-6 col-6">
//                                 <div className="logo text-left"> { /* CSS 3 */}
//                                     <Link to="/profile">
//                                         <img src="/assets/images/risk-hub.png" alt="PyFin Academy Logo" className="main-logo" /> { /* CSS 4 */}
//                                     </Link>
//                                 </div>
//                             </div>
//                             <div className="col-lg-11 col-sm-6 col-6">
//                                 <button className={`hidden-lg hamburger ${menuActive ? 'h-active' : ''}`} onClick={toggleMenu}>  { /* CSS 5 */}
//                                     <span className="h-top"></span>
//                                     <span className="h-middle"></span>
//                                     <span className="h-bottom"></span>
//                                 </button>
//                                 <nav className={`main-nav ${menuActive ? 'slidenav' : ''}`}> { /* CSS 6 */}
//                                     <div className="logo mobile-ham-logo d-lg-none d-block text-left">
//                                         {/* <Link to="/" className="mobile-logo"> */}
//                                             <img src="/assets/images/risk-hub.png" alt="Mobile Logo" />
//                                         {/* </Link> */}
//                                     </div>
//                                     <ul>
//                                                         <li className="has-child-menu">
//                                                           <a href="javascript:void(0)">FRM I</a>
//                                                           {!menuActive && <i className="bx bx-chevron-down"></i>}
//                                                           <i className="fl flaticon-plus">+</i>
//                                                           <ul className="sub-menu">
//                                                             <li><Link to="/frm-i/course/foundations-of-risk-management">Foundations of Risk Management</Link></li>
//                                                             <li><a href="#">Quantitative Analysis</a></li>
//                                                             <li><a href="#">Financial Markets and Products</a></li>
//                                                             <li><a href="#">Valuation and Risk Models</a></li>
//                                                             <li><a href="#">FRM I Mentorship and Guidance Program</a></li>
//                                                             <li><Link to="frm-i/frm-i-test-series">FRM I Test Series</Link></li>
//                                                           </ul>
//                                                         </li>
//                                                         <li className="has-child-menu">
//                                                           <a href="javascript:void(0)">FRM II</a>
//                                                           <i className="bx bx-chevron-down"></i>
//                                                           <i className="fl flaticon-plus">+</i>
//                                                           <ul className="sub-menu">
//                                                             <li><a href="#">Market Risk Measurement and Management</a></li>
//                                                             <li><a href="#">Credit Risk Measurement and Management</a></li>
//                                                             <li><a href="#">Operational Risk and Resilience</a></li>
//                                                             <li><a href="#">Liquidity Risk</a></li>
//                                                             <li><a href="#">Risk Management and Investment Management</a></li>
//                                                             <li><a href="#">FRM II Mentorship and Guidance Program</a></li>
//                                                             <li><Link to="frm-ii/frm-ii-test-series">FRM II Test Series</Link></li>
//                                                           </ul>
//                                                         </li>
//                                                         <li className="has-child-menu">
//                                                           <i className="bx bx-chevron-down"></i>
//                                                           <a href='#'>Test Series</a>
//                                                           <i className="fl flaticon-plus">+</i>
//                                                           <ul className="sub-menu">
//                                                             {paidPrograms.map((program) => (
//                                                               <li><Link to={getUrl(program)}>{program.program_name}</Link></li>
//                                                             ))}
//                                                           </ul>
//                                                         </li>
//                                                         {/* <li className="header-login-btn">
//                                                           <div className='login-btn-main' onClick={() => setIsLoginOpen(true)}>
//                                                             Log In
//                                                           </div>
//                                                         </li>
//                                                         <li className="header-login-btn">
//                                                           <div className='login-btn-main' onClick={() => setIsSignupOpen(true)}>
//                                                             Sign Up
//                                                           </div>
//                                                         </li> */}
//                                                       </ul>
//                                     <ul>
//                                         <li className="has-child-menu profile-image">
//                                             <a href="#">
//                                                 <img 
//                                                     src={profileImage} 
//                                                     alt="Profile" 
//                                                     style={{
//                                                         maxWidth: '50px',
//                                                         width: '50px',
//                                                         height: '50px',
//                                                         borderRadius: '50%',
//                                                         objectFit: 'cover'
//                                                     }}
//                                                 />
//                                             </a>
//                                             {!menuActive && <i class="bx bx-chevron-down" style={{'margin-top':'8px'}}></i>}
//                                             <i className="fl flaticon-plus">+</i>
//                                             <ul className="sub-menu">
//                                                 <li><a href="/settings/edit">Settings</a></li>
//                                                 <li><a href="/profile">My Profile</a></li>
//                                                 <li><a href="#" onClick={handleLogout}>Log Out</a></li>
//                                             </ul>
//                                         </li>
//                                     </ul>
//                                 </nav>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </nav>
//             {/* Menu end */}
//         </header>
//     );
// };

// export default NavBarLogin;
