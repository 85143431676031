import React from 'react';
import FRMCard from '../FRMCard/FRMCard';

const FRMCards = () => {
  return (
    <div className="featured-area positioning">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="hrdd-section-title">
              <h1>Take the Next Step Towards FRM Certification</h1>
            </div>
          </div>
        </div>
        <div className="row justify-content-left featured-area-card">
          <FRMCard
            imgSrc="assets/images/pyfinimg/frm-i-integrated-test-series.png" 
            title="FRM I Integrated Test Series" 
            tests="10" 
            quizzes="50" 
            rating="4.9" 
            link="/frm-i/frm-i-integrated-test-series"
          />
          <FRMCard 
            imgSrc="assets/images/pyfinimg/frm-ii-integrated-test-series.jpeg" 
            title="FRM II Integrated Test Series" 
            tests="10" 
            quizzes="50" 
            rating="4.9" 
            link="/frm-ii/frm-ii-integrated-test-series"
          />
        </div>
      </div>
    </div>
  );
};

export default FRMCards;
