import React from "react";
import { Datagrid, EditButton, Filter, List, TextField, TextInput } from "react-admin";

const UpdateCourseFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Exam" source="exam_name" alwaysOn />
        <TextInput label="Course" source="course_name" alwaysOn/>
        <TextInput label="Lesson" source="lesson_name" alwaysOn/>
    </Filter>
)

const UpdateCourseList = (props) => (
    <List {...props} filters={<UpdateCourseFilter />} >
        <Datagrid bulkActionButtons={false}>
            <TextField label="Exam" source="exam_name" />
            <TextField label="Course" source="course_name" />
            <TextField label="Lesson" source="lesson_name" />
            <EditButton />
        </Datagrid>
    </List>
);

export default UpdateCourseList;