import React, { useState, useEffect } from 'react';
import Footer2 from '../Footer/Footer2';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setProgramDetails } from '../../redux/auth/programSlice';

// Set up otherPrograms also  
const Profile = ({ otherPrograms }) => {
  const [qrSliderVisible, setQrSliderVisible] = useState(false);
  const [purchasedPrograms, setPurchasedPrograms] = useState([])
  const baseUrl = process.env.REACT_APP_BASE_URL
  const user = useSelector((state) => state.auth.user)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleQrSlider = () => {
    setQrSliderVisible(!qrSliderVisible);
  };

  useEffect(() => {
    const fetchModules = async () => {
      const token = localStorage.getItem('authToken')

      if(!token){
        console.log('Token not found')
      }

      try{
        const response = await axios.get(`${baseUrl}/api/users/paid-programs`,{
          headers: {
            'x-auth-token': token,
          }
        })
        
        const programs = await response.data.programs
        console.log('programs:', programs)
        setPurchasedPrograms(programs)
      }catch(error){
        console.error(error)
      }
    }
    fetchModules()
  }, [])

  const handleProgramView = (program) => {
    dispatch(setProgramDetails(program.program_name));
    navigate(`/public/frm-i/frm-i-integrated-test-series/all/${program.id}`);
  }

  return (
    <>
      <div className="edu-hero-style">
        <div className="container">
          <div className="sub-menu-bar all-test-navtags">
            <ul className="nav-tags">
              <li><a href="#" className="active">Home {'>'} </a></li>
              <li>Profile</li>
            </ul>
            <div className="date-time-scetion">
              <ul>
                <li>Exam Date: {user?.exam_date ? new Date(user.exam_date).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }) : ' '}</li>
                {/* Ask what is this days_remaining */}
                <li>Days Remaining: {user?.days_remaining ?? ' '}</li> 
              </ul>
            </div>
          </div>
          <h2 className="edu-section-title text-start">My Info</h2>
          <div className="col-lg-12 justify-content-lg-between align-items-center test-series-hero-content profile-info-card">
            <div className="col-lg-12 col-md-10 table-text">
              <img
                src={user?.profile_image ? `${user.profile_image}` : '/assets/images/cart/avatarmenprofile.png'}
                alt="Profile"
                style={{ maxWidth: '100px' }}
              />
              <div className="my-info-details">
                <h5>{user?.name}</h5>
                <p>Joined Date: {user?.logindate ? new Date(user.logindate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }) : ' '}</p>
              </div>
            </div>
          </div>
          <div className="bottom-line"></div>
        </div>
      </div>

      <div className="positioning">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="edu-section-title text-start">My Programs</h2>
            </div>
          </div>
          <div className="row featured-area-card profile-my-programs">
            {purchasedPrograms?.length > 0 ? purchasedPrograms.map(program => (
              <div key={program.id} className="col-lg-3 col-md-5 col-sm-12">
                <div className="purchesed-card">
                  <div className="featured-icon">
                    <img src="/assets/images/pyfinimg/frm-i-integrated-test-series.png" alt="Program" />
                  </div>
                  <div className="featured-content">
                    <h2>{program.program_name}</h2>
                    <div className="purchesed-inner-content">
                      <ul>
                        <p>📚 10 Tests</p>
                        <p>📝 50 Quizzes</p>
                      </ul>
                      <ul>
                        <p>🔢 Performance Analysis</p>
                        <p>💬 Discussion Forums</p>
                      </ul>
                    </div>
                    {/* Remove Below line when fixing css */}
                    <p className="featured-content-rating"></p>
                  </div>
                  <div className="enroll-btns">
                    {/* <a href={`/test/${program.id}`} className="enroll-btn">View {'>>'}</a> */}
                    <a className="enroll-btn" onClick={() => handleProgramView(program)}>View {'>>'}</a>
                    {/* <Link to="public/frm-i/frm-i-integrated-test-series/all/1" classname="enroll-btn">View {'>>'}</Link> */}
                  </div>
                </div>
              </div>
            )) : (
              <p>No purchased programs</p>
            )}
          </div>
          <div className="bottom-line-2"></div>
        </div>
      </div>

      <div className="positioning">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="edu-section-title text-start">Other Programs</h1>
            </div>
          </div>
          <div className="row featured-area-card profile-my-programs">
            {otherPrograms?.length > 0 ? otherPrograms.map(program => (
              <div key={program.id} className="col-lg-3 col-md-6 col-sm-12">
                <div className="featured-box">
                  <div className="featured-icon">
                    <img src="/assets/images/pyfinimg/frm-i-integrated-test-series.png" alt="Program" />
                  </div>
                  <div className="featured-content">
                    <h2>{program.program_name}</h2>
                    <div className="featured-inner-content">
                      <ul>
                        <li>📚 10 Tests</li>
                        <li>📝 50 Quizzes</li>
                      </ul>
                      <ul>
                        <li>🔢 Performance Analysis</li>
                        <li>💬 Discussion Forums</li>
                      </ul>
                    </div>
                    {/* Remove Below line when fixing css */}
                    <p className="featured-content-rating"></p>
                  </div>
                  <div className="enroll-btns">
                    <a href="#" className="enroll-btn" onClick={handleQrSlider}>Enroll Now</a>
                  </div>
                </div>
              </div>
            )) : (
              <p>No other programs available</p>
            )}
          </div>
        </div>
      </div>

      {qrSliderVisible && (
        <div id="qrSlider" className="slider-visible">
          <div className="mobile-frame">
            <div className="qr-content">
              <button id="closeButton" className="close-btn" onClick={handleQrSlider}>&times;</button>
              <img src="/assets/images/pyfinimg/qr-1.png" alt="QR Code" />
              <div className="qr-code-instructions">
                <p>Please scan and pay. After payment, share a screenshot to the below WhatsApp Number:</p>
                <p>WhatsApp: 📱 +91 7007 3430 16</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer2/>
    </>
  );
};

export default Profile;
