import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom'
import { setLoggedIn, setUser } from '../../redux/auth/authSlice';
import axios from 'axios';

let isCalled = false;

function GoogleCallback() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const baseUrl = process.env.REACT_APP_BASE_URL
    
    useEffect(() => {
        const handleGoogleCallback = async () => {
            if(isCalled) return;
            isCalled = true;
            const urlParams = new URLSearchParams(location.search);
            const code = urlParams.get('code');
            console.log('CODE', code);

            if(code){
                try{

                    // console.log('Before making API call');
                    const response = await axios.get(`${baseUrl}/api/auth/google-signin?code=${code}`);
                    // const response = await fetch(`http://localhost:8080/api/auth/google-login?code=${code}`);
                    console.log('Response:', response)
                    if(response.status !== 200)
                    {
                        throw new Error(`Response error status: ${response.status}`)
                    }
                    // console.log(response.data)
                    const { token, user } = response.data
                    // console.log('Token:', token);
                    // console.log('User:', user);
                    if(token)
                    {
                        dispatch(setLoggedIn(true));
                        dispatch(setUser(user));
                        localStorage.setItem('authToken', token);
                        navigate('/profile');
                    } else {
                        throw new Error('Token not found in response');
                    }
                } catch (err) {
                    console.error('Error during Google Signin');
                }
            } else {
                console.error('Authorization code not found');
            }
        }

        handleGoogleCallback();
    }, []);

  return (
    <div>
      Redirecting...
    </div>
  )
}

export default GoogleCallback
