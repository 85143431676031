// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edu-hero-style {
  /* background: linear-gradient(90.63deg, #FFFBF4 21.32%, #F7FCF9 132.04%); */
  overflow-x: hidden;
  padding-top: 100px;
}

.course-page {
    display: flex;
    font-family: Arial, sans-serif;
    height: 100vh;
    overflow: hidden;
  }
  
  .sidebar {
    width: 300px;
    overflow-y: auto;
    background-color: #f8f9fa;
    padding: 20px;
    border-right: 1px solid #ddd;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  }
  
  .sidebar h3 {
    margin: 0;
    margin-bottom: 15px;
  }
  
  .course-section {
    margin-bottom: 20px;
  }
  
  .course-section h4 {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .course-section p {
    margin: 0;
    color: #2d7a46;
    font-size: 14px;
  }
  
  .search-bar {
    width: 100%;
    padding: 8px;
    margin-top: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .menu {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .menu li {
    margin-bottom: 10px;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.2s ease;
  }
  
  .menu li:hover {
    background-color: #e9ecef;
  }
  
  .example-block {
    background: blue;
  }`, "",{"version":3,"sources":["webpack://./src/components/CourseContentPage/CourseContentPage.css"],"names":[],"mappings":"AAAA;EACE,4EAA4E;EAC5E,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,aAAa;IACb,gBAAgB;EAClB;;EAEA;IACE,YAAY;IACZ,gBAAgB;IAChB,yBAAyB;IACzB,aAAa;IACb,4BAA4B;IAC5B,wCAAwC;EAC1C;;EAEA;IACE,SAAS;IACT,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;IACE,SAAS;IACT,cAAc;IACd,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;IAChB,UAAU;IACV,SAAS;EACX;;EAEA;IACE,mBAAmB;IACnB,aAAa;IACb,eAAe;IACf,kBAAkB;IAClB,sCAAsC;EACxC;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,gBAAgB;EAClB","sourcesContent":[".edu-hero-style {\n  /* background: linear-gradient(90.63deg, #FFFBF4 21.32%, #F7FCF9 132.04%); */\n  overflow-x: hidden;\n  padding-top: 100px;\n}\n\n.course-page {\n    display: flex;\n    font-family: Arial, sans-serif;\n    height: 100vh;\n    overflow: hidden;\n  }\n  \n  .sidebar {\n    width: 300px;\n    overflow-y: auto;\n    background-color: #f8f9fa;\n    padding: 20px;\n    border-right: 1px solid #ddd;\n    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);\n  }\n  \n  .sidebar h3 {\n    margin: 0;\n    margin-bottom: 15px;\n  }\n  \n  .course-section {\n    margin-bottom: 20px;\n  }\n  \n  .course-section h4 {\n    margin-bottom: 5px;\n    font-weight: bold;\n  }\n  \n  .course-section p {\n    margin: 0;\n    color: #2d7a46;\n    font-size: 14px;\n  }\n  \n  .search-bar {\n    width: 100%;\n    padding: 8px;\n    margin-top: 10px;\n    border: 1px solid #ddd;\n    border-radius: 5px;\n  }\n  \n  .menu {\n    list-style: none;\n    padding: 0;\n    margin: 0;\n  }\n  \n  .menu li {\n    margin-bottom: 10px;\n    padding: 10px;\n    cursor: pointer;\n    border-radius: 5px;\n    transition: background-color 0.2s ease;\n  }\n  \n  .menu li:hover {\n    background-color: #e9ecef;\n  }\n  \n  .example-block {\n    background: blue;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
