import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    answer: {}
}

const answerReducer = createSlice({
    name: 'answers',
    initialState,
    reducers: {
        setAnswer: (state, action) => {
            const { questionId, option } = action.payload;
            const answers = state.answer;
            state.answer = {
                ...answers,
                [questionId]: option
            }

            console.log('Updated Answers:', state.answer);
        },
        // clearAnswer: () => initialState
        clearAnswer: (state, action) => {
            const callback = action.payload?.callback; // Extract callback if provided
      
            // Reset the state
            Object.assign(state, initialState);
      
            // Execute the callback after state is reset
            if (typeof callback === "function") {
              callback();
            }
        },      
    }
});

export const { setAnswer, clearAnswer } = answerReducer.actions;
export default answerReducer.reducer