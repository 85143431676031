import React from 'react';

const HeadComponent = () => {
  return (
    <>
      {/* Meta tags */}
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no" />
      {/* For CSRF token, if required */}
      {/* <meta name="csrf-token" content="YOUR_CSRF_TOKEN" /> */}
      <title>FRM | Home</title>
      <link rel="stylesheet" href="/assets/css/style.css" />
      <link rel="stylesheet" href="/assets/css/responsive.css" />
    </>
  );
};

export default HeadComponent;
