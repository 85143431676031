import React, { useEffect, useState } from "react";
import "./CourseContentPage.css";
import style from './CourseContentPage.module.css';
import { FaCheckCircle } from "react-icons/fa";
import axios from "axios";
import { useSelector } from "react-redux";
import MathJaxRenderer from "../MathJaxRenderer/MathJaxRenderer";
import Footer2 from "../Footer/Footer2";

const CourseContentPage = () => {
  const [content, setContent] = useState("<h2>Introduction</h2><p>Welcome to this course!</p>");
  const [courseData, setCourseData] = useState([])
  const [chapterId, setChapterId] = useState('');
  const [completedStatus, setCompletedStatus] = useState({});
  const [questions, setQuestions] = useState([]);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const user = useSelector((state) => state.auth.user);
  const [answers, setAnswers] = useState();
  const [solutions, setSolutions] = useState({});
  const [showExplanation, setShowExplanation] = useState(questions.map(() => false));

  // State to manage which courses are open (true for open, false for closed)
  const [openCourse, setOpenCourse] = useState(courseData.map(() => false));
  const [chapterIdIndex, setChapterIdIndex] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  // const courseData = [
  //   {
  //     courseTitle: "The Building Blocks of Risk Management",
  //     chapters: [{chapterName: "Risk and Risk Management", completed: false, chapterContent: "<h2>Risk and Risk Management</h2><p>Understand risk...</p>"}, {chapterName: "Risk Measures", completed: true, chapterContent: "<h2>Risk Measures</h2><p>Risk measures help...</p>"}, 
  //       {chapterName: "Expected Loss and Unexpedcted Loss", completed: false, chapterContent: "<h2>Expected and Unexpected Loss</h2><p>Learn more...</p>"}, {chapterName: "Risks and Rewards", completed: true, chapterContent: "<h2>Risks and Rewards</h2><p>Learn more...</p>"}, 
  //       {chapterName: "Types of Risks", completed: true, chapterContent: "<h2>Types of Risks</h2><p>Learn more...</p>"}, {chapterName: "Risk Factors", completed: true, chapterContent: "<h2>Risk Factors</h2><p>Learn more...</p>"}, {chapterName: "Revision", completed: true, chapterContent: "<h2>Revision</h2><p>Learn more...</p>"}
  //     ]
  //   },
  // ];

  useEffect(() => {
    const fetchCourseData = async () => {
      try{
        console.log("Fetching data");
        const response = await axios.get(`${baseUrl}/api/lesson/all`, { params: { id: '678d4d3e86bc8eb73b666162', user_id: user.id}});
        // console.log("This is Content Page response:", response);
        console.log("Fetched Data");
        const data = response.data;
        console.log("Content Page Data:", data);
        setCourseData(data);
        const completed = data.reduce((acc, lesson) => {
          lesson.chapters.forEach((chapter) => {
            acc[chapter.chapter_id] = chapter.completed;
          });
          return acc;
        }, {});
        const chapterIdArray = Object.keys(completed);
        console.log("Chapter Id Array:", chapterIdArray);
        console.log("Mapping:", completed);
        setCompletedStatus(completed);
        setChapterIdIndex(chapterIdArray);
      }catch(error){
        console.error(error);
      }
    }
    fetchCourseData();
  }, [])

  const handleChapterIdSelected = async (chapter_id) => {
    setChapterId(chapter_id);
    {chapterIdIndex.map((chapterIdItem, index) => {
      if(chapterIdItem === chapter_id)
        setCurrentIndex(index);
    })}
    console.log("Chapter_id:", chapterId);
    try{
      const token = localStorage.getItem('authToken');
      const answerResponse = await axios.get(`${baseUrl}/api/practice-questions/answers/${chapter_id}`, {
        headers: {
          'x-auth-token': token,
        }
      });
      setAnswers(answerResponse.data.answerList);
      setSolutions(answerResponse.data.solutionList);
      console.log("Answers Data:", answerResponse.data);
    } catch(error) {
      console.error(error);
    }
  }

  const handleOptionChange = (questionId, option) =>{
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: option
    }))
    console.log(answers);
  }

  const handleShowExplanation = (index) => {
    const updatedShowExplanation = [...showExplanation];
    updatedShowExplanation[index] = !updatedShowExplanation[index];
    setShowExplanation(updatedShowExplanation); 
  }

  const handleSubmit = async (questionId) => {
    console.log("QuestionId:", questionId)
    try{
      const response = await axios.get(`${baseUrl}/api/practice-questions/submit/${questionId}`)
      const data = response.data;
      setSolutions(prevSolutions => ({
        ...prevSolutions,
        [questionId]: {
          correct_option: data.correct_option,
          explanation: data.explanation
        }
      }))
      console.log("This is solutions:", solutions);
      const token = localStorage.getItem('authToken');
      const savedAnswerResponse = await axios.put(`${baseUrl}/api/practice-questions/submit/${questionId}`, 
        {submitted_answer: answers[questionId]},
        {
          headers: {
            'x-auth-token': token,
          }
        }
      );
      console.log("Submitted answer:", savedAnswerResponse.data.submitted_answer);
    } catch(error) {
      console.log(error);
    }
  }

  const handleClearSolution = async (questionId) => {
    setSolutions(prevSolutions => {
      const updatedSolutions = {...prevSolutions};
      delete updatedSolutions[questionId];
      return updatedSolutions;
    })

    setAnswers(prevAnswers => {
      const updatedAnswers = {...prevAnswers};
      delete updatedAnswers[questionId];
      return updatedAnswers;
    })

    try{
      const token = localStorage.getItem('authToken');
      const response = await axios.delete(`${baseUrl}/api/practice-questions/submit/${questionId}`, 
        {
          headers: {
            'x-auth-token': token,
          }
        })
      console.log("Deleted Response:", response);
    } catch(error) {
      console.error(error);
    }
  }

  const handleCompleteState = async () => {
    try{
      const response = await axios.post(`${baseUrl}/api/chapter/chapter-status`, {user_id: user.id, chapter_id: chapterId, state: !completedStatus[chapterId]})
      setCompletedStatus((prevState) => ({
        ...prevState,
        [chapterId]: !prevState[chapterId]
      }));
      console.log("Complete status Response:", completedStatus);
    }catch(error){
      console.error(error);
    }
  }

  useEffect(() => {
    console.log("This is answers:", answers);
    console.log("This is solutions:", solutions);
  }, [answers, solutions]);
  
  // Function to toggle the clicked course
  const toggleCourse = (index) => {
    // Toggle the course at the clicked index without affecting others
    setCurrentIndex(index);
    const newOpenCourse = [...openCourse];
    newOpenCourse[index] = !newOpenCourse[index];
    setOpenCourse(newOpenCourse);
  };

  const handleChapterPrevChange = async () => {
    if(currentIndex > 0)
    {
      await handleChapterIdSelected(chapterIdIndex[currentIndex-1]);
      {const courseContent = courseData.map((course) => {
        course.chapters.map((chapter) => {
          if(chapter.chapter_id === chapterIdIndex[currentIndex-1])
            {
              console.log("This is the prev content:", chapter.chapterContent);
              handleMenuClick(chapter.chapterContent);
              handleSetQuestions(chapter.questions);
            }
        })
      })}
      setCurrentIndex(currentIndex-1);
    }
  }

  const handleChapterNextChange = async () => {
    if(currentIndex < chapterIdIndex.length - 1)
      {
        await handleChapterIdSelected(chapterIdIndex[currentIndex+1]);
        {const courseContent = courseData.map((course) => {
          course.chapters.map((chapter) => {
            if(chapter.chapter_id === chapterIdIndex[currentIndex+1])
            {
              console.log("This is the next content:", chapter.chapterContent);
              handleMenuClick(chapter.chapterContent);
              handleSetQuestions(chapter.questions);
            }
          })
        })}
        setCurrentIndex(currentIndex+1);
      }
  }

  useEffect(() => {
    // console.log("CurrentIndex:", currentIndex);
    // console.log("Chapter Id:", chapterId);
    console.log(`Current Index: ${currentIndex}, Current Chapter Id: ${chapterId}`);
  }, [chapterId]);

  const handleMenuClick = (htmlContent) => {
    console.log("Setting content");
    setContent(htmlContent);
  };

  const handleSetQuestions = (questions) => {
    console.log("Questions Check:", questions);
    const sortedQuestions = [...questions].sort((a, b) => a.createdAt - b.createdAt);
    console.log("Create At sorted questions:", sortedQuestions);
    setQuestions(sortedQuestions);
  }


  return (
    <>
    <div className="edu-hero-style">
    <div className="course-page">
      {/* Left Sidebar */}
      <div className="sidebar">
        <div className="course-section">
          <h4>Foundations Of Risk Management</h4>
          <p>50% Completed</p>
          <div className={style.progressBar}>
            <div className={style.progress} style={{ width: `${50}%`, backgroundColor: '#2d7a46' }}></div>
          </div>
          <input type="text" placeholder="Search Course" className="search-bar" />
        </div>

        {courseData.map((course, index) => (
                        <div
                          key={index}
                          // className="platform-work-box"
                        >
                          <div className={style.platformContent}>
                            <div className={style.circleWrapper}>
                              <div className={style.circle}>
                                <span className={style.circleNumber}>{index + 1}</span>
                              </div>
                            </div>
        
                            <div className={style.chapterListContent}>
                            <h3 className={style.chapterContentHeading} onClick={() => toggleCourse(index)}> 
                              {course.courseTitle}
                            </h3>
                            <span className={`${style.arrow} ${openCourse[index] ? `${style.rotate}` : ''}`} onClick={() => toggleCourse(index)}>
                              <i className="bi bi-chevron-down"></i>
                            </span>
                            {/* <p className={openCourse[index] ? '' : 'hide'}>{course.answer}</p> */}
                            <p className={openCourse[index] ? '' : `${style.hide}`}>
                            <div>
                              {course.chapters.map((chapter, index) => (
                                <div key={`chapter-${index}`} 
                                className={`${style.topics} ${index === course.chapters.length - 1 ? style.noBorder : ''}`}
                                onClick={() => {
                                  handleMenuClick(chapter.chapterContent)
                                  handleChapterIdSelected(chapter.chapter_id)
                                  handleSetQuestions(chapter.questions);
                                }} 
                                >
                                  <h4>{completedStatus[chapter.chapter_id] ? (<><FaCheckCircle className={style.completedCircle}/></>) : (<span className={style.notCompletedCircle}></span>)} {chapter.chapterName}</h4>
                                </div>
                              ))}
                            </div>
                            </p>
                            <div style={{ width: '90%', borderBottom: '2px black solid', margin: '20px 0' }}></div>
                            </div>
                          </div>
                        </div>
                      ))}

      </div>

      {/* Right Content */}
      <div className={style.content}>
        <div className={style.chapterContent} dangerouslySetInnerHTML={{ __html: content }} />
        <div className={style.questionsContent}>
        <h1>Practice Questions</h1>
        {/* {questions.map((question, index) => (
          <div key={`question_${index}`}>
          <h1>Hello</h1>
          <h1>{question.question}</h1>
          <h1>{question.explanation}</h1>
          </div>)
        )} */}
        {questions.map((questionItem, index) => (
          <div className={style.testQuestions} key={`question_block_${index}`} >
            <div>Q {index+1}.</div>
            <div className={style.questionContainer} key={`question_container_${index}`}>
              <p>
                <MathJaxRenderer content={questionItem.question}/>
              </p>
              
              {!solutions[questionItem._id] ? (<div className="options">
               {["A", "B", "C", "D"].map((option) => (
                <div key={option}>
                  <label className="option" key={option}>
                  <input 
                    key={`label_${option}`}
                    type="radio"
                    name={`answer_${index}`}
                    value={option}
                    onChange={() => handleOptionChange(questionItem._id, option)}
                  />
                  <span>{option}</span>. <span>{questionItem[`option_${option}`]}</span>
                  </label>
                </div>
               ))}
              </div>) : (
                 <div className="test-answer-key-options"> { /* CSS 16 */ }
                 <div className="test-answer-key-options">
                     {['A', 'B', 'C', 'D'].map(optionKey => {
                         const option = `option_${optionKey}`;
                         const isSelected = (answers[questionItem._id] === optionKey);
                         const isCorrect = (solutions[questionItem._id].correct_option === optionKey);
                         const optionClass = isSelected && isCorrect ? 'correct-answer' :
                             isSelected && !isCorrect ? 'incorrect-answer' : 
                             !isSelected && isCorrect ? 'correct-answer' : ''; { /* CSS 17 */ }

                         return (
                             <div key={optionKey} className="test-answer-key-options">
                                 <label key={optionKey} className={`test-answer-key-option ${optionClass}`}>
                                     <input key={`${optionKey}_input`} type="radio" name={`answer${index}`} value={optionKey} disabled />
                                     <span key={`${optionKey}_span_radio`} className={`custom-radio ${optionClass}`}></span> { /* CSS 18 */ }
                                     <span>{optionKey}</span>. <span key={`${optionKey}_span_option`}>{questionItem[`option_${optionKey}`]}</span>
                                 </label>
                             </div>
                         );
                     })}
                 </div>
             </div>
              ) }
              {/* <div className={style.submitExamBtns} key={`buttons_${index}`}>
                {!solutions[questionItem._id] ? (<div className={style.submitExamBtn} key={`submit_button_${index}`}>
                <button 
                onClick={() => handleSubmit(questionItem._id)}
                >Submit</button>
                </div>) : 
                (<div className={style.submitExamBtn} key={`clear_answer_${index}`}>
                <button 
                onClick={() => handleClearSolution(questionItem._id)}
                >Clear Answer</button>
                </div>)}
                {<div className={style.submitExamBtn} key={`explanation_button_${index}`}>
                <button>Show Explanation</button>
                </div>}
              </div> */}
              <div className={style.submitExamBtns} key={`buttons_${index}`}>
                <div>
                  {!solutions[questionItem._id] ? (
                    <button 
                    disabled = {!answers || !answers[questionItem._id]}
                    onClick={() => handleSubmit(questionItem._id)}
                    className={`${style.submitExamBtn} ${!answers || !answers[questionItem._id] ? style.disabledBtn : ''}`}
                    >Submit</button>
                  ) : (
                    <button 
                    className={`${style.submitExamBtn}`}
                    onClick={() => handleClearSolution(questionItem._id)}
                    >Clear Answer</button>
                  )}
                </div>
                {/* {!solutions[questionItem._id] ? (<div className={style.submitExamBtn}>
                  <button>Show Explanation</button>
                </div>) : (

                ) */}
                {/* <div className="hide-answre-btn"> */}
                <div>
                    <button
                    disabled = {!solutions || !solutions[questionItem._id]}
                    className={`${style.submitExamBtn} ${!solutions || !solutions[questionItem._id] ? style.disabledBtn : ''}`}
                    onClick={() => handleShowExplanation(index)}
                    >
                        {!showExplanation[index] ? 'Hide Explanation' : 'Show Explanation'}
                    </button>
                </div>
              </div>
              <div className="hidden-answre" style={!showExplanation[index] ? { display: 'block' } : {}} dangerouslySetInnerHTML={{ __html: solutions[questionItem._id]?.explanation }}></div>
            </div>
          </div>
        )
      )}
      </div>
        <div className={style.chapterChangeBtns}>
          <button className={style.submitExamBtn} onClick={handleCompleteState}> {!completedStatus[chapterId] ? 'Mark as Complete' : 'Mark as Incomplete'}</button>
          <div className={style.prevNextBtns}>
          <button className={`${style.submitExamBtn} ${currentIndex === 0 ? style.hideBtn : ''}`} onClick={handleChapterPrevChange}>Previous</button>
          <button className={`${style.submitExamBtn} ${currentIndex === chapterIdIndex.length - 1 ? style.hideBtn : ''}`} onClick={handleChapterNextChange}>Next</button>
          </div>
        </div>
      </div>
    </div>
    </div>
    {/* <Footer2 /> */}
    </>
  );
};

export default CourseContentPage;
