import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import {setUser, setLoggedIn} from '../../redux/auth/authSlice'

const LoginPage = () => {

    const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID  
    const googleRedirectUri = process.env.REACT_APP_GOOGLE_REDIRECT_URI
    const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${googleClientId}&redirect_uri=${googleRedirectUri}&response_type=code&scope=profile%20email`;
    const baseUrl = process.env.REACT_APP_BASE_URL;
    // console.log('client', REACT_APP_GOOGLE_CLIENT_ID)
    // console.log('uri', uri)
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setErrors({});
        try {
            const response = await axios.post(`${baseUrl}/api/auth/login`, {
                email,
                password,
            });

            if (response.status === 200) {
                const { token, user } = response.data
                console.log('Token:', token)
                console.log('User:', user);
                localStorage.setItem('authToken', token);
                dispatch(setLoggedIn(true))
                dispatch(setUser(user))
                navigate('/public/profile');
            }
        } catch (error) {
            setIsSubmitting(false);
            if (error.response && error.response.data) {
                setErrors(error.response.data.errors);
            } else {
                setErrors({ general: 'Something went wrong. Please try again later.' });
            }
        }
    };

    return (
        <>
            {/* Button to open modal */}
            <button onClick={() => setIsModalOpen(true)} className="open-login-btn">Login</button>

            {/* Modal Overlay */}
            {isModalOpen && (
                <div className="modal-overlay" onClick={() => setIsModalOpen(false)}>
                    <div className="modal-content-split" onClick={(e) => e.stopPropagation()}>
                        {/* Left side with image */}
                        <div className="login-image-section">
                            <img src="/assets/images/vector/man-sitting-on-desk.jpg" alt="Login" className="login-image" />
                        </div>

                        {/* Right side with existing login form */}
                        <div className="login-form-section">
                            <form onSubmit={handleSubmit} className="contact-input login-page-box-shadow">
                                <div className="login-title">
                                    <h1>Welcome to Risk Hub</h1>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-11 login-input-bg-color">
                                        <div className="row justify-content-center">
                                            <div className="col-xl-10">
                                                <label>Email Address</label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    placeholder="Enter Your Email"
                                                    required
                                                />
                                                {errors.email && <span className="text-danger">{errors.email}</span>}
                                            </div>
                                            <div className="col-xl-10">
                                                <label>Password</label>
                                                <input
                                                    type="password"
                                                    name="password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    placeholder="Enter Your Password"
                                                    required
                                                />
                                                {errors.password && <span className="text-danger">{errors.password}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {errors.general && <div className="text-danger text-center">{errors.general}</div>}

                                <div className="login-btn">
                                    <button type="submit" disabled={isSubmitting}>
                                        {isSubmitting ? 'Logging in...' : <span className="login-page-btn">Login</span>}
                                    </button>
                                </div>

                                {/* <div className="or-text">
                                    <h1>OR</h1>
                                </div> */}
                                <div className="or-text">
                                    <hr/>
                                    <div className="or">or</div>
                                    <hr/>
                                </div>
                                <div className="google-signin">
                                    <a href={googleAuthUrl}>
                                        <img src="assets/images/google.png" alt="Google Sign-In" />
                                        <span>Login with Google</span>
                                    </a>
                                </div>
                                <div  className='signup-now'>
                                <span>
                                        Don’t have an account yet? <a className="sign-up-text"><Link to='/signup'>Sign Up NOW!</Link></a>
                                </span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default LoginPage;
