import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {setUser, setLoggedIn} from '../../redux/auth/authSlice'
import './LoginPage.css'
import Modal from '../common/modal';

const LoginPage = ({ isOpen, onClose, openSignupModal }) => {
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const googleRedirectUri = process.env.REACT_APP_GOOGLE_REDIRECT_URI;
  const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${googleClientId}&redirect_uri=${googleRedirectUri}&response_type=code&scope=profile%20email`;
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrors({});
    try {
      const response = await axios.post(`${baseUrl}/api/auth/login`, {
        email,
        password,
      });

      if (response.status === 200) {
        const { token, user } = response.data;
        localStorage.setItem('authToken', token);
        dispatch(setLoggedIn(true));
        dispatch(setUser(user));
        navigate('/profile');
      } else {
        setIsSubmitting(false);
        setErrors({ general: 'Something went wrong. Please try again later.' });
      }
    } catch (error) {
      setIsSubmitting(false);
      if (error.response) {
        const { type, message } = error.response.data;
        if (type === 'USER_NOT_FOUND' || type === 'INVALID_PASSWORD') {
          setErrors({ general: message });
        } else if (type === 'SERVER_ERROR') {
          setErrors({ general: 'Internal server error. Please try again later.' });
        } else {
          setErrors({ general: 'Something went wrong. Please try again later.' });
        }
      } else if (error.request) {
        setErrors({ general: 'No response from server. Please try again later.' });
      } else {
        setErrors({ general: 'Something went wrong. Please try again later.' });
      }
    }
  };

  const handleClose = () => {
    onClose();
    // navigate('/');
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <div className="modal-content-split" onClick={(e) => e.stopPropagation()}>
        {/* Left side with image */}
        <div className="login-image-section">
          <img src="/assets/images/vector/man-sitting-on-desk.jpg" alt="Login" className="login-image" />
        </div>

        {/* Right side with existing login form */}
        <div className="login-form-section">
          <form onSubmit={handleSubmit} className="contact-input login-page-box-shadow">
            <div className="login-title">
              <h1>Welcome to Risk Hub</h1>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-11 login-input-bg-color">
                <div className="row justify-content-center">
                  <div className="col-xl-10">
                    <label>Email Address</label>
                    <input
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter Your Email"
                      required
                    />
                    {errors.email && <span className="text-danger">{errors.email}</span>}
                  </div>
                  <div className="col-xl-10">
                    <label>Password</label>
                    <input
                      type="password"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Enter Your Password"
                      required
                    />
                    {errors.password && <span className="text-danger">{errors.password}</span>}
                  </div>
                </div>
              </div>
            </div>

            {errors.general && <div className="text-danger text-center" style={{ paddingBottom: '16px' }}>{errors.general}</div>}

            <div className="login-btn">
              <button type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Logging in...' : <span className="login-page-btn">Login</span>}
              </button>
            </div>

            <div className="or-text">
              <hr />
              <div className="or">or</div>
              <hr />
            </div>
            <div className="google-signin">
              <a href={googleAuthUrl}>
                <img src="/assets/images/google.png" alt="Google Sign-In" />
                <span>Login with Google</span>
              </a>
            </div>
            <div className="signup-now">
              <span>
                Don’t have an account yet? <a className="sign-up-text" onClick={openSignupModal}>Sign Up NOW!</a>
              </span>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default LoginPage;