import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Footer2 from '../Footer/Footer2';
import { useSelector } from 'react-redux';
import './TestStartPage.css'
import axios from 'axios';

const TestStartPage = ({ count }) => {
  const { examType, seriesType, id, moduleId } = useParams()
  const [module, setModule] = useState({})
  const baseUrl = process.env.REACT_APP_BASE_URL
  const navigate = useNavigate();
  const programName = useSelector((state) => state.programDetails.programName)
  const handleNavigation = () => {
    navigate(`/${examType}/${seriesType}/${id}/${moduleId}`, { replace:true })
  }
  useEffect(()=>{
    window.history.replaceState(null, '', window.location.href);
  }, [])

  useEffect(()=> {
    const fetchModule = async () => {
      const token = localStorage.getItem('authToken');
      if(token)
      {
        try{
          const response = await axios.get(`${baseUrl}/api/module/${moduleId}/info`, {
            headers: {
              'x-auth-token': token,
            }
          })
          const moduleData = response.data
          setModule(moduleData);
        } catch(err) {
          console.error(err)
        }
      }
    }

    fetchModule()
  }, [])
  

  return (
    <div>
      <div className="edu-hero-style">  { /* CSS 1 */}
        <div className="container"> { /* CSS 2 */}
          <div className="col-lg-6 lanuch">  { /* CSS 3 */}
            <div className="sub-menu-bar all-test-navtags col-lg-12 justify-content-start">
              <ul className="nav-tags">  { /* CSS 4 */}
                <li><Link to="/profile" className="active">Home &gt; </Link></li>
                {/* <li><Link to={`/alltest/${module.program.id}`} className="active">{module.program.program_name} &gt; </Link></li> */}
                <li><Link to={`/${examType}/${seriesType}/all/${id}`} className="active">{programName} &gt; </Link></li>
                <li>{module.module_name}</li>
              </ul>
            </div>
          </div>
          <div className="row justify-content-center align-items-center star-test-mobile-view">
            <div className="col-lg-8 col-md-10 col-lg-6 test-start-hero-content">
              <div className="sub-menu-bar all-test-navtags"></div>
              <div className="start-test-edu-hero-content">
                <div className="edu-hero-content-inner">
                  <h2 className="edu-section-title text-center">
                    {programName}-{module.module_name}
                  </h2>
                  <div className="test-start-subheding">  { /* CSS 9 */}
                    <div className="test-start-subhedings">  { /* CSS 10 */}
                      <ul>
                        <li>📚 {count} Questions</li>
                        <li>🔢 No Negative Marking</li>
                      </ul>
                      <ul>
                        <li>🎯 Multiple Choice Questions</li>
                        <li>⏱️ {module.duration/60} minutes</li>
                      </ul>
                      <ul>
                        <img src="/assets/images/pyfinimg/test-cover.png" alt="Test Cover" />
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row justify-content-center align-items-center star-test-mobile-view">
          <div className="col-lg-8 col-md-10 col-lg-6 test-instruction">  { /* CSS 11 */}
            <h3>Test Instructions</h3>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-8 col-md-10 col-lg-6">
            <p className="test-instruction-section-heading">*Please read before starting the test</p>  { /* CSS 12 */}
          </div>
        </div>
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-8 col-md-10 col-lg-6 test-instruction-containt"> { /* CSS 13 */}
            <div>
              <div className="test-instructions">  { /* CSS 14 */}
                <h4>Total Time</h4>
                <p>Total duration of examination is {module.duration/60} mins</p>
              </div>
              <div className="test-instructions">
                <h4>Exam Timer</h4>
                <p>
                  The clock has been set at the server and the countdown timer at the top right corner
                  of your screen will display the time remaining for you to complete the exam. When the clock runs out, the exam ends by default - you are not required to end or submit your exam.
                </p>
              </div>
              <div className="test-instructions">
                <h4>Navigating to a Question</h4>
                <p><b>A.</b> Click on a question number in the question palette on the right side of your screen to navigate directly to that specific question without losing your current answer.</p>
                <p><b>B.</b> Click on "Previous" to go to the previous question and "Next" to go to the next question.</p>
                <p><b>C.</b> Click on "Submit" to submit the exam.</p>
              </div>
            </div>
          </div>
          <div className="start-test-btn">  { /* CSS 15 */}
            {/* <Link to={`/testquestions/${module.id}`}>Start {module.module_name} &gt;&gt;</Link> */}
            <a href='#' onClick={handleNavigation}>Start {module?.module_name} &gt;&gt;</a>
          </div>
        </div>
      </div>
      <Footer2/>
    </div>
  );
};

export default TestStartPage;
