import React from 'react'

function CompanyBanner() {

    const companies = [
        {
            'name': 'JPMorgan chase', 'imageUrl': '/assets/images/companies-logo/JP-Morgan.png' 
        },
        {
            'name': 'Bank of America', 'imageUrl': '/assets/images/companies-logo/Bank-Of-America.png' 
        },
        {
            'name': 'EY', 'imageUrl': '/assets/images/companies-logo/EY.png' 
        },
        {
            'name': 'PwC', 'imageUrl': '/assets/images/companies-logo/PwC.png' 
        },
        {
            'name': 'Citigroup', 'imageUrl': '/assets/images/companies-logo/Citigroup.png' 
        },
        {
            'name': 'Wells Fargo', 'imageUrl': '/assets/images/companies-logo/Wells-Fargo.png' 
        },
        {
            'name': 'Goldman Sachs', 'imageUrl': '/assets/images/companies-logo/Goldman-Sachs.png' 
        },
        {
            'name': 'KPMG', 'imageUrl': '/assets/images/companies-logo/KPMG.png' 
        },
        {
            'name': 'Morgan Stanley', 'imageUrl': '/assets/images/companies-logo/Morgan-Stanley.png' 
        },
        {
            'name': 'Capital One', 'imageUrl': '/assets/images/companies-logo/Capital-One.png' 
        },
        {
            'name': 'Deloitte', 'imageUrl': '/assets/images/companies-logo/Deloitte.png' 
        },
        {
            'name': 'BNY Mellon', 'imageUrl': '/assets/images/companies-logo/BNY-Mellon.png' 
        },
        {
            'name': 'State Street', 'imageUrl': '/assets/images/companies-logo/State-Street.png' 
        },
        {
            'name': 'American Express', 'imageUrl': '/assets/images/companies-logo/American-Express.png' 
        },
        {
            'name': 'HSBC', 'imageUrl': '/assets/images/companies-logo/HSBC.png' 
        }
    ]


  return (
    <div className="banner">
      <h1>Get offers from companies like</h1>
        <div className="company-logos">
            {companies.map((company, index) => (
                <div className="company-logo" key={index}>
                    <img src={company.imageUrl} alt="logo" className="company-logo-img" />
                </div>
            ))}
        </div>
    </div>
  )
}

export default CompanyBanner
