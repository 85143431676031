// filepath: /Users/uchitkumar/Desktop/risk-hub-frontend/src/redux/auth/programSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  redirectUrl: ''
};

const redirectUrlReducer = createSlice({
  name: 'redirectUrl',
  initialState,
  reducers: {
    setRedirectUrl: (state, action) => {
      console.log('Setting Redirect Url:', action.payload);
      state.redirectUrl = action.payload;
    },
    clearRedirectUrl: () => {
      console.log('Clearing Redirect Url');
      return initialState;
    },
  },
});

export const { setRedirectUrl, clearRedirectUrl } = redirectUrlReducer.actions;

export default redirectUrlReducer.reducer;