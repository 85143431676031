import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LoginPage from '../LoginPage/LoginPage';
import Signup from '../Signup/Signup';
import style from './NavBar.module.css';
import './NavBar.css';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { clearPaidPrograms } from '../../redux/auth/paidProgramSlice';
import { clearAuthState } from '../../redux/auth/authSlice';
import { persistor } from '../../store/store';

const NavBar = () => {
  const [menuActive, setMenuActive] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isSignupOpen, setIsSignupOpen] = useState(false);
  const [paidPrograms, setPaidPrograms] = useState([]); 
  const user = useSelector((state) => state.auth.user);
  const profileImage = user?.profileImage || '/assets/images/cart/avatarmenprofile.png';
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchPrograms = async () => {
      try{
        const response = await axios.get(`${baseUrl}/api/users/all`)

        const programs = await response.data.programs;
        console.log("Purchased Programs:", programs);
        setPaidPrograms(programs);
        
      } catch (err) {
        console.error(err);
      }
    }
    fetchPrograms();
  }, [])

  useEffect(() => {
          const handleScroll = () => {
            const header = document.getElementById('header');
            if (window.scrollY > 0) {
              header.classList.add('sticky');
            } else {
              header.classList.remove('sticky');
            }
          };
      
          // Add the scroll event listener
          window.addEventListener('scroll', handleScroll);
      
          // Clean up the event listener on component unmount
          return () => {
            window.removeEventListener('scroll', handleScroll);
          };
        }, []);

  const getUrl = (program) => {
    if(program.program_name === 'FRM I Test Series')
    return `/frm-i/frm-i-test-series/all/${program.id}`;
    else if(program.program_name === 'FRM II Test Series')
    return `/frm-ii/frm-ii-test-series/all/${program.id}`;
    else if(program.program_name === 'SCR Test Series')
    return `/scr/scr-test-series/all/${program.id}`;
  }

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  const openSignupModal = () => {
    setIsLoginOpen(false);
    setIsSignupOpen(true);
  };
  const openLoginModal = () => {
    setIsSignupOpen(false);
    setIsLoginOpen(true);
  }

  const handleLogout = async () => {
          persistor.pause();
          persistor.flush().then(() => {
          return persistor.purge();
          });
          localStorage.clear();
          dispatch(clearAuthState());
          dispatch(clearPaidPrograms());
          navigate('/');
      }

  return (
    <>
      <nav>
        {/* <div className={`header-menu-area eu-menu`} id="header"> */}
        <div className={`${style.headerMenuArea} eu-menu`} id="header">
        {/* <div className={`${style.headerMenuArea} ${style.euMenu}`} id="header"> */}
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-1 col-sm-6 col-6">
                <div className="logo text-left">
                  <Link to="/">
                    <img src="/assets/images/risk-hub.png" alt="Risk-Hub Logo" className={style.mainLogo} />
                  </Link>
                </div>
              </div>
              <div className="col-lg-11 col-sm-6 col-6">
                <a href="javascript:void(0)" className={`${style.hamburger} ${menuActive ? `${style.hActive}` : ''}`} onClick={toggleMenu}>
                  <span className={style.hTop}></span>
                  <span className={style.hMiddle}></span>
                  <span className={style.hBottom}></span>
                </a>
                {/* <nav className={`main-nav ${menuActive ? 'slidenav' : ''}`}> */}
                <nav className={`${style.mainNav} ${menuActive ? `${style.slidenav}` : ''}`}>
                  <div className={`${style.logo} mobile-ham-logo d-lg-none d-block text-left`}>
                    <a href="#" className="mobile-logo">
                      <img src="/assets/images/risk-hub.png" alt="Mobile Logo" />
                    </a>
                  </div>
                  <ul>
                    <li className={`${style.hasChildMenu}`}>
                    {/* <li className={`${style.hasChildMenu}`}> */}
                      <a href="javascript:void(0)">FRM I</a>
                      <i className="bx bx-chevron-down"></i>
                      <i className="fl flaticon-plus">+</i>
                      {/* <ul className="sub-menu"> */}
                      <ul className={`${style.subMenu}`}>
                        <li><Link to="/frm-i/course/foundations-of-risk-management">Foundations of Risk Management</Link></li>
                        <li><a href="#">Quantitative Analysis</a></li>
                        <li><a href="#">Financial Markets and Products</a></li>
                        <li><a href="#">Valuation and Risk Models</a></li>
                        <li><a href="#">FRM I Mentorship and Guidance Program</a></li>
                        <li><Link to="frm-i/frm-i-test-series">FRM I Test Series</Link></li>
                      </ul>
                    </li>
                    <li className={`${style.hasChildMenu}`}>
                    {/* <li className={`${style.hasChildMenu}`}> */}
                      <a href="javascript:void(0)">FRM II</a>
                      <i className="bx bx-chevron-down"></i>
                      <i className={`fl flaticon-plus`}>+</i>
                      {/* <ul className="sub-menu"> */}
                      <ul className={`${style.subMenu}`}>
                        <li><a href="#">Market Risk Measurement and Management</a></li>
                        <li><a href="#">Credit Risk Measurement and Management</a></li>
                        <li><a href="#">Operational Risk and Resilience</a></li>
                        <li><a href="#">Liquidity Risk</a></li>
                        <li><a href="#">Risk Management and Investment Management</a></li>
                        <li><a href="#">FRM II Mentorship and Guidance Program</a></li>
                        <li><Link to="frm-ii/frm-ii-test-series">FRM II Test Series</Link></li>
                      </ul>
                    </li>
                    <li className={`${style.hasChildMenu}`}>
                    {/* <li className={`${style.hasChildMenu}`}> */}
                    <a href="#">Test Series</a>
                      <i className="bx bx-chevron-down"></i>
                      <i className="fl flaticon-plus">+</i>
                      {/* <ul className="sub-menu"> */}
                      <ul className={`${style.subMenu}`}>
                        {paidPrograms.map((program) => (
                            <li><Link to={getUrl(program)}>{program.program_name}</Link></li>
                        ))}
                      </ul>
                    </li>
                    {}
                    {/* <li className="header-login-btn">
                      <div className='login-btn-main' onClick={() => setIsLoginOpen(true)}>
                        Log In
                      </div>
                    </li>
                    <li className="header-login-btn">
                      <div className='login-btn-main' onClick={() => setIsSignupOpen(true)}>
                        Sign Up
                      </div>
                    </li> */}
                  </ul>
                  {user ?                                     <ul>
                                        <li className={`${style.hasChildMenu} profile-image`}>
                                            <a href="#">
                                                <img 
                                                    src={profileImage} 
                                                    alt="Profile" 
                                                    style={{
                                                        maxWidth: '50px',
                                                        width: '50px',
                                                        height: '50px',
                                                        borderRadius: '50%',
                                                        objectFit: 'cover'
                                                    }}
                                                />
                                            </a>
                                            {!menuActive && <i className="bx bx-chevron-down" style={{'margin-top':'8px'}}></i>}
                                            <i className="fl flaticon-plus">+</i>
                                            {/* <ul className="sub-menu"> */}
                                            <ul className={`${style.subMenu}`}>
                                                <li><a href="/settings/edit">Settings</a></li>
                                                <li><a href="/profile">My Profile</a></li>
                                                <li><a href="#" onClick={handleLogout}>Log Out</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                  : 
                  <ul>
                  <li className={style.headerLoginBtn}>
                      <div className={style.loginBtnMain} onClick={() => setIsLoginOpen(true)}>
                        Log In
                      </div>
                    </li>
                    <li className={style.headerLoginBtn}>
                      <div className={style.signupBtnMain} onClick={() => setIsSignupOpen(true)}>
                        Sign Up
                      </div>
                    </li>
                  </ul>}
                </nav>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <LoginPage isOpen={isLoginOpen} onClose={() => setIsLoginOpen(false)} openSignupModal={openSignupModal} />
      <Signup isOpen={isSignupOpen} onClose={() => setIsSignupOpen(false)} openLoginModal={openLoginModal} />
    </>
  );
};

export default NavBar;