import React from 'react';
import { Link } from 'react-router-dom';
import style from './FRMCard.module.css';
import './FRMCard.css'
import { useDispatch } from 'react-redux';
import { setProgramDetails } from '../../../redux/auth/programSlice';

const FRMCard = ({ imgSrc, title, content1, content2, link, programName, paid }) => {
  const dispatch = useDispatch();

const handleSubmit = (program_name) => {
    dispatch(setProgramDetails(program_name));
}
  return (
    // <div className="col-lg-3 col-md-5 col-sm-12" data-wow-delay="200ms" data-wow-duration="1500ms">
      <div className={style.featuredBox}> { /* CSS 1 */ }
        <div className={style.featuredIcon}> { /* CSS 2 */ }
          <img src={imgSrc} alt={title} />
        </div>
        <div className={style.featuredContent}> { /* CSS 3 */ }
          <h2>{title.split("\n").map((line, index) => (
            <span key={index}>
              {line}
              <br />
            </span>
          ))}</h2>
          <div className="featured-inner-content"> { /* CSS 4 */ }
            <ul>
              <p>📚 {content1}</p>
              <p>📝 {content2}</p>
            </ul>
            <ul>
              <p>🔢 Performance Analysis</p>
              <p>💬 Discussion Forums</p>
            </ul>
          </div>
        </div>
        <div className={style.enrollBtns}> { /* CSS 6 */ }
          {/* <a href="#" className="enroll-btn">Enroll {'>>'}</a> */}
          <Link to={link} onClick={() => {handleSubmit(programName)}} className={style.enrollBtn}>{paid ? 'View' : 'Enroll'} {'>>'}</Link> { /* CSS 7 */ }
        </div>
      </div>
    // {/* </div> */}
  );
};

export default FRMCard;
