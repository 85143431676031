import React, { FC, useMemo } from 'react';
import { DatagridProps, useListContext, useRecordContext, Datagrid } from 'react-admin';

export const CurrentIndexField = (props) => {
  const record = useRecordContext(props);
  return record && record.__index__ !== undefined ? <span>{record.__index__} </span> : <span></span>;
};

export const DataGridWithIndex = React.forwardRef((props, ref) => {
  const { children, ...rest } = props;
  const { data: rawData } = useListContext();
  const data = useMemo(() => rawData?.map((dt, index) => ({ ...dt, __index__: index + 1 /* make it 1-based */ })) || [], [rawData]);
  return (
    <Datagrid {...rest} ref={ref} data={data}>
      <CurrentIndexField />
      {children}
    </Datagrid>
  );
});