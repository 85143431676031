import React from "react";
import { FaArrowRight, FaCalendarAlt } from "react-icons/fa";

const Card = ({ time, price, stars, title, type, description }) => {
  return (
    <li>
      <a href="https://topmate.io/prateek964" className="card">
        <div className="card-header">
          <div className="rating">★ {stars}</div>
          <h2>{title}</h2>
        </div>
        <div className="card__overlay">
          <div className="card__header">
            {/* <svg className="card__arc" xmlns="http://www.w3.org/2000/svg">
              <path />
            </svg> */}
            <div className="time-info">
                <FaCalendarAlt className="calendar-icon" />
                <div className="content">
                    <h1>{time}</h1>
                    <p>{type}</p>
                </div>
            </div>
            <div className="price-box">
                <span className="price">₹{price}</span>
                <span className="arrow-1"><FaArrowRight/></span>
            </div>
            {/* <div className="card__header-text">
              <h3 className="card__title">{title}</h3>
              <span className="card__status">{status}</span>
            </div> */}
          </div>
          <p className="card__description">{description}</p>
        </div>
      </a>
    </li>
  );
};

export default Card;
