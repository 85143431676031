import React from 'react'
import Footer2 from '../Footer/Footer2'
import CourseContent from './CourseContent';
import FRMCard from '../Integerated-test-series/FRMCard/FRMCard';
import './Foundation_Of_Risk_Management.css';
import style from './CoursePage.module.css';
import { useSelector } from 'react-redux';

function Foundation_Of_Risk_Management() {

    const user = useSelector((state) => state.auth.user)

    const courseObj = {
        'examName':'FRM I',
        'courseName':'Foundations of Risk Management',
        'chapters':'11',
        'practiceQuestions':'300',
        'watchHours':'50',
        'weightage':'20'
    }

  return (
    <div className={style.textContainer}>
        <div className="edu-hero-style"> { /* CSS 1 */ }

                        <div className={style.testSeriesHeader}>
                            <div className="row" style={{"padding-top":"10px"}}>
                                <div className="col-lg-6 content-positioning">
                                    <ul className={style.navTags}> { /* CSS 2 */ }
                                        <li><a href="/" className="active">Home {'>'} </a></li>
                                        <li><a href="#" className="active">{courseObj.examName} {'>'} </a></li>
                                        <li>{courseObj.courseName}</li>
                                    </ul>
                                </div>
                                <div className="col-lg-4 hidden-content-value"> { /* CSS 3 */ }
                                    <li>Hidden</li>
                                </div>
                            </div>
                            <div className="row">
                            <div className='col-lg-6 content-positioning'>
                                <h2 className={style.testHeading}>{courseObj.courseName}</h2> { /* CSS 5 */ }
                                <div className="test-details">
                                    <ul className={style.testDetail}>
                                        <li className={style.testDetailItem}>{courseObj.chapters} Chapters</li>
                                        <li className={style.testDetailItem}>{courseObj.practiceQuestions} Practice Questions</li>
                                        <li className={style.testDetailItem}>{courseObj.watchHours} Hours</li>
                                        <li className={style.testDetailItem}>{courseObj.weightage}% Exam Weightage</li>
                                    </ul>
                                </div>
                                <h2 className={style.testContentHeading}>Master key concepts, tackle your weak areas, and gear up for exam success with our expertly crafted test
                                series.</h2>
                                <div className={style.enrollNowBtn}> { /* CSS 16 */ }
                                    <a href="#">{user ? 'Continue Learning' : 'Enroll Now'}</a>
                                </div>
                                                </div>
                                <div className="col-lg-4 hidden-content-value"> { /* CSS 3 */ }
                                    <li>Hidden</li>
                                </div>                
                            </div>
                            </div>

                {/* <div className="row justify-content-center">
                    <div className=" col-lg-6  order-1 order-lg-0"> */}
                        <div className="edu-hero-content "> { /* CSS 4 */ }
                        <div className="row" style={{"margin":"0", "padding":"0"}}>
                            <div className=" col-lg-6  order-1 order-lg-0 content-positioning">

                                <h1 className="edu-section-title text-start mt-26  frm-i-heading">Overview </h1> { /* CSS 11 */ }
                                <ul className="what-include-content"> { /* CSS 12 */ }
                                    This course is designed to cover the topics in Book 1. Foundations of
                                    Risk Management. The content in this course is curated in a crisp
                                    manner by FRM certified professionals. Some of the high weightage
                                    topics covered in this course include CAPM, APT, Great Financial Crisis
                                    of 2008-2008, roles of board members, senior management and
                                    several commitess in the risk management department.
                                </ul>
                                
                                <div className="row justify-content-center">
                            <CourseContent/>
                            {/* </div> */}
                            <div className={style.downloadSyllabusBtn}> { /* CSS 17 */ }
                                <a href="{{asset('assets/pdf/FRM I- ITS- Syllabus and Schedule - Nov 2024.pdf')}} " target="_blank">Download Learning Objectives <i className="bi bi-download"></i> </a>
                            </div>
                            <h1 className="edu-section-title text-start mt-26 frm-i-heading">Your Learning Environment</h1> { /* CSS 18 */ }
                            <div className="prime-feature-bg"> { /* CSS 19 */ }
                                <div className="prime-features mt-26"> { /* CSS 20 */ }
                                    <div className="prime-feature"> { /* CSS 21 */ }
                                        <h3>Study Notes</h3>
                                        <h5>Comprehensive study material  which covers detailed reading notes, revision notes and formula
                                        sheets.</h5>
                                    </div>
                                    <div className="prime-feature-image">
                                        <img src="/assets/images/chapters/study-notes.png" alt="Test Anylysis" srcset=""/>
                                    </div>
                                </div>
                                <div className="prime-features mt-26">
                                    <div className="prime-feature">
                                        <h3>Practice Questions</h3>
                                        <h5>A rich question bank with answers and detailed explanations including solved examples, in-chapter
                                        quizzes and practice questions are included with each chapter.</h5>
                                    </div>
                                    <div className="prime-feature-image">
                                        <img src="/assets/images/chapters/revision-notes.png" alt="Test Anylysis" srcset=""/>
                                    </div>
                                </div>
                                <div className="prime-features mt-26">
                                    <div className="prime-feature">
                                        <h3>Gap Analysis</h3>
                                        <h5>Designed to analyse the gaps in all learning objectives and accordingly provide an effort
                                        estimation required to complete the relevant topics.</h5>
                                    </div>
                                    <div className="prime-feature-image">
                                        <img src="/assets/images/chapters/gap-analysis.png" alt="Test Anylysis" srcset=""/>
                                    </div>
                                </div>
                                <div className="prime-features mt-26">
                                    <div className="prime-feature">
                                        <h3>Interactive Learning</h3>
                                        <h5>Risk Hub’s interactive, text-based content accelerate learning by providing flexibility, convenience, and accessibility without any setup or dowload.</h5>
                                    </div>
                                    <div className="prime-feature-image">
                                        <img src="/assets/images/chapters/flexible.png" alt="Test Anylysis" srcset=""/>
                                    </div>
                                </div>
                                <div className="prime-features mt-26">
                                    <div className="prime-feature">
                                        <h3>Demonstrable Progress</h3>
                                        <h5>Track your learning journey with milestones and tangible results via visual progress indicators
                                        as you advance through the course. </h5>
                                    </div>
                                    <div className="prime-feature-image">
                                        <img src="/assets/images/chapters/formula.png" alt="Test Anylysis" srcset=""/>
                                    </div>
                                </div>
                            </div>

                            
                        </div>
                                
                        <div className="featured-area positioning"> { /* CSS 22 */ }
                            <div className="container"> { /* CSS 23 */ }
                                <div className="row">
                                <div className="col-12">
                                    <div className="hrdd-section-title"> { /* CSS 24 */ }
                                    <h1>Recommended Courses</h1>
                                    </div>
                                </div>
                                </div>
                                <div className={style.cardView}> { /* CSS 25 */ }
                                    <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                <FRMCard
                                    imgSrc="/assets/images/course-cover/frmi-quantitative-analysis.jpg" 
                                    title={"Quantitative Analysis\n"} 
                                    content1="15 Chapters" 
                                    content2="Detailed Notes"
                                    link="/frm-i/frm-i-test-series"
                                />
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                <FRMCard 
                                    imgSrc="/assets/images/course-cover/frmi-financial-markets-and-products.jpg" 
                                    title={"Financial Markets and Products"} 
                                    content1="20 Chapters" 
                                    content2="Detailed Notes" 
                                    link="/frm-ii/frm-ii-test-series"
                                />
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                <FRMCard
                                    imgSrc="/assets/images/course-cover/frmi-valuation-and-risk-models.jpg" 
                                    title={"Valuation and Risk Models\n"} 
                                    content1="16 Chapters" 
                                    content2="Detailed Notes" 
                                    link="/frm-i/frm-i-test-series"
                                />
                                </div>
                                </div>
                                </div>
                            </div>
                        </div>
                                
                                </div>
                            
                                {!user ? (<div className="col-lg-4 order-0 order-lg-1">
                        {/* <div className="sticky-card">  */}
                            {/* <div className="test-fees-card sticky-card"> */}
                                {/* <ul className="test-fees-card-heading">
                                    <li>Test Fee </li>
                                    <li>₹9,999</li>
                                </ul> */}
                                {/* <div className="sticky-card"> */}
                                <div className={style.testFeesCardOuter}> { /* CSS 15 */ }
                                    {/* <div className="test-fees-card-inner"> */}
                                        <h3>{courseObj.courseName}</h3>
                                        <img src="/assets/images/chapters/foundationsOfRiskManagement.jpg" alt="" srcset=""/>
                                    {/* </div> */}
                                
                                <div className="test-fees-btn mt-24"> { /* CSS 16 */ }
                                    <a href="#">Enroll Now</a>
                                    {/* <a href="{{route('login')}}">Enroll Now</a> */}
                                </div>
                                </div>
                                {/* </div> */}
                            {/* </div> */}
                        {/* </div> */}
                        </div>) : (
                            <div className="col-lg-4 order-0 order-lg-1">
                              <div className={style.testProgressCard}>
                                <div className="test-progress-card-outer">
                                  <h3>{courseObj.courseName}</h3>
                                  <p><span>Tests Completed: {5}/10</span></p>
                                  <div className={style.progressBar}>
                                    {/* Tests Progress Bar */}
                                    <div className={style.progress} style={{ width: `50%`, backgroundColor: '#2d7a46' }}></div>
                                  </div>
                                  <p><span>Quizzes Completed: {10}/50</span></p>
                                  <div className={style.progressBar}>
                                    {/* Quizzes Progress Bar */}
                                    <div className={style.progress} style={{ width: `${20}%`, backgroundColor: '#2d7a46' }}></div>
                                  </div>
                                  <p><span>{10}% Completed</span></p>
                                  <div className={style.progressBar}>
                                    {/* Total Progress Bar */}
                                    <div className={style.progress} style={{ width: `${10}%`, backgroundColor: '#2d7a46' }}></div>
                                  </div>
                                  <div className={style.testProgressBtn}>
                                    {/* CSS 16 */}
                                    <a href="#" className={style.reAttemptBtn}>Continue Learning</a>
                                  </div>
                                </div>
                              </div>
                            
                          </div>
                          
                        )}


                                {/* ** */}
                            </div>
                    {/* </div> */}
                    {/* <div className="col-lg-4 order-0 order-lg-1">
                        <div className="ag-hero-figure ">
                            <div className="test-fees-card">
                                <ul className="test-fees-card-heading">
                                    <li>Test Fee </li>
                                    <li>₹9,999</li>
                                </ul>
                                <div className="test-fees-card-outer">
                                    <div className="test-fees-card-inner">
                                        <h4>Master FRM I with Integrated Test Series!</h4>
                                        <img src="/assets/images/testimonial-img-1.png" alt="" srcset=""/>
                                    </div>
                                </div>
                                <div className="test-fees-btn mt-24">
                                    <a href="#">Enroll Now</a>
                                    {/* <a href="{{route('login')}}">Enroll Now</a> */}
                                </div>
                            </div>
                            <Footer2/>
                        </div>

  )
}

export default Foundation_Of_Risk_Management;