import React from "react";
import { Datagrid, List, TextField, useRecordContext, TextInput, Filter } from "react-admin";
import { useNavigate, useParams } from "react-router-dom";
import MathJaxField from "../Users/MathJaxRendererAdmin";
import { Button } from "react-bootstrap";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { DataGridWithIndex } from "../DataGridWithIndex";

const ResultFilter = (props) => (
    <Filter {...props}>
      <TextInput label="Question" source="question" />
    </Filter>
  );

const EditButton = () => {
    const record = useRecordContext();
    const navigate = useNavigate();
    const handleEditClick = (questionId) => {
        console.log("Checking from questions");
        navigate(`/admin/questions/${questionId}`);
    };

    return (
        <IconButton onClick={() => handleEditClick(record.id)} color="primary">
            <EditIcon />
        </IconButton>
    )
}

const QuestionsList = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const record = useRecordContext();

    const handleEditClick = (questionId) => {
        navigate(`/questions/${questionId}`);
    };

    return (
        <List resource={`/contents/${id}`} title={`Questions`} filters={<ResultFilter />}>
            {/* <Datagrid bulkActionButtons={false}> */}
            <DataGridWithIndex bulkActionButtons={false}>
                {/* <TextField source="id" label="Sl. No."/> */}
                <MathJaxField source="question" label="Question" />
                <EditButton />
            </DataGridWithIndex>
            {/* </Datagrid> */}
        </List>
    )
}

export default QuestionsList;