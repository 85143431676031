import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const Signup = () => {
    const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const googleRedirectUri = process.env.REACT_APP_GOOGLE_REDIRECT_URI;
    const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${googleClientId}&redirect_uri=${googleRedirectUri}&response_type=code&scope=profile%20email`;
    const baseUrl = process.env.REACT_APP_BASE_URL
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmittingOtp, setIsSubmittingOtp] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [step, setStep] = useState('signin');
    const [otp, setOtp] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setErrors({});

        if (password !== confirmPassword) {
            setErrors({ confirmPassword: 'Passwords do not match' });
            setIsSubmitting(false);
            return;
        }

        try {
            const response = await axios.post(`${baseUrl}/api/otp/send-otp`, {
                email
            });
            if (response.status === 200) {
                setStep('otp')
            }
        } catch (error) {
            setIsSubmitting(false);
            if (error.response && error.response.data) {
                setErrors(error.response.data.errors);
            } else {
                setErrors({ general: 'Something went wrong. Please try again later.' });
            }
        }
    };

    const handleSubmitOtp = async (e) => {
        e.preventDefault();
        setIsSubmittingOtp(true);
        setErrors({});

        try {

            console.log(otp);
            const otpVerificationResponse = await axios.post(`${baseUrl}/api/otp/verify-otp`, {
                email, 
                otp
            })
            console.log('After OTP verification')
            if(otpVerificationResponse.status === 200){
                console.log('Before Signup');
                console.log(email, password, otp);
                const response = await axios.post(`${baseUrl}/api/auth/signup`, {
                    email,
                    password,
                });
                console.log('After signup')
                if (response.status === 200) {
                    localStorage.setItem('authToken', response.data.token);
                    navigate('/public/profile');
                }
                else {
                    console.log(response.data.msg)
                    console.log(response.status)
                }
            }
            else{
                console.log(otpVerificationResponse.data.message)
                setErrors((prevError) => ({
                    ...prevError,
                    otp: otpVerificationResponse.data
                }))
            }
        } catch (error) {
            setIsSubmittingOtp(false);
            console.log(error)
            if (error.response && error.response.data) {
                setErrors({general: error.response.data.message});
            } else {
                setErrors({ general: 'Something went wrong. Please try again later.' });
            }
        }
    };

    return (
        <>
            <button onClick={() => setIsModalOpen(true)} className="open-login-btn">Login</button>

            {isModalOpen && (
                <div className="modal-overlay" onClick={() => setIsModalOpen(false)}>
                    <div className="modal-content-split" onClick={(e) => e.stopPropagation()}>
                        <div className="login-image-section">
                            <img src="/assets/images/vector/man-sitting-on-desk.jpg" alt="Login" className="login-image" />
                        </div>
                        <div className="login-form-section">
                            {(step==='signin') && <form onSubmit={handleSubmit} className="contact-input login-page-box-shadow">
                                <div className="login-title">
                                    <h1>Welcome to Risk Hub</h1>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-11 login-input-bg-color">
                                        <div className="row justify-content-center">
                                            <div className="col-xl-10">
                                                <label>Email Address</label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    placeholder="Enter Your Email"
                                                    required
                                                />
                                                {errors.email && <span className="text-danger">{errors.email}</span>}
                                            </div>
                                            <div className="col-xl-10">
                                                <label>Password</label>
                                                <input
                                                    type="password"
                                                    name="password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    placeholder="Enter Your Password"
                                                    required
                                                />
                                                {errors.password && <span className="text-danger">{errors.password}</span>}
                                            </div>
                                            <div className="col-xl-10">
                                                <label>Confirm Password</label>
                                                <input
                                                    type="password"
                                                    name="confirmPassword"
                                                    value={confirmPassword}
                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                    placeholder="Confirm Your Password"
                                                    required
                                                />
                                                {errors.confirmPassword && <span className="text-danger">{errors.confirmPassword}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {errors.general && <div className="text-danger text-center">{errors.general}</div>}

                                <div className="login-btn">
                                    <button type="submit" disabled={isSubmitting}>
                                        {isSubmitting ? 'Signing up...' : <span className="login-page-btn">Signup</span>}
                                    </button>
                                </div>
                                <div className="or-text">
                                    <hr />
                                    <div className="or">or</div>
                                    <hr />
                                </div>
                                <div className="google-signin">
                                    <a href={googleAuthUrl}>
                                        <img src="assets/images/google.png" alt="Google Sign-In" />
                                        <span>Signup with Google</span>
                                    </a>
                                </div>
                                <div className="signup-now">
                                    <span>
                                        Already have an account? <a className="sign-up-text"><Link to='/login' >Login NOW!</Link></a>
                                    </span>
                                </div>
                            </form>}


                            {(step === 'otp') && <form onSubmit={handleSubmitOtp} className="contact-input login-page-box-shadow">
                                <div className="login-title">
                                    <h1>Welcome to Risk Hub</h1>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-11 login-input-bg-color">
                                        <div className="row justify-content-center">
                                            <div className="col-xl-10">
                                                <label>OTP</label>
                                                <input
                                                    type="password"
                                                    name="otp"
                                                    value={otp}
                                                    onChange={(e) => setOtp(e.target.value)}
                                                    placeholder="Enter Your OTP"
                                                    required
                                                />
                                                {/* {errors.otp && <span className="text-danger">{errors.otp}</span>} */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {errors.general && <div className="text-danger text-center">{errors.general}</div>}

                                <div className="login-btn">
                                    <button type="submit" disabled={isSubmittingOtp}>
                                        {isSubmittingOtp ? 'Signing up...' : <span className="login-page-btn">Submit</span>}
                                    </button>
                                </div>
                            </form>}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Signup;
