import React from "react";
import { List, Datagrid, TextField, TextInput, Filter, DateField, EditButton } from "react-admin";
import { DataGridWithIndex } from "../DataGridWithIndex";

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="User Name" source="user_name" />
    <TextInput label="Email" source="email" />
  </Filter>
);

const UserList = (props) => (
  <List {...props} filters={<UserFilter/>}> {/* Disable checkboxes */}
    {/* <Datagrid bulkActionButtons={false}> */}
      <DataGridWithIndex bulkActionButtons={false}>
        {/* <TextField source="id" label="Sl. No." /> */}
        <TextField source="user_name" label="User Name" />
        <TextField source="email" label="Email" />
        <DateField source="createdAt" label="Joined On" />
        <EditButton />
      </DataGridWithIndex>
    {/* </Datagrid> */}
  </List>
);

export default UserList;
