import React from 'react';
import './WebinarCard.css'

const WebinarCard = () => {
  const [timeLeft, setTimeLeft] = React.useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  // Timer logic to calculate the time left for the webinar
  React.useEffect(() => {
    const calculateTimeLeft = () => {
      const webinarDate = new Date('2024-12-15T00:00:00'); // Example date
      const now = new Date();
      const difference = webinarDate - now;

      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60)
        });
      }
    };

    const timer = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(timer); // Cleanup the interval on component unmount
  }, []);

  return (
    <div className="webinar-card"> {/* CSS 1 */}
      <div className="card-content"> {/* CSS 2 */}
        <h1>Ready to crush your interview? Let’s do it!</h1>
        <p>
          If you’ve made it this far, you must be at least a little curious. Talk to our founding
          team to take the first step toward your goals.
        </p>
        <button className="register-button">Register for our FREE webinar</button>  {/* CSS 3 */}
        <div className="timer"> {/* CSS 4 */}
          <p>NEXT WEBINAR STARTS IN</p>
          <div className="countdown">  {/* CSS 5 */}
            <span>{String(timeLeft.days).padStart(2, '0')}</span>:
            <span>{String(timeLeft.hours).padStart(2, '0')}</span>:
            <span>{String(timeLeft.minutes).padStart(2, '0')}</span>:
            <span>{String(timeLeft.seconds).padStart(2, '0')}</span>
          </div>
        </div>
      </div>
      <img src="/assets/images/vector/man-sitting-on-desk.jpg" alt="Person Cutout" className="person-image" />  {/* CSS 6 */}
      <div className="wave-bg">  { /* CSS 7 */}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#0099ff"
            fillOpacity="1"
            d="M0,288L48,272C96,256,192,224,288,197.3C384,171,480,149,576,165.3C672,181,768,235,864,250.7C960,267,1056,245,1152,250.7C1248,256,1344,288,1392,304L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default WebinarCard;
