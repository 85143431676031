import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    answer: {}
}

const answerReducer = createSlice({
    name: 'answers',
    initialState,
    reducers: {
        setAnswer: (state, action) => {
            const { questionId, option } = action.payload;

            state.answer[questionId] = option;

            console.log('Updated Answers:', state.answer);
        },
        clearAnswer: () => initialState
    }
});

export const { setAnswer, clearAnswer } = answerReducer.actions;
export default answerReducer.reducer