import React from 'react'
import PrimeFeatures from './PrimeFeatures/PrimeFeatures'
import FRMCards from './FRMCards/FRMCards'
import HeroSection from './HeroSection/HeroSection'
import FAQ from './FAQ/FAQ'
import Footer2 from '../Footer/Footer2'

const IntegeratedTestSeries = () => {
  return (
    <div>
      <HeroSection/>
      <FRMCards/>
      <PrimeFeatures/>
      <FAQ/>
      <Footer2/>
    </div>
  )
}

export default IntegeratedTestSeries
