import React, { useState, useEffect } from 'react';
import Footer2 from '../Footer/Footer2';
import axios from 'axios';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Settings.css';
import { FaPlus } from 'react-icons/fa';

const baseUrl = process.env.REACT_APP_BASE_URL;

function Settings() {
  const [formData, setFormData] = useState({
    name: '',
    user_name: '',
    contact_number: '',
    country: '',
    exam_date: '',
    email: '',
    profile_image: '/assets/images/cart/avatarmenprofile.png',
  });
  const dummyExams = [
    { exam_id: '1', exam_name: 'Exam 1' },
    { exam_id: '2', exam_name: 'Exam 2' },
    { exam_id: '3', exam_name: 'Exam 3' },
    { exam_id: '4', exam_name: 'Exam 4' },
  ];  

  const [editingField, setEditingField] = useState('');
  const user = useSelector((state) => state.auth.user);
  // State for saved exam-date pairs. Each pair is an object: { exam: { id, name }, date }
  const [pairs, setPairs] = useState(user.exam_dates ?? []);

  // State for available exam options. Starts as all dummy exams.
  const [availableExams, setAvailableExams] = useState(dummyExams);

  // State for new pair being added
  const [newPair, setNewPair] = useState({ exam_id: '', date: '' });

  // State for editing an existing pair
  const [editIndex, setEditIndex] = useState(null);
  const [editDate, setEditDate] = useState('');
  const [addPair, setAddPair] = useState(false);

  useEffect(() => {
    fetchUserDetails();
  }, []);

  useEffect(() => {
    setAvailableExams((prev) =>
      prev.filter(
        (exam) => !pairs.some((pair) => pair.exam.exam_id === exam.exam_id)
      )
    );
  }, [pairs]);

  const handleNewPairChange = (e) => {
    const { name, value } = e.target;
    setNewPair((prev) => ({ ...prev, [name]: value }));
  };

  const sendPairsToBackend = async (pairsData) => {
    const userId = user.id;
    const response = await axios.put(
      `${baseUrl}/api/users/${userId}`,
      {
        ['exam_dates']: pairsData
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('authToken'),
        },
      }
    );
    console.log("Post adding pairs:", response.data);
  };

  const toggleAddPair = () => {
    setAddPair(!addPair);
  }

  // Handler to add new exam-date pair
  const handleAddPair = () => {
    if (newPair.exam_id && newPair.date) {
      // Find the exam object in availableExams
      const examObj = availableExams.find((exam) => exam.exam_id === newPair.exam_id);
      if (examObj) {
        // Add the new pair
        const updatedPairs = [
          ...pairs,
          { exam: examObj, date: newPair.date },
        ];

        setPairs(updatedPairs); 
        sendPairsToBackend(updatedPairs);
        // Remove this exam from available options
        setAvailableExams((prev) =>
          prev.filter((exam) => exam.exam_id !== newPair.exam_id)
        );
        // Reset newPair input
        setNewPair({ exam_id: '', date: '' });
        toggleAddPair();
      }
    }
  };

  // Handler to initiate editing a pair
  const handleEditPair = (index) => {
    setEditIndex(index);
    setEditDate(pairs[index].date);
  };

  // Handler to save edited date
  const handleSaveEdit = () => {
    if (editIndex !== null) {
      const updatedPairs = pairs.map((pair, index) =>
        index === editIndex ? { ...pair, date: editDate } : pair
      );
      sendPairsToBackend(updatedPairs);
      setPairs(updatedPairs);
      setEditIndex(null);
      setEditDate('');
    }
  };

  const fetchUserDetails = async () => {
    try {
      console.log("User:", user.id);
      const response = await axios.get(`${baseUrl}/api/users/${user.id}`, {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('authToken'),
        },
      });
      const examResponse = await axios(`${baseUrl}/api/module/all-exams`, {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('authToken'),
        },
      });
      console.log("Settings Data:", response.data)
      console.log("Exam Response:", examResponse.data);
      setFormData(response.data);
      setAvailableExams(examResponse.data);
      setPairs(response.data.exam_dates ?? [])
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDateChange = (date) => {
    setFormData({ ...formData, exam_date: date });
  };

  const editField = (field) => {
    setEditingField(field);
  };

  const saveField = async (field, value) => {
    const userId = user.id;
    const response = await axios.put(
      `${baseUrl}/api/users/${userId}`,
      {
        [field]: value
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('authToken'),
        },
      }
    );
    console.log(response);
    setEditingField('');
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = 60;
          canvas.height = 60;
          ctx.drawImage(img, 0, 0, 60, 60);
          const base64Image = canvas.toDataURL('image/png');
          setFormData((prevFormData) => {
            const updatedFormData = { ...prevFormData, profile_image: base64Image };
            console.log('Updated Form Data:', updatedFormData);
            saveField('profile_image', base64Image);
            return updatedFormData;
          });
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const toggle = (e) => {
    const buttons = e.target.parentElement.querySelectorAll('button');
    buttons.forEach((btn) => btn.classList.remove('active'));
    e.target.classList.add('active');
  };

  const handleDeleteAccount = () => {
    if (window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
      console.log('Account deleted');
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString)

    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = String(date.getFullYear())
    return `${day}-${month}-${year}`
  }

  return (
    <>
      <div className="edu-hero-style"> { /* CSS 1 */ }
        <div className="container"> { /* CSS 2 */ }
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <ul className="nav-tags">
                <li><a href="/profile" className="active">Home {'>'} </a></li>
                <li>Settings</li>
              </ul>
            </div>
            <div className="col-lg-2 hidden-content-value"> { /* CSS 4 */ }
              <li>Hidden</li>
            </div>
          </div>

          <div className="row justify-content-center mt-24">
            <div className="col-2 account-bg">
              <h2>Settings</h2>
              <div className="account-manage-card"> { /* CSS 5 */ }
                <h4><a href="#">Account</a></h4>
                <br />
                <h4><a href="#manage-updates">Manage Updates</a></h4>
              </div>
            </div>

            <div className="col-lg-6">
              <h4>Account</h4>
              <div className="edit-profile-card">
                <div className="setting-cards">
                 <form>
                    {/* Profile Image */}
                    <div className="form-group-container">
                      <div className="form-group">
                        <img src={formData.profile_image ? formData.profile_image :  '/assets/images/cart/avatarmenprofile.png' } alt="Profile" id="profile-image-preview" style={{ maxWidth: '50px' }} />
                      </div>
                      <div className="form-group">
                        <input type="file" accept="image/*" id="profile_image" onChange={handleImageChange} style={{ display: 'none' }} />
                        <button type="button" className="edit-profile-pic-btn" onClick={() => document.getElementById('profile_image').click()}>Edit Profile Pic</button> { /* CSS 9 */ }
                      </div>
                    </div>
                    <div className="setting-profile-bottom-line"></div> { /* CSS 10 */ }

                    {/* Name Field */}
                    <div className='form-group-container'>
                    <div className="form-group">
                      <p><b>Name</b></p>
                      {editingField === 'name' ? (
                        <>
                        <p>{formData.name}</p>
                        <input type="text" name="name" className="form-control" value={formData.name} onChange={handleInputChange} />
                        </>
                      ) : (
                        <p>{formData.name}</p>
                      )}
                    </div>
                    <div className="form-group">
                      {editingField === 'name' ? (
                        <button type="button" className="save-profile-btn" onClick={() => saveField('name', formData.name)}>Save Name</button>
                      ) : (
                        <button type="button" className="edit-profile-pic-btn" onClick={() => editField('name')}>Edit Name</button>
                      )} { /* CSS 11 */ }
                    </div>
                    </div>
                    <div className="setting-profile-bottom-line"></div>

                    {/* Username Field */}
                    <div className="form-group-container">
                      <div className="form-group">
                        <p><b>Username</b></p>
                        {editingField === 'user_name' ? (
                          <>
                          <p>{formData.user_name}</p>
                          <input type="text" name="user_name" className="form-control" value={formData.user_name} onChange={handleInputChange} />
                          </>
                        ) : (
                          <p>{formData.user_name}</p>
                        )}
                      </div>
                      <div className="form-group">
                        {editingField === 'user_name' ? (
                          <button type="button" className="save-profile-btn" onClick={() => saveField('user_name', formData.user_name)}>Save Username</button>
                        ) : (
                          <button type="button" className="edit-profile-pic-btn" onClick={() => editField('user_name')}>Edit Username</button>
                        )}
                      </div>
                    </div>
                    <div className="setting-profile-bottom-line"></div>

                    <div className="form-group-container">
                      <div className="form-group">
                        <p><b>Email</b></p>
                        <p>{formData.email}</p>
                      </div>
                    </div>
                    <div className="setting-profile-bottom-line"></div>

                    <div className="form-group-container">
                      <div className="form-group">
                        <p><b>Country</b></p>
                        {editingField === 'country' ? (
                          <>
                          <p>{formData.country}</p>
                          <input type="text" name="country" className="form-control" value={formData.country} onChange={handleInputChange} />
                          </>
                        ) : (
                          <p>{formData.country}</p>
                        )}
                      </div>
                      <div className="form-group">
                        {editingField === 'country' ? (
                          <button type="button" className="save-profile-btn" onClick={() => saveField('country', formData.country)}>Save Country</button>
                        ) : (
                          <button type="button" className="edit-profile-pic-btn" onClick={() => editField('country')}>Edit Country</button>
                        )}
                      </div>
                    </div>
                    <div className="setting-profile-bottom-line"></div>
                    
                    {/* <div className="form-group-container">
                      <div className="form-group">
                        <p><b>Exam Date</b></p>
                        {editingField === 'exam_date' ? (
                          <>
                          <DatePicker
                            selected={formData.exam_date ? new Date(formData.exam_date) : null}
                            onChange={handleDateChange}
                            dateFormat="dd-MM-yyyy"
                            className="form-control"
                          />
                          </>
                        ) : (
                          <p>{formatDate(formData.exam_date)}</p>
                        )}
                      </div>
                      <div className="form-group">
                        {editingField === 'exam_date' ? (
                          <button type="button" className="save-profile-btn" onClick={() => saveField('exam_date', formData.exam_date)}>Save Exam Date</button>
                        ) : (
                          <button type="button" className="edit-profile-pic-btn" onClick={() => editField('exam_date')}>Edit Exam Date</button>
                        )}
                      </div>
                    </div>
                    <div className="setting-profile-bottom-line"></div> */}
                    <div className="exam-form">
                    <p><b>Exam Dates</b></p>
      {/* New Pair Form */}
      {addPair && <div className='form-group-container'>
        {/* <h3>Add Exam</h3> */}
        <div className="form-group">
        <div className="exam-pairs-container">
        <div style={{marginRight:"10px"}}>
          <select
            name="exam_id"
            className="form-control"
            value={newPair.exam_id}
            onChange={handleNewPairChange}
          >
            <option value="" disabled>
              Exam
            </option>
            {availableExams.map((exam) => (
              <option key={exam.exam_id} value={exam.exam_id}>
                {exam.exam_name}
              </option>
            ))}
          </select>
        </div>
        <div>
        {/* <input
            type="date"
            name="date"
            className="form-control"
            value={newPair.date}
            onChange={handleNewPairChange}
          /> */}
        <>
        <DatePicker
          selected={newPair.date ? new Date(newPair.date) : null} 
          onChange={(date) => handleNewPairChange({ target: { name: "date", value: date.toISOString() } })} 
          dateFormat="dd-MM-yyyy"
          className="form-control"
        />
          </>
        </div>
        </div>
        </div>
        <div className='form-group'>
        <button className="save-profile-btn" type="button" onClick={handleAddPair}>Save</button>
        </div>
      </div>}

      {/* Display Saved Pairs */}
      {pairs.length > 0 && 
      (
        <div>
          {pairs.map((pair, index) => (
            <div key={pair.exam.id} className="form-group-container">
              <div className='form-group'>
              <b style={{color: "#000", marginRight: "5px"}}>{pair.exam.exam_name}:</b>
              {editIndex === index ? (
                <>
                  {/* <input
                    type="date"
                    className="form-control"
                    value={editDate}
                    onChange={(e) => setEditDate(e.target.value)}
                  /> */}
                  <DatePicker
                    selected={editDate ? new Date(editDate) : null}
                    onChange={(date) => setEditDate(date.toISOString())}
                    dateFormat="dd-MM-yyyy"
                    className="form-control"
                  />
                </>
              ) : (
                <>
                  <span style={{color: "#000"}}>{formatDate(pair.date)}</span>
                  
                </>
              )}
              </div>
              <div className='form-group'>
              {editIndex === index ? (
                <>
                  <button className="save-profile-btn" type="button" onClick={handleSaveEdit}>Save</button>
                </>
              ) : (
                <>
                  <button className="save-profile-btn" type="button" onClick={() => handleEditPair(index)}>
                    Edit
                  </button>
                </>
              )}
                </div>
            </div>
          ))}
        </div>
      )
      }
      {availableExams.length > 0 && !addPair && <div><button className="add-exam-btn" type="button" onClick={toggleAddPair}><FaPlus/> Add New Date</button></div>}
    </div>
    <div className="setting-profile-bottom-line"></div>
                    {/* Delete Account */}
                    <div className="form-group delete-account-text">
                      <button type="button" className="btn btn-danger" onClick={handleDeleteAccount}>
                        Delete Account
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="manage-upates-section" id="manage-updates">
                <h4>Manage Updates</h4>
                <div className="manage-upates-card"> { /* CSS 12 */ }
                  <div className="toggle-group"> { /* CSS 13 */ }
                    <label>Email</label>
                    <div className="toggle-switch"> { /* CSS 14 */ }
                      <button className="active" onClick={toggle}>ON</button>
                      <button onClick={toggle}>OFF</button>
                    </div>
                  </div>
                  <div className="toggle-group">
                    <label>Push notifications</label>
                    <div className="toggle-switch">
                      <button className="active" onClick={toggle}>ON</button>
                      <button onClick={toggle}>OFF</button>
                    </div>
                  </div>
                  <div className="toggle-group">
                    <label>SMS</label>
                    <div className="toggle-switch">
                      <button className="active" onClick={toggle}>ON</button>
                      <button onClick={toggle}>OFF</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer2/>
    </>
  );
}

export default Settings;