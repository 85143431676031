import { Create, required, SimpleForm, TextInput, email } from "react-admin";

const UserCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput source="name" label="Name" validate={required()} />
            <TextInput source="user_name" label="Username" validate={required()} />
            <TextInput source="email" label="Email" validate={[required(), email()]} />
            <TextInput source="contact_number" label="Contact Number" />
        </SimpleForm>
    </Create>
)

export default UserCreate;