// filepath: /Users/uchitkumar/Desktop/risk-hub-frontend/src/redux/auth/programSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  programName: ''
};

const programReducer = createSlice({
  name: 'programDetails',
  initialState,
  reducers: {
    setProgramDetails: (state, action) => {
      console.log('Setting Program Details:', action.payload);
      state.programName = action.payload;
    },
    clearProgramName: () => {
      console.log('Clearing Program Details');
      return initialState;
    },
  },
});

export const { setProgramDetails, clearProgramName } = programReducer.actions;

export default programReducer.reducer;