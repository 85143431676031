import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNotify } from "react-admin";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ExampleBlockPlugin from "./CustomExampleBlockPlugin";

const baseUrl = process.env.REACT_APP_BASE_URL;
const apiUrl = `${baseUrl}/api/admin`;

// const customConfig = {
//   extraPlugins: [ExampleBlockPlugin],
//   toolbar: [
//     "heading",
//     "|",
//     "bold",
//     "italic",
//     "underline",
//     "|",
//     "exampleBlock", // Add Example Block button
//     "|",
//     "undo",
//     "redo"
//   ]
// };

// const customConfig = {
//   extraPlugins: [ExampleBlockPlugin], // Register the custom plugin
//   toolbar: [
//     "heading",
//     "|",
//     "bold",
//     "italic",
//     "underline",
//     "|",
//     "exampleBlock", // Add our custom button
//     "|",
//     "undo",
//     "redo"
//   ]
// };


const customConfig = { 
  licenseKey: 'GPL',
  heading: {
    options: [
      { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
      { model: "heading1", view: "h1", title: "Heading 1", class: "ck-heading_heading1" },
      { model: "heading2", view: "h2", title: "Heading 2", class: "ck-heading_heading2" },
      {
        model: "example",
        view: {
          name: "div",
          classes: "example-block"
        },
        title: "Example Block",
        class: "ck-heading_example"
      }
    ],
  },
}


// const customConfig = {
//   licenseKey: 'GPL',
//   extraPlugins: [customExampleBlockPlugin], // Add our custom plugin
//   toolbar: [
//     "heading", 
//     "|",
//     "bold", 
//     "italic", 
//     "underline",
//     "|",
//     "exampleBlock", // Our custom button
//     "|",
//     "undo", 
//     "redo"
//   ],
//   heading: {
//     options: [
//       { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
//       { model: "heading1", view: "h1", title: "Heading 1", class: "ck-heading_heading1" },
//       { model: "heading2", view: "h2", title: "Heading 2", class: "ck-heading_heading2" },
//     ],
//   },
// };


// function customExampleBlockPlugin(editor) {

//   editor.model.schema.register('exampleBlock', {
//     allowWhere: '$block',
//     allowContentOf: '$root',
//   });

//   editor.conversion.for('upcast').elementToElement({
//     model: 'exampleBlock',
//     view: {
//       name: 'div',
//       classes: 'example-block',
//     },
//   });

//   editor.conversion.for('downcast').elementToElement({
//     model: 'exampleBlock',
//     view: (modelElement, { writer }) => {
//       return writer.createContainerElement('div', { class: 'example-block' });
//     },
//   });

//   editor.commands.add('toggleExampleBlock', {
//     execute() {
//       const model = editor.model;
//       const selection = model.document.selection;

//       if (selection.isCollapsed) {
//         return; // Ignore empty selection
//       }

//       model.change(writer => {
//         const selectedBlocks = Array.from(selection.getSelectedBlocks());
//         let existingBlock = null;

//         // Check if an exampleBlock already exists
//         for (const block of selectedBlocks) {
//           if (block.is('element', 'exampleBlock')) {
//             existingBlock = block;
//             break;
//           }
//         }

//         if (existingBlock) {
//           // Merge all selected blocks into the existing block
//           for (const block of selectedBlocks) {
//             if (block !== existingBlock) {
//               writer.append(block, existingBlock);
//               writer.remove(block);
//             }
//           }
//         } else {
//           // Create a new exampleBlock and wrap all selected content
//           const exampleBlock = writer.createElement('exampleBlock');

//           for (const block of selectedBlocks) {
//             writer.append(block, exampleBlock);
//             writer.remove(block);
//           }

//           model.insertContent(exampleBlock);
//         }
//       });
//     },
//   });

//   editor.ui.componentFactory.add('exampleBlock', locale => {
//     const button = new editor.ui.button({
//       label: 'Example Block',
//       icon: null, // Optional: You can add an icon here
//       withText: true,
//       tooltip: true,
//     });
  
//     button.on('execute', () => {
//       editor.execute('toggleExampleBlock');
//     });
  
//     return button;
//   });
// }



const ChaptersEdit = () => {
  const { id } = useParams(); // Get chapter ID from the route
  const [title, setTitle] = useState("");
  const [chapterContent, setChapterContent] = useState("");
  const [questions, setQuestions] = useState([]);
  const [isChapterAdded, setIsChapterAdded] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [payloadQuestions, setPayloadQuestions] = useState([]);

  useEffect(() => {
    const fetchChapter = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const response = await axios.get(`${apiUrl}/chapters/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("Chapter Content Response:", response);
        const chapterContent = response.data.data.content;
        const chapterData = response.data.data.chapter;
        if(chapterData)
          setIsChapterAdded(true);
        console.log("Data of Content Edit:", chapterData);

        const questionsData = response.data.data.questions;
        const sortedQuestionsData = [...questionsData].sort((a, b) => a.id - b.id);
        console.log("Questions Data:", sortedQuestionsData);
        setQuestions(sortedQuestionsData);
        setCurrentIndex(sortedQuestionsData.length);
        const questionsArray = sortedQuestionsData.map(questionItem => ({"id": questionItem.id}));
        console.log("Questions Id Array:", questionsArray);
        setPayloadQuestions(questionsArray);
        setChapterContent(chapterData.content);
        setTitle(chapterData.chapter_name);
        console.log("Title:", chapterData.chapter_name);
        } catch (error) {
        console.log(error);
      }
    };

    fetchChapter();
  }, [id]);


  const handleSave = async () => {
    try {
      // Construct the payload to match the API's expected format
      const token = localStorage.getItem('authToken');
      const payload = {
        chapter_name: title,
        chapterContent,
        questions: payloadQuestions
      };

      console.log("Payload:", payload);

      // Submit the updated chapter to the backend
      const response = await axios.put(`${apiUrl}/chapters/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        alert("Chapter updated successfully!");
      } else {
        alert("Failed to update the chapter.");
      }
    } catch (error) {
      console.error("Error saving chapter:", error);
      alert("Failed to save the chapter. Check the console for details.");
    }
  };

  // Add a new question
  const handleAddQuestion = () => {
    setQuestions((prevQuestions) => [
      ...prevQuestions,
      {
        id: currentIndex,
        question: "",
        options: { optionA: "", optionB: "", optionC: "", optionD: "" },
        correctOption: "A",
        explanation: "",
      },
    ]);
    setPayloadQuestions((prevQuestions) => [
      ...prevQuestions,
      {
        id: currentIndex,
        question: "",
        options: { optionA: "", optionB: "", optionC: "", optionD: "" },
        correctOption: "A",
        explanation: "",
      },
    ]);
    setCurrentIndex(prevIndex => prevIndex+1);
  };


  const handleQuestionSave = (index, updatedData) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index] = { ...updatedQuestions[index], ...updatedData };
    setQuestions(updatedQuestions);
    const updatedPayloadQuestions = [...payloadQuestions];
    updatedPayloadQuestions[index] = {...updatedPayloadQuestions[index], ...updatedData};
    setPayloadQuestions(updatedPayloadQuestions);
  };

  useEffect(() => {
    console.log("Payload Questions Content:", payloadQuestions);
  }, [payloadQuestions]);

  // Update options for a specific question
  const handleOptionChange = (e, index, optionName) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].options[optionName] = e.target.value;
    setQuestions(updatedQuestions);
    const updatedPayloadQuestions = [...questions];
    updatedPayloadQuestions[index].options[optionName] = e.target.value;
    setPayloadQuestions(updatedPayloadQuestions);
  };


  return (
    <div style={{ padding: "20px" }}>
      {chapterContent}
      <h1>Content Creator</h1>

      <div style={{ marginTop: "20px" }}>
        <label>Title</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          style={{ width: "100%" }}
        />
      </div>

      {(isChapterAdded || chapterContent ) && (
        <div style={{ padding: "20px", border: "1px solid #ccc", marginBottom: "20px" }}>
          <h2>Edit Chapter Content</h2>
          <CKEditor
            editor={ClassicEditor}
            data={chapterContent}
            config={customConfig}
            onChange={(event, editor) => {
              const data = editor.getData();
              setChapterContent(data);
            }}
          />
        </div>
      )}

      {questions.map((item, index) => (
        <div key={index} style={{ padding: "20px", border: "1px solid #ccc", marginBottom: "20px" }} >
          <h2>Edit Question {index + 1}</h2>
          <CKEditor
            editor={ClassicEditor}
            data={item.question}
            config={customConfig}
            onChange={(event, editor) => {
              const data = editor.getData();
              handleQuestionSave(index, { question: data });
            }}
          />

          {/* Options */}
          <div style={{ marginTop: "20px" }}>
            <h3>Options</h3>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px" }}>
              {["A", "B", "C", "D"].map((option) => (
                <div key={option}>
                  <label>Option {option}</label>
                  <input
                    type="text"
                    value={item.options[`option${option}`]}
                    onChange={(e) => handleOptionChange(e, index, `option${option}`)}
                    style={{ width: "100%" }}
                  />
                </div>
              ))}
            </div>

            {/* Correct Option Selector */}
            <div style={{ marginTop: "10px" }}>
              <label>Correct Option</label>
              <select
                value={item.correctOption}
                onChange={(e) => handleQuestionSave(index, { correctOption: e.target.value })}
                style={{ width: "100%", padding: "5px" }}
              >
                <option value="A">Option A</option>
                <option value="B">Option B</option>
                <option value="C">Option C</option>
                <option value="D">Option D</option>
              </select>
            </div>
          </div>

          {/* Explanation */}
          <CKEditor
            editor={ClassicEditor}
            data={item.explanation}
            config={customConfig}
            onChange={(event, editor) => {
              const data = editor.getData();
              handleQuestionSave(index, { explanation: data });
            }}
          />
        </div>
      ))}

      {!isChapterAdded && (
        <button
          onClick={() => setIsChapterAdded(true)}
          style={{ marginBottom: "20px", marginRight: "10px" }}
        >
          Add Chapter
        </button>
      )}
      <button onClick={handleAddQuestion} style={{ marginBottom: "20px" }}> Add Question </button>
      <button onClick={handleSave} style={{ marginBottom: "20px" }}> Save </button>
    </div>
  );


};

export default ChaptersEdit;