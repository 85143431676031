import React, { useState, useEffect } from 'react';
import Footer2 from '../Footer/Footer2';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import MathJaxRenderer from '../MathJaxRenderer/MathJaxRenderer';

const ResultsPage = () => {
    // Dummy data for testing
    const module = {
        id: 1,
        module_name: 'FRM I Integerated Test Series',
        program: {
            id: 101,
            program_name: 'Test 3'
        }
    };
    
    const totalQuestions = 15;
    const correctCount = 15;
    const percentage = 75;
    const attemptedQuestions = 18;
    const marks = 75;
    const rank = 1;
    const answer = useSelector((state) => state.answer.answer)
    const baseUrl = process.env.REACT_APP_BASE_URL
    const [questionsPaginated, setQuestionsPaginated] = useState([]);
    const { moduleId } = useParams();
    console.log(answer)
    // questionsPaginated = [
    //     {
    //         question: { question: "What is React?", correct_option: 'A', option_A: "A library", option_B: "A framework", option_C: "A language", option_D: "None of the above", explanation: "React is a library for building user interfaces." },
    //         userAnswer: 'A'
    //     },
    //     {
    //         question: { question: "What is JSX?", correct_option: 'B', option_A: "JavaScript XML", option_B: "Java Syntax Extension", option_C: "JSON XML", option_D: "None of the above", explanation: "JSX is a syntax extension for JavaScript, often used with React.JSX is a syntax extension for JavaScript, often used with React.JSX is a syntax extension for JavaScript, often used with React.JSX is a syntax extension for JavaScript, often used with React.JSX is a syntax extension for JavaScript, often used with React.JSX is a syntax extension for JavaScript, often used with React.JSX is a syntax extension for JavaScript, often used with React.JSX is a syntax extension for JavaScript, often used with React.JSX is a syntax extension for JavaScript, often used with React.JSX is a syntax extension for JavaScript, often used with React.JSX is a syntax extension for JavaScript, often used with React.JSX is a syntax extension for JavaScript, often used with React." },
    //         userAnswer: 'C'
    //     },
    //     {
    //         question: { question: "What is JSX?", correct_option: 'B', option_A: "JavaScript XML", option_B: "Java Syntax Extension", option_C: "JSON XML", option_D: "None of the above", explanation: "JSX is a syntax extension for JavaScript, often used with React." },
    //         userAnswer: 'B'
    //     },
    //     {
    //         question: { question: "What is JSX?", correct_option: 'B', option_A: "JavaScript XML", option_B: "Java Syntax Extension", option_C: "JSON XML", option_D: "None of the above", explanation: "JSX is a syntax extension for JavaScript, often used with React." },
    //         userAnswer: 'B'
    //     },
    //     {
    //         question: { question: "What is JSX?", correct_option: 'B', option_A: "JavaScript XML", option_B: "Java Syntax Extension", option_C: "JSON XML", option_D: "None of the above", explanation: "JSX is a syntax extension for JavaScript, often used with React." },
    //         userAnswer: 'B'
    //     },
    //     {
    //         question: { question: "What is JSX?", correct_option: 'B', option_A: "JavaScript XML", option_B: "Java Syntax Extension", option_C: "JSON XML", option_D: "None of the above", explanation: "JSX is a syntax extension for JavaScript, often used with React." },
    //         userAnswer: 'B'
    //     },
    //     {
    //         question: { question: "What is JSX?", correct_option: 'B', option_A: "JavaScript XML", option_B: "Java Syntax Extension", option_C: "JSON XML", option_D: "None of the above", explanation: "JSX is a syntax extension for JavaScript, often used with React." },
    //         userAnswer: 'B'
    //     },
    //     {
    //         question: { question: "What is JSX?", correct_option: 'B', option_A: "JavaScript XML", option_B: "Java Syntax Extension", option_C: "JSON XML", option_D: "None of the above", explanation: "JSX is a syntax extension for JavaScript, often used with React." },
    //         userAnswer: ''
    //     },
    //     {
    //         question: { question: "What is JSX?", correct_option: 'B', option_A: "JavaScript XML", option_B: "Java Syntax Extension", option_C: "JSON XML", option_D: "None of the above", explanation: "JSX is a syntax extension for JavaScript, often used with React." },
    //         userAnswer: 'B'
    //     },
    //     {
    //         question: { question: "What is JSX?", correct_option: 'B', option_A: "JavaScript XML", option_B: "Java Syntax Extension", option_C: "JSON XML", option_D: "None of the above", explanation: "JSX is a syntax extension for JavaScript, often used with React." },
    //         userAnswer: 'B'
    //     },
    //     {
    //         question: { question: "What is JSX?", correct_option: 'B', option_A: "JavaScript XML", option_B: "Java Syntax Extension", option_C: "JSON XML", option_D: "None of the above", explanation: "JSX is a syntax extension for JavaScript, often used with React." },
    //         userAnswer: 'B'
    //     },
    //     {
    //         question: { question: "What is JSX?", correct_option: 'B', option_A: "JavaScript XML", option_B: "Java Syntax Extension", option_C: "JSON XML", option_D: "None of the above", explanation: "JSX is a syntax extension for JavaScript, often used with React." },
    //         userAnswer: 'B'
    //     },
    //     {
    //         question: { question: "What is JSX?", correct_option: 'B', option_A: "JavaScript XML", option_B: "Java Syntax Extension", option_C: "JSON XML", option_D: "None of the above", explanation: "JSX is a syntax extension for JavaScript, often used with React." },
    //         userAnswer: 'B'
    //     },
    //     {
    //         question: { question: "What is JSX?", correct_option: 'B', option_A: "JavaScript XML", option_B: "Java Syntax Extension", option_C: "JSON XML", option_D: "None of the above", explanation: "JSX is a syntax extension for JavaScript, often used with React." },
    //         userAnswer: 'C'
    //     },
    //     {
    //         question: { question: "What is JSX?", correct_option: 'B', option_A: "JavaScript XML", option_B: "Java Syntax Extension", option_C: "JSON XML", option_D: "None of the above", explanation: "JSX is a syntax extension for JavaScript, often used with React." },
    //         userAnswer: ''
    //     }
    // ];

    const questionsPerPage = 5;
    const [currentPage, setCurrentPage] = useState(1);
    const [showExplanation, setShowExplanation] = useState(Array(questionsPaginated.length).fill(false));
    const [filter, setFilter] = useState('all');
    const [currentQuestions, setCurrentQuestions] = useState(questionsPaginated);
    // const [lastPage, setLastPage] = useState(Math.ceil(currentQuestions.length / questionsPerPage))
    const lastPage = Math.ceil(totalQuestions / questionsPerPage)

    const handlePageChange = (page) => setCurrentPage(page);
    const handleShowExplanation = (index) => {
        const updatedShowExplanation = [...showExplanation]
        updatedShowExplanation[index] = !updatedShowExplanation[index] 
        setShowExplanation(updatedShowExplanation);
    }

    // const filterQuestions = (questions) => {
    //     console.log(filter)
    //     const val = questions.filter((qa) => {
    //         if(filter === 'correct') return qa.question.correct_option === qa.userAnswer;
    //         if(filter === 'incorrect') return qa.question.correct_option !== qa.userAnswer;
    //         if(filter === 'unattempted') return !qa.userAnswer
    //         return true;
    //     });
    //     console.log(val);
    //     return val;
    // };

    // const currentQuestions = filterQuestions(questionsPaginated).slice(
    //     (currentPage - 1) * questionsPerPage,
    //     currentPage * questionsPerPage
    // );

    useEffect(() => {
        const fetchResultData = async () => {
            try{
                const token = localStorage.getItem('authToken');
                if(!token)
                {
                    console.log('Token not found');
                }
                const response = await axios.post(`${baseUrl}/api/users/get-results`,{
                        "userAnswersSubmitted": answer,
                        "moduleId": moduleId,
                        },
                        {
                            headers: {
                                'x-auth-token': token,
                            }
                        })
                console.log('Results Response:', response);
                console.log('Results Data:', response.data);
                const mergedData = response.data.mergedData
                console.log('Merged Data:', mergedData);
                setQuestionsPaginated(mergedData);
                console.log('Results:', questionsPaginated);
            } catch(err) {
                console.log(err);
            }
        }
        fetchResultData();
    }, [])

    useEffect(() => {
        const updatedFilterQuestions = questionsPaginated.filter((qa) => {
            if(filter === 'correct') return qa.question.correct_option === qa.userAnswer;
            if(filter === 'incorrect') return qa.userAnswer && qa.question.correct_option !== qa.userAnswer;
            if(filter === 'unattempted') return !qa.userAnswer
            return true;
        });

        

        setCurrentQuestions(updatedFilterQuestions.slice(
            (currentPage - 1) * questionsPerPage,
            currentPage * questionsPerPage)
        )

        // setLastPage(Math.ceil(currentQuestions.length / questionsPerPage))
    }, [filter, currentPage, currentQuestions]);


    return (
        <div className="edu-hero-style">
            <div className="container">
                <div className="col-lg-5 test">
                    <div className="sub-menu-bar all-test-navtags col-lg-12 justify-content-start">
                        <ul className="nav-tags">
                            <li><a className="active"><Link to='/public/profile'>Home &gt; </Link></a></li>
                            <li><a className="active"><Link to='/public/frm-i/frm-i-integrated-test-series/all/1'>{module.program.program_name} &gt; </Link></a></li>
                            <li>{module.module_name}</li>
                        </ul>
                    </div>
                </div>

                <div className="row justify-content-center align-items-center">
                    <div className="col-lg-10 test-start-hero-content">
                        <div className="edu-hero-content">
                            <div>
                                <h2 className="edu-section-title text-center">{module.program.program_name} - {module.module_name}</h2>
                            </div>
                            <div className="test-submited-all-subheding">
                                <div className="test-submited-subheding">
                                    <h4>Performance Insights</h4>
                                    <div className="test-submited-subheading">
                                        <ul>
                                            <li>🔢 Total Questions: {totalQuestions}</li>
                                            <li>✅ Correct: {correctCount}</li>
                                            <li>📈 Percentile: {percentage}%</li>
                                        </ul>
                                        <ul>
                                            <li>📝 Attempted: {attemptedQuestions}</li>
                                            <li>🎯 Your Marks: {marks}</li>
                                            <li>🏆 Rank: {rank}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="test-submit-heading-image">
                                    <img src="./assets/images/pyfinimg/test-cover.png" alt="Test Cover" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="row col-lg-10" >
                        <div className="filter-section">
                        <label>
                            <input
                            type="radio"
                            checked={filter === 'all'}
                            onChange={() => setFilter('all')}
                            />All
                        </label>
                        <label>
                            <input
                            type="radio"
                            checked={filter === 'correct'}
                            onChange={() => setFilter('correct')}
                            />Correct
                        </label>
                        <label>
                            <input
                            type="radio"
                            checked={filter === 'incorrect'}
                            onChange={() => setFilter('incorrect')}
                            />Incorrect
                        </label>
                        <label>
                            <input
                            type="radio"
                            checked={filter === 'unattempted'}
                            onChange={() => setFilter('unattempted')}
                            />Unattempted
                        </label>
                    </div>
                    </div>
                </div>
            </div>
            {/* Test Answer Key Section */}
            <div className="test-answer-key">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="answer-key-border col-lg-10">
                            <div className="test-answer-main-card">
                                <h3 className="test-answer-key-heading">Answer Key</h3>
                                {currentQuestions.map((qa, index) => (
                                    <div key={index} className="test-answer-key-card row">
                                        <div className="test-answer-key-questions">
                                            <p className="full-width-text">Q. {(currentPage - 1) * questionsPerPage + index + 1} <MathJaxRenderer content={qa.question.question} /></p>
                                            <div className="test-answer-key-options">
                                                <div className="test-answer-key-options">
                                                    {['A', 'B', 'C', 'D'].map(optionKey => {
                                                        const option = `option_${optionKey}`;
                                                        const isSelected = (qa.userAnswer === optionKey);
                                                        const isCorrect = (qa.question.correct_option === optionKey);
                                                        const optionClass = isSelected && isCorrect ? 'correct-answer' :
                                                            isSelected && !isCorrect ? 'incorrect-answer' : 
                                                            !isSelected && isCorrect ? 'correct-answer' : '';

                                                        return (
                                                            <div className="test-answer-key-options">
                                                                <label key={optionKey} className={`test-answer-key-option ${optionClass}`}>
                                                                    <input type="radio" name={`answer${index}`} value={optionKey} disabled />
                                                                    <span className={`custom-radio ${optionClass}`}></span>
                                                                    {qa.question[option]}
                                                                </label>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="hide-answre-btn">
                                            <button className="toggle-explanation" onClick={() => handleShowExplanation(index)}>{showExplanation[index] ? 'Hide Explanation':'Show Explanation'}</button>
                                            <div className="hidden-answre" style={showExplanation[index] ? {display: 'block'} : {}}>{qa.question.explanation}</div>
                                        </div>
                                        <div className="answer-key-bottom-line"></div>
                                    </div>
                                ))}
                            </div>                            
                            <div className="pagination-details">
                                Showing Questions {(currentPage-1) * currentQuestions.length + 1} to {(currentPage) * currentQuestions.length} of {currentQuestions.length}   
                            </div>




                            {/* Pagination Section */}
                            {/* <div className="pagination">
                                <button disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>
                                    &laquo; Prev
                                </button>
                                {[...Array(lastPage).keys()].map(i => (
                                    <button key={i + 1} onClick={() => handlePageChange(i + 1)} className={currentPage === i + 1 ? 'active' : ''}>
                                        {i + 1}
                                    </button>
                                ))}
                                <button disabled={currentPage === lastPage} onClick={() => handlePageChange(currentPage + 1)}>
                                    Next &raquo;
                                </button>
                            </div> */}

                            <div className="pagination">
                                {lastPage > 1 && (
                                    <ul className="pagination">
                                        {/* Previous Page Link */}
                                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                            <a
                                                className="page-link"
                                                href="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (currentPage > 1) handlePageChange(currentPage - 1);
                                                }}
                                                aria-label="Previous"
                                            >
                                                <span aria-hidden="true">&laquo;</span>
                                            </a>
                                        </li>

                                        {/* Display pages dynamically */}
                                        {[...Array(lastPage).keys()].map((_, i) => {
                                            const pageNumber = i + 1;
                                            if (
                                                pageNumber <= 5 ||
                                                (pageNumber >= currentPage - 1 && pageNumber <= currentPage + 1) ||
                                                pageNumber === lastPage
                                            ) {
                                                return (
                                                    <li
                                                        key={pageNumber}
                                                        className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}
                                                    >
                                                        <a
                                                            className="page-link"
                                                            href="#"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                handlePageChange(pageNumber);
                                                            }}
                                                        >
                                                            {pageNumber}
                                                        </a>
                                                    </li>
                                                );
                                            } else if (pageNumber === 6 && currentPage < lastPage - 1) {
                                                return (
                                                    <li key="ellipsis" className="page-item disabled">
                                                        <span className="page-link">...</span>
                                                    </li>
                                                );
                                            }
                                            return null;
                                        })}

                                        {/* Next Page Link */}
                                        <li className={`page-item ${currentPage === lastPage ? 'disabled' : ''}`}>
                                            <a
                                                className="page-link"
                                                href="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (currentPage < lastPage) handlePageChange(currentPage + 1);
                                                }}
                                                aria-label="Next"
                                            >
                                                <span aria-hidden="true">&raquo;</span>
                                            </a>
                                        </li>
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer2/>
        </div>
    );
};

export default ResultsPage;
