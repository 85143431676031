import React from 'react'
import HeadComponent from '../Partials/HeadComponent'
import NavBar from '../NavBar/NavBar'
import Footer from '../Footer/Footer'
import RegisterSection from '../Registration/RegistrationSection'
import Testimonials from '../Testimonials/Testimonials'
import OurPrograms from '../OurPrograms/OurPrograms'
import WebinarCard from '../WebinarCard/WebinarCard'
import TimerCard from '../TimerCard/TimerCard'
import CompanyBanner from '../CompanyBanner/CompanyBanner'
import HeadingCarousal from '../HeadingCarousal/HeadingCarousal'
import './HomePage.css'
import Footer2 from '../Footer/Footer2'
import FRMCard from '../Integerated-test-series/FRMCard/FRMCard'


function HomePage() {
  return (
    <div>
        <HeadingCarousal/>
      <div>
        <div class="edu-hero-style"> { /* CSS 1 */ }
        <div class="container"> { /* CSS 2 */ }
            <div class=" justify-content-lg-between pb-5 pb-lg-0 item-alignment"> { /* CSS 3 */ }
                <div class="col-lg-12 order-1 order-lg-0">
                    <div class="edu-hero-content"> { /* CSS 4 */ }
                        <h2 class="edu-hero-title">Upskill Your Career
                            <p>Stay Ahead of the Curve with Industry-Relevant Finance Skills</p>
                        </h2>
                        {/* <div class="edu-hero-btn">
                            <a href="http://t.me/FRM_I" target="_blank" class="edu-btn-fill">Join Our Community</a>
                        </div> */}
                    </div>
                </div>

                {/* <div class="col-lg-12 col-md-10 py-lg-5 py-4 order-0 order-lg-1">
                    <div class="ag-hero-figure">
                        <img src="/assets/images/education/edu-hero-figure.png" alt="" class="img-fulid"/> */}
                        {/* <img src="{{asset('assets/images/education/edu-hero-figure.png')}}" alt="" class="img-fulid"> */}

                        {/* <div class="florting-mail-label">
                            <div class="icon"><i class="bi bi-geo-alt-fill"></i></div>

                            <h6>Congratulations!</h6>
                            <p>You are on track</p>
                        </div>
                        <div class="florting-mail-label-two">
                            <div class="icon"><i class="bi bi-heart"></i></div>
                            <h4>3k+</h4>
                            <p>Happy Students</p>
                            <img src="/assets/images/education/avater-group.png" alt=""/>
                        </div> */}
                    {/* </div> */}
                {/* </div> */}
            </div>
        </div>
    </div>

        {/* <OurPrograms/> */}
        <div className="row align-items-center justify-content-lg-between">
        <div className="featured-area positioning"> { /* CSS 22 */ }
                            <div className="container"> { /* CSS 23 */ }
                                <div className="row">
                                <div className="col-12">
                                    <div className="hrdd-section-title"> { /* CSS 24 */ }
                                    <h1>Explore our Active Courses</h1>
                                    </div>
                                </div>
                                </div>
                                <div className="row justify-content-left featured-area-card"> { /* CSS 25 */ }
                                <div className="col-lg-3 col-md-5 col-sm-12" data-wow-delay="200ms" data-wow-duration="1500ms">
                                <FRMCard
                                    imgSrc="/assets/images/course-cover/frmi-test-series.jpg" 
                                    title="FRM I Test Series" 
                                    content1="10 tests" 
                                    content2="50 quizzes"  
                                    link="/frm-i/frm-i-test-series"
                                />
                                </div>
                                <div className="col-lg-3 col-md-5 col-sm-12" data-wow-delay="200ms" data-wow-duration="1500ms">
                                <FRMCard 
                                    imgSrc="/assets/images/course-cover/frmii-test-series.jpg" 
                                    title="FRM II Test Series" 
                                    content1="10 tests" 
                                    content2="50 quizzes" 
                                    link="/frm-ii/frm-ii-test-series"
                                />
                                </div>
                                <div className="col-lg-3 col-md-5 col-sm-12" data-wow-delay="200ms" data-wow-duration="1500ms">
                                <FRMCard
                                    imgSrc="/assets/images/course-cover/scr-test-series.png" 
                                    title="SCR Test Series" 
                                    content1="4 tests" 
                                    content2="20 quizzes"  
                                    link="/scr/scr-test-series"
                                />
                                </div>
                                </div>
                            </div>
                        </div>
        </div>

        {/* <Testimonials/> */}

        {/* <WebinarCard/> */}

        {/* <TimerCard/> */}

        {/* <CompanyBanner/> */}

        {/* <RegisterSection/> */}

        <div style={{"padding-top":" 100px"}}></div>

        <Footer2/>
      </div>
    </div>
  )
}

export default HomePage
