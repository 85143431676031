import { Edit, SimpleForm, TextInput, required, email, EditButton } from "react-admin";

const UserEdit = () => (
    <Edit>
        <SimpleForm>
            {/* <TextInput source="id" label="ID" disabled /> */}
            <TextInput source="name" label="Name" validate={required()} />
            <TextInput source="user_name" label="Username" validate={required()} />
            <TextInput source="email" label="Email" validate={[required(), email()]} /> {/* Email is non-editable */}
            <TextInput source="contact_number" label="Contact Number" />
        </SimpleForm>
    </Edit>
);

export default UserEdit;
