import React, { useEffect } from 'react';
import style from './MathJaxRenderer.module.css';

const MathJaxRenderer = ({ content }) => {
  useEffect(() => {
    const loadMathJax = async () => {
      if (window.MathJax) {
        // Wait for MathJax to finish initialization
        window.MathJax.startup.promise.then(() => {
          window.MathJax.typeset();
        });
      } else {
        const script = document.createElement('script');
        script.src = 'https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-mml-chtml.js';
        script.async = true;
        script.onload = () => {
          window.MathJax.startup.promise.then(() => {
            window.MathJax.typeset();
          });
        };
        document.body.appendChild(script);
      }
    };

    loadMathJax();
  }, [content]);

  return (
    <div
      className={style.mathjaxContent}
      dangerouslySetInnerHTML={{ __html: content }}
    ></div>
  );
};

export default MathJaxRenderer;
