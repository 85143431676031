import React, { useState } from 'react';
import './OurPrograms.css'

const OurPrograms = () => {
  const [showContent, setShowContent] = useState(false);
  const [showContent1, setShowContent1] = useState(false);

  const toggleContent = () => setShowContent(!showContent);
  const toggleContent1 = () => setShowContent1(!showContent1);

  return (
    <div className="edu-instructor-style position-relative" id="our-program"> { /* CSS 1 */ }
      <div className="vactor-shape position-absolute d-none d-lg-block end-0 wow animated fadeInRight" data-wow-duration="2000ms"> { /* CSS 2 */ }
        <img src="/assets/images/education/shapes/edu-vactor2.svg" alt="" />
      </div>
      <div className="container"> { /* CSS 3 */ }
        <div className="row justify-content-center">
          <div className="col-xxl-4 col-lg-6">
            <h2 className="edu-section-title" style={{"font-size": "50px"}}>Explore Active Programs</h2> { /* CSS 4 */ }
          </div>
        </div>

        <div className="row gy-4 mt-45">
          <div className="col-lg-12 mentors-card"> { /* CSS 5 */ }
            <div className="col-lg-7 col-md-7">
              <div className="edu-department-single"> { /* CSS 6 */ }
                <div className="edu-department-thumb">
                  <a href="#">
                    <img src="/assets/images/blog/details-img-1.png" alt="" />
                  </a>
                </div>
                <div className="edu-department-disc">
                  <h3 className="department-title"><a href="#">Mentorship and Guidance Program</a></h3>
                  <p>Experience comprehensive support and guidance tailored to your FRM preparation with our Mentorship and Guidance Program!</p>
                  <div className="d-btn" id="toggleButton" onClick={toggleContent}> { /* CSS 7 */ }
                    <a>Learn More</a>
                  </div>

                  {showContent && (
                    <div id="content"> { /* CSS 8 */ }
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mobt-50">
                        <div className="ch-section-title animate fadeInDown" data-wow-delay="100ms" data-wow-duration="1500ms"> { /* CSS 9 */ }
                        <h1><span className="title-mark title-mark-section">Features!</span></h1> { /* CSS 10 */ }
                        </div>
                        <div className="platform-work-wrap mt-50"> { /* CSS 11 */ }
                          <FeatureBox title="📔️ Study Notes" content="Comprehensive study notes covering every aspect of the FRM Part 1 syllabus." />
                          <FeatureBox title="📖️ Revision Notes" content="Condensed summaries of key concepts to aid in quick revision." />
                          <FeatureBox title="📝️ Formula Sheets" content="Handy reference sheets containing essential formulas for quick revision." />
                          <FeatureBox title="💡️ TI BA II Plus Usage Notes" content="Learn how to use the TI BA II Plus calculator effectively." />
                          <FeatureBox title="📆️ Personalized Planner" content="Tailor your study plan to fit your schedule with a personalized planner." />
                          <FeatureBox title="📊️ Gap Analysis" content="Identify areas of strength and weakness with comprehensive gap analysis tools." />
                          <FeatureBox title="🔄️ Continuous Catch-Ups" content="Stay on track with continuous catch-up sessions." />
                          <FeatureBox title="📈️ Performance Feedback" content="Receive personalized feedback on your performance and progress." />
                          <FeatureBox title="📊️ Cost-Benefit Analysis" content="Evaluate the effectiveness of your study efforts with cost-benefit analysis tools." />
                          <FeatureBox title="💡️ Doubts Resolution" content="Get prompt resolution to your doubts and queries from experienced mentors." />
                          <FeatureBox title="👩‍🎓️ Toppers Blogs" content="Gain insights and strategies from top-performing candidates." />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Second Card */}
          <div className="col-lg-12 mentors-card">
            <div className="col-lg-7 col-md-7">
              <div className="edu-department-single">
                <div className="edu-department-thumb">
                  <a href="/testseries">
                    <img src="/assets/images/education/departments/depertment2.png" alt="" />
                  </a>
                </div>
                <div className="edu-department-disc">
                  <h3 className="department-title"><a href="#">Integrated Test Series</a></h3>
                  <p>Embark on your journey to FRM success with our Integrated Test Series Program!</p>
                  <div className="d-btn" id="toggleButton-1" onClick={toggleContent1}>
                    <a>Learn More</a>
                  </div>

                  {showContent1 && (
                    <div id="content-1">
                      <h1><span className="title-mark title-mark-section">Features!</span></h1>
                      <div className="platform-work-wrap mt-50">
                        <FeatureBox title="📚 10 Module Wise Tests" content="Practice with 10 comprehensive tests, each comprising 100 questions." />
                        <FeatureBox title="🔍 50 Topic Wise Quizzes" content="Dive deep into individual topics with our 62 topic-wise quizzes." />
                        <FeatureBox title="🕒 Access Anytime" content="Access our test series anytime, anywhere, allowing you to study at your own pace." />
                        <FeatureBox title="📝 MCQs with Single Choice Correct" content="Experience exam-like questions with a single correct answer." />
                        <FeatureBox title="🚫 No Negative Marking" content="Focus on learning without the fear of penalization." />
                        <FeatureBox title="🎓 Pattern Reflects the Actual Exam" content="Our test series mirrors the pattern and difficulty level of the FRM Part 1 exam." />
                        <div className="d-btn explore-btn"> { /* CSS 12 */ }
                          <a href="/testseries">Explore the Program</a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FeatureBox = ({ title, content }) => (
  <div className="platform-work-box animate fadeInUp" data-wow-delay="100ms" data-wow-duration="1500ms">
    <div className="platform-content">
      <h3>{title}</h3>
      <p>{content}</p>
    </div>
  </div>
);

export default OurPrograms;
