// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .Profile_myInfoDetails__VDOnm{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items:start;
  }
  .Profile_myInfoDetails__VDOnm h5{
    font-size: 27px;
    font-weight: 600;
  }
  .Profile_myInfoDetails__VDOnm p{
    color: black;
    font-weight: 600;
    font-size: 20px;
  }`, "",{"version":3,"sources":["webpack://./src/components/Profile/Profile.module.css"],"names":[],"mappings":"EAAE;IACE,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,iBAAiB;EACnB;EACA;IACE,eAAe;IACf,gBAAgB;EAClB;EACA;IACE,YAAY;IACZ,gBAAgB;IAChB,eAAe;EACjB","sourcesContent":["  .myInfoDetails{\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    align-items:start;\n  }\n  .myInfoDetails h5{\n    font-size: 27px;\n    font-weight: 600;\n  }\n  .myInfoDetails p{\n    color: black;\n    font-weight: 600;\n    font-size: 20px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"myInfoDetails": `Profile_myInfoDetails__VDOnm`
};
export default ___CSS_LOADER_EXPORT___;
