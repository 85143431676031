import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoggedIn: false,
  user: null, // Optional: to store user data
};

const authReducer = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload; // Pass user data if needed
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    clearAuthState: () => initialState,
  },
});

export const { setLoggedIn, setUser, clearAuthState } = authReducer.actions;

export default authReducer.reducer;
