// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MathJaxRenderer_mathjaxContent__SJBmk ul{
    list-style: unset;
}`, "",{"version":3,"sources":["webpack://./src/components/MathJaxRenderer/MathJaxRenderer.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB","sourcesContent":[".mathjaxContent ul{\n    list-style: unset;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mathjaxContent": `MathJaxRenderer_mathjaxContent__SJBmk`
};
export default ___CSS_LOADER_EXPORT___;
