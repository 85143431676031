// import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
// import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
// import Command from '@ckeditor/ckeditor5-core/src/command';

// export default class ExampleBlockPlugin extends Plugin {
//   static get pluginName() {
//     return 'ExampleBlockPlugin';
//   }

//   init() {
//     const editor = this.editor;

//     // Register schema for exampleBlock
//     editor.model.schema.register('exampleBlock', {
//       allowWhere: '$block',
//       allowContentOf: '$root',
//     });

//     // Define conversions
//     editor.conversion.for('upcast').elementToElement({
//       view: {
//         name: 'div',
//         classes: 'example-block',
//       },
//       model: 'exampleBlock',
//     });

//     editor.conversion.for('downcast').elementToElement({
//       model: 'exampleBlock',
//       view: (modelElement, { writer }) => {
//         return writer.createContainerElement('div', { class: 'example-block' });
//       },
//     });

//     // Add the command
//     editor.commands.add('toggleExampleBlock', new ToggleExampleBlockCommand(editor));

//     // Add button to toolbar
//     editor.ui.componentFactory.add('exampleBlock', (locale) => {
//       const button = new ButtonView(locale);

//       button.set({
//         label: 'Example Block',
//         withText: true,
//         tooltip: true,
//       });

//       button.on('execute', () => {
//         editor.execute('toggleExampleBlock');
//       });

//       return button;
//     });
//   }
// }

// // Command for toggling exampleBlock
// class ToggleExampleBlockCommand extends Command {
//   execute() {
//     const editor = this.editor;
//     const model = editor.model;
//     const selection = model.document.selection;

//     if (selection.isCollapsed) return;

//     model.change(writer => {
//       const selectedBlocks = Array.from(selection.getSelectedBlocks());
//       let existingBlock = null;

//       for (const block of selectedBlocks) {
//         if (block.is('element', 'exampleBlock')) {
//           existingBlock = block;
//           break;
//         }
//       }

//       if (existingBlock) {
//         // Merge all selected blocks into existing example block
//         for (const block of selectedBlocks) {
//           if (block !== existingBlock) {
//             writer.append(block, existingBlock);
//             writer.remove(block);
//           }
//         }
//       } else {
//         // Wrap selection in new exampleBlock
//         const exampleBlock = writer.createElement('exampleBlock');

//         for (const block of selectedBlocks) {
//           writer.append(block, exampleBlock);
//           writer.remove(block);
//         }

//         model.insertContent(exampleBlock);
//       }
//     });
//   }
// }
