import React from 'react';
import { Link } from 'react-router-dom';

const FRMCard = ({ imgSrc, title, tests, quizzes, rating, link }) => {
  return (
    <div className="col-lg-3 col-md-5 col-sm-12" data-wow-delay="200ms" data-wow-duration="1500ms">
      <div className="featured-box">
        <div className="featured-icon">
          <img src={imgSrc} alt={title} />
        </div>
        <div className="featured-content">
          <h2>{title}</h2>
          <div className="featured-inner-content">
            <ul>
              <p>📚 {tests} Tests</p>
              <p>📝 {quizzes} Quizzes</p>
            </ul>
            <ul>
              <p>🔢 Performance Analysis</p>
              <p>💬 Discussion Forums</p>
            </ul>
          </div>
          <p className="featured-content-rating"><b>⭐ {rating}</b> <span>(100 Ratings)</span></p>
        </div>
        <div className="enroll-btns">
          {/* <a href="#" className="enroll-btn">Enroll {'>>'}</a> */}
          <Link to={link} className="enroll-btn">Enroll {'>>'}</Link>
        </div>
      </div>
    </div>
  );
};

export default FRMCard;
