import React, { useState, useEffect } from "react";
import Card from "./Card"; // Importing the Card component

const CardList = () => {

    const [cards, setCards] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        const cardData = [
            {
              stars: "5",
              title: "CV Prep for Risk Management",
              time: "60 mins",
              type: "Video Meeting",
              price: "4799",
              description: "A step by step guide to prepare your CV"    
            },
            {
              stars: "5",
              title: "FRM Preparation Plan",
              time: "60 mins",
              type: "Video Meeting",
              price: "4799",
              description: "Optimize your study efforts and ace FRM Exam"    
            },
            {
              stars: "5",
              title: "Career Counselling",
              time: "60 mins",
              type: "Video Meeting",
              price: "4799",
              description: "Career Counseling for Risk Management"    
            },
            {
              stars: "5",
              title: "Interview Prep for Risk Management",
              time: "60 mins",
              type: "Video Meeting",
              price: "4799",
              description: "Boost your chances for clearing Risk Management interviews"    
            },
          ];
        setCards(cardData)
        setLoading(false)
    }, [])

    if(loading){
        return <div>Loading...</div>
    }

  return (
    <>
    <ul className="cards">
      {cards.map((card, index) => (
        <Card
          key={index}
          stars={card.stars}
          time={card.time}
          title={card.title}
          type={card.type}
          price={card.price}
          description={card.description}
        />
      ))}
    </ul>
    </>
  );
};

export default CardList;
