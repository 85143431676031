import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Footer2 from '../Footer/Footer2';

const TestStartPage = ({ module, count, timerDuration }) => {
  const { moduleId } = useParams()
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(`/public/frm-i/frm-i-integrated-test-series/${moduleId}`, { replace:true })
  }
  useEffect(()=>{
    window.history.replaceState(null, '', window.location.href);
  }, [])
  return (
    <div>
      <div className="edu-hero-style">
        <div className="container">
          <div className="col-lg-6 lanuch">
            <div className="sub-menu-bar all-test-navtags col-lg-12 justify-content-start">
              <ul className="nav-tags">
                <li><Link to="/public/profile" className="active">Home &gt; </Link></li>
                {/* <li><Link to={`/alltest/${module.program.id}`} className="active">{module.program.program_name} &gt; </Link></li> */}
                <li><Link to='/public/frm-i/frm-i-integrated-test-series/all/1' className="active">{module.program.program_name} &gt; </Link></li>
                <li>{module.module_name}</li>
              </ul>
            </div>
          </div>
          <div className="row justify-content-center align-items-center star-test-mobile-view">
            <div className="col-lg-8 col-md-10 col-lg-6 test-start-hero-content">
              <div className="sub-menu-bar all-test-navtags"></div>
              <div className="start-test-edu-hero-content">
                <div className="edu-hero-content-inner">
                  <h2 className="edu-section-title text-center">
                    {module.program.program_name}-{module.module_name}
                  </h2>
                  <div className="test-start-subheding">
                    <div className="test-start-subhedings">
                      <ul>
                        <li>📚 {count} Questions</li>
                        <li>🔢 No Negative Marking</li>
                      </ul>
                      <ul>
                        <li>🎯 Multiple Choice Questions</li>
                        <li>⏱️ {timerDuration} minutes</li>
                      </ul>
                      <ul>
                        <img src="./assets/images/pyfinimg/test-cover.png" alt="Test Cover" />
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row justify-content-center align-items-center star-test-mobile-view">
          <div className="col-lg-8 col-md-10 col-lg-6 test-instruction">
            <h3>Test Instructions</h3>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-8 col-md-10 col-lg-6">
            <p className="test-instruction-section-heading">*Please read before starting the test</p>
          </div>
        </div>
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-8 col-md-10 col-lg-6 test-instruction-containt">
            <div>
              <div className="test-instructions">
                <h4>Total Time</h4>
                <p>Total duration of examination is {timerDuration} mins</p>
              </div>
              <div className="test-instructions">
                <h4>Exam Timer</h4>
                <p>
                  The clock has been set at the server and the countdown timer at the top right corner
                  of your screen will display the time remaining for you to complete the exam. When the clock runs out, the exam ends by default - you are not required to end or submit your exam.
                </p>
              </div>
              <div className="test-instructions">
                <h4>Navigating to a Question</h4>
                <p><b>A.</b> Click on a question number in the question palette on the right side of your screen to navigate directly to that specific question without losing your current answer.</p>
                <p><b>B.</b> Click on "Previous" to go to the previous question and "Next" to go to the next question.</p>
                <p><b>C.</b> Click on "Submit" to submit the exam.</p>
              </div>
            </div>
          </div>
          <div className="start-test-btn">
            {/* <Link to={`/testquestions/${module.id}`}>Start {module.module_name} &gt;&gt;</Link> */}
            <a href='#' onClick={handleNavigation}>Start {moduleId} &gt;&gt;</a>
          </div>
        </div>
      </div>
      <Footer2/>
    </div>
  );
};

export default TestStartPage;
