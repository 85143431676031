import React, { useState, useEffect } from 'react';
import Footer2 from '../Footer/Footer2';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setProgramDetails } from '../../redux/auth/programSlice';
import './Profile.css';
import style from './Profile.module.css';
import FRMCard from '../Integerated-test-series/FRMCard/FRMCard';
import { setPaidPrograms } from '../../redux/auth/paidProgramSlice';

// Set up otherPrograms also  
const Profile = () => {
  const [qrSliderVisible, setQrSliderVisible] = useState(false);
  const [purchasedPrograms, setPurchasedPrograms] = useState([])
  const [otherPrograms, setOtherPrograms] = useState([]);
  // const [imageName, setImageName] = useState('')
  const baseUrl = process.env.REACT_APP_BASE_URL
  const user = useSelector((state) => state.auth.user)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleQrSlider = () => {
    setQrSliderVisible(!qrSliderVisible);
  };

  useEffect(() => {
    const navigateUrl = localStorage.getItem('redirectUrl');
    localStorage.removeItem('redirectUrl');
    if(navigateUrl)
    navigate(navigateUrl);
  }, []);

  useEffect(() => {
    
    const fetchModules = async () => {
      const token = localStorage.getItem('authToken')

      if(!token){
        console.log('Token not found')
      }

      try{
        const [purchasedProgramsResponse, otherProgramsResponse] = await Promise.all([
          axios.get(`${baseUrl}/api/users/paid-programs`,{
          headers: {
            'x-auth-token': token,
          }
        }),
        axios.get(`${baseUrl}/api/users/other-programs`,{
          headers: {
            'x-auth-token': token,
          }
        }),
      ])
        
        const paidPrograms = await purchasedProgramsResponse.data.programs
        const unpaidPrograms = await otherProgramsResponse.data.programs
        console.log('Paid Programs:', paidPrograms)
        setPurchasedPrograms(paidPrograms)
        setOtherPrograms(unpaidPrograms)
        const paidProgramIds = paidPrograms.map((program) => program.id);
        console.log("Paid Programs Ids:", paidProgramIds);
        dispatch(setPaidPrograms(paidProgramIds));
      }catch(error){
        console.error(error)
      }
    }
    fetchModules()
  }, [])

  const getImageUrl = (program_name) => {
    let imageName
    if(program_name === 'FRM I Test Series')
      imageName = 'frmi-test-series.jpg'
    else if(program_name === 'FRM II Test Series')
      imageName = 'frmii-test-series.jpg'
    else if(program_name === 'SCR Test Series')
      imageName = 'scr-test-series.png'
    const imageUrl =  `/assets/images/course-cover/${imageName}`
    return imageUrl
  }
 
  const handleProgramView = (program) => {
    console.log("Getting Program Name:", program.program_name);
    console.log("Program View");
    // dispatch(setProgramDetails(program.program_name));
    if(program.program_name === 'FRM I Test Series')
    return `/frm-i/frm-i-test-series/all/${program.id}`;
    else if(program.program_name === 'FRM II Test Series')
    return `/frm-ii/frm-ii-test-series/all/${program.id}`;
    else if(program.program_name === 'SCR Test Series')
    return `/scr/scr-test-series/all/${program.id}`;
  }

  const handleContent1 = (program) => {
    if(program.program_name === 'FRM I Test Series')
    return `10 tests`;
    else if(program.program_name === 'FRM II Test Series')
    return `10 tests`;
    else if(program.program_name === 'SCR Test Series')
    return `4 tests`;
  }

  const handleContent2 = (program) => {
    if(program.program_name === 'FRM I Test Series')
    return `50 quizzes`;
    else if(program.program_name === 'FRM II Test Series')
    return `50 quizzes`;
    else if(program.program_name === 'SCR Test Series')
    return `20 quizzes`;
  }

  return (
    <>
      <div className="edu-hero-style"> { /* CSS 1 */ }
        <div className="container"> { /* CSS 2 */ }
          <div className="sub-menu-bar all-test-navtags"> { /* CSS 3 */ }
            <ul className="nav-tags"> { /* CSS 4 */ }
              <li><a href="#" className="active">Home {'>'} </a></li>
              <li>Profile</li>
            </ul>
            {/* <div className="date-time-scetion">
              <ul>
                <li>Exam Date: {user?.exam_date ? new Date(user.exam_date).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }) : ' '}</li>
                <li>Days Remaining: {user?.days_remaining ?? ' '}</li> 
              </ul>
            </div> */}
          </div>
          <h2 className="edu-section-title text-start">My Info</h2> { /* CSS 6 */ }
          <div className="col-lg-12 justify-content-lg-between align-items-center test-series-hero-content profile-info-card"> { /* CSS 7 */ }
            <div className="col-lg-12 col-md-10 table-text"> { /* CSS 8 */ } 
              <img
                src={user?.profile_image ? `${user.profile_image}` : '/assets/images/cart/avatarmenprofile.png'}
                alt="Profile"
                style={{ maxWidth: '100px' }}
              />
              <div className={style.myInfoDetails}> { /* CSS 9 */ }
                <h5>{user?.name}</h5>
                <p>Joined Date: {user?.logindate ? new Date(user.logindate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }) : ' '}</p>
              </div>
            </div>
          </div>
          <div className="bottom-line"></div> { /* CSS 10 */ }
        </div>
      </div>

      <div className="positioning"> { /* CSS 11 */ }
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="edu-section-title text-start">My Programs</h2> { /* CSS 12 */ }
            </div>
          </div>
          <div className="row featured-area-card profile-my-programs"> { /* CSS 13 */ }
            {purchasedPrograms?.length > 0 ? purchasedPrograms.map(program => (
               <div className="col-lg-3 col-md-5 col-sm-12" data-wow-delay="200ms" data-wow-duration="1500ms">
                                                            <FRMCard key={program.id}
                                                            imgSrc={getImageUrl(program.program_name)} 
                                                            title={program.program_name} 
                                                            content1={handleContent1(program)} 
                                                            content2={handleContent2(program)}
                                                            rating="4.9" 
                                                            link={handleProgramView(program)}
                                                            programName={program.program_name}
                                                            paid={true}
                                                        />
                                                        </div>
              // <div key={program.id} className="col-lg-3 col-md-5 col-sm-12">
              //   <div className="purchesed-card">
              //     <div className="featured-icon">
              //       <img src={getImageUrl(program.program_name)} alt="Program" />
              //     </div>
              //     <div className="featured-content"> { /* CSS 16 */ }
              //       <h2>{program.program_name}</h2>
              //       <div className="purchesed-inner-content"> { /* CSS 17 */ }
              //         <ul>
              //           <p>📚 10 Tests</p>
              //           <p>📝 50 Quizzes</p>
              //         </ul>
              //         <ul>
              //           <p>🔢 Performance Analysis</p>
              //           <p>💬 Discussion Forums</p>
              //         </ul>
              //       </div>
              //       {/* Remove Below line when fixing css */}
              //       <p className="featured-content-rating"></p> { /* CSS 18 */ }
              //     </div>
              //     <div className="enroll-btns"> { /* CSS 19 */ }
              //       {/* <a href={`/test/${program.id}`} className="enroll-btn">View {'>>'}</a> */}
              //       <a className="enroll-btn" onClick={() => handleProgramView(program)}>View {'>>'}</a> { /* CSS 20 */ }
              //       {/* <Link to="/frm-i/frm-i-integrated-test-series/all/1" classname="enroll-btn">View {'>>'}</Link> */}
              //     </div>
              //   </div>
              // </div>
            )) : (
              <p>No purchased programs</p>
            )}
          </div>
          <div className="bottom-line-2"></div> { /* CSS 21 */ }
        </div>
      </div>

      <div className="positioning">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="edu-section-title text-start">Other Programs</h1>
            </div>
          </div>
          <div className="row featured-area-card profile-my-programs">
            {otherPrograms?.length > 0 ? otherPrograms.map(program => (
               <div className="col-lg-3 col-md-5 col-sm-12" data-wow-delay="200ms" data-wow-duration="1500ms">
                                              <FRMCard key={program.id}
                                              imgSrc={getImageUrl(program.program_name)} 
                                              title={program.program_name} 
                                              content1={handleContent1(program)}
                                              content2={handleContent2(program)}
                                              link={handleProgramView(program)}
                                          />
                                          </div>
              // <div key={program.id} className="col-lg-3 col-md-6 col-sm-12">
              //   <div className="featured-box"> { /* CSS 22 */ }
              //     <div className="featured-icon">
              //       <img src="/assets/images/pyfinimg/frm-i-integrated-test-series.png" alt="Program" />
              //     </div>
              //     <div className="featured-content">
              //       <h2>{program.program_name}</h2>
              //       <div className="featured-inner-content">
              //         <ul>
              //           <li>📚 10 Tests</li>
              //           <li>📝 50 Quizzes</li>
              //         </ul>
              //         <ul>
              //           <li>🔢 Performance Analysis</li>
              //           <li>💬 Discussion Forums</li>
              //         </ul>
              //       </div>
              //       {/* Remove Below line when fixing css */}
              //       <p className="featured-content-rating"></p>
              //     </div>
              //     <div className="enroll-btns">
              //       <a href="#" className="enroll-btn" onClick={handleQrSlider}>Enroll Now</a>
              //     </div>
              //   </div>
              // </div>
            )) : (
              <p>No other programs available</p>
            )}
          </div>
        </div>
      </div>

      {qrSliderVisible && (
        <div id="qrSlider" className="slider-visible"> { /* CSS 23 */ }
          <div className="mobile-frame"> { /* CSS 24 */ }
            <div className="qr-content"> { /* CSS 25 */ }
              <button id="closeButton" className="close-btn" onClick={handleQrSlider}>&times;</button> { /* CSS 26 */ }
              <img src="/assets/images/pyfinimg/qr-1.png" alt="QR Code" />
              <div className="qr-code-instructions"> { /* CSS 27 */ }
                <p>Please scan and pay. After payment, share a screenshot to the below WhatsApp Number:</p>
                <p>WhatsApp: 📱 +91 7007 3430 16</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer2/>
    </>
  );
};

export default Profile;
