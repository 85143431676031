// filepath: /Users/uchitkumar/Documents/GitHub/risk-hub-frontend/src/App.js
import React, { useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setLoggedIn, clearAuthState } from './redux/auth/authSlice';
import NavBar from './components/NavBar/NavBar';
import NavBarLogin from './components/NavBarLogin/NavBarLogin';
import HomePage from './components/Home/HomePage';
import Profile from './components/Profile/Profile';
import LoginPage from './components/LoginPage/LoginPage';
import Signup from './components/Signup/Signup';
import Settings from './components/Settings/Settings';
import AdminApp from './components/Admin/AdminApp';
import TestSeries from './components/TestSeries/TestSeries';
import GoogleCallback from './components/GoogleCallback/GoogleCallback';
import TestListPage from './components/TestListPage/TestListPage';
import TestStartPage from './components/TestStartPage/TestStartPage';
import TestPage from './components/TestPage/TestPage';
import ResultsPage from './components/ResultsPage/ResultsPage';
import ScrollToTop from './components/common/scrollTop';
import CoursePage from './components/CoursePage/index.jsx';
import CourseContentPage from './components/CourseContentPage/CourseContentPage.jsx';

const App = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const user = useSelector((state) => state.auth.user);

  // useEffect(() => {
  //   const token = localStorage.getItem('authToken');
  //   const user = JSON.parse(localStorage.getItem('user'));
  //   if (token && user) {
  //     dispatch(setLoggedIn(user));
  //   }
  // }, [dispatch]);

  return (
    <div className="App" style={{ backgroundColor: '#f7f8f9' }}>
      <Router>
        <ScrollToTop />
        <AppContent isLoggedIn={isLoggedIn} user={user} />
      </Router>
    </div>
  );
};

const AppContent = ({ isLoggedIn, user }) => {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/admin');

  return (
    <>
      {/* {!isAdminRoute ? !isLoggedIn ? <NavBar /> : <NavBarLogin user={user} /> : <></>} */}
      {!isAdminRoute ? <NavBar /> : <></>}
      <Routes>
        <Route path="course" element={<CourseContentPage />} />
        <Route path="/" element={isLoggedIn ? <Navigate to="/profile" /> : <HomePage />} />
        {/* <Route path="/:examType/:seriesType" element={<TestSeries />} /> */}
        <Route path=":examType/course/:seriesType" element={<CoursePage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="signup" element={<Signup />} />
        <Route path="/auth/google/callback" element={<GoogleCallback />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/settings/edit" element={<Settings />} />
        <Route path="/:examType/:seriesType/all/:id" element={<TestSeries />} />
        <Route path="/:examType/:seriesType/launch/test/:id/:moduleId" element={<TestStartPage />} />
        <Route path="/:examType/:seriesType/:id/:moduleId" element={<TestPage />} />
        <Route path="/:examType/:seriesType/:id/:moduleId/submit" element={<ResultsPage />} />
        <Route path="/admin/*" element={
          <Suspense fallback={<div>Loading Admin Dashboard...</div>}>
            <AdminApp />
          </Suspense>
        } />
      </Routes>
    </>
  );
};

export default App;