import React from 'react';
import { Admin, CustomRoutes, Resource } from 'react-admin';
import { Route } from 'react-router-dom';
import UserList from './Users/UserList';
import ResultList from './Results/ResultList';
import UpdateContentList from './UpdateContent/UpdateContentList';
import TransactionList from './Transactions/TransactionList';
import QuestionsList from './Questions/QuestionsList';
import QuestionsEdit from './Questions/QuestionsEdit';
import dataProvider from './dataProvider';
import authProvider from './authProvider';
import { FaPlus, FaHome, FaList, FaDollarSign, FaPen } from 'react-icons/fa';
import UpdateCourseList from './UpdateCourse/UpdateCourseList';
import ChaptersList from './Chapters/ChaptersList';
import ChaptersEdit from './Chapters/ChaptersEdit';
import UserCreate from './Users/UserCreate';
import TransactionCreate from './Transactions/TransactionCreate';
import UserEdit from './Users/UserEdit';
import TransactionEdit from './Transactions/TransactionEdit';

const AdminApp = () => {
  return (
    <Admin  basename="/admin" dataProvider={dataProvider} authProvider={authProvider}>
      <Resource name="users" list={UserList} create={UserCreate} icon={FaHome} />
      <Resource name="results" list={ResultList} icon={FaList} />
      <Resource name="contents" list={UpdateContentList} options={{ label: "Update Test Series" }} icon={FaPlus} />
      <Resource name="lessons" list={UpdateCourseList} options={{ label: "Update Courses" }} icon={FaPen} />
      <Resource name="transactions" list={TransactionList} create={TransactionCreate} edit={TransactionEdit} icon={FaDollarSign} />

      <CustomRoutes>
        <Route path="/contents/:id" element={<QuestionsList />} />
        <Route path="/questions/:id" element={<QuestionsEdit />} />
        <Route path='/lessons/:id' element={<ChaptersList />} />
        <Route path='/chapters/:id' element={<ChaptersEdit />} />
      </CustomRoutes>
    </Admin>
  );
};

export default AdminApp;