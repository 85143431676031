import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';

const NavBar = () => {

  const [menuActive, setMenuActive] = useState(false)

  const toggleMenu = () => {
    setMenuActive(!menuActive)
  }

  return (
    <>
    <nav>
      <div className="header-menu-area eu-menu">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-1 col-sm-6 col-6">
              <div className="logo text-left">
                <Link to="/">
                  <img src="/assets/images/risk-hub.png" alt="Risk-Hub Logo" className="main-logo" />
                </Link> 
              </div>
            </div>
            <div className="col-lg-11 col-sm-6 col-6">
              <a href="javascript:void(0)" className={`hidden-lg hamburger ${menuActive ? 'h-active' : ''}`} onClick={toggleMenu}>
                <span className="h-top"></span>
                <span className="h-middle"></span>
                <span className="h-bottom"></span>
              </a>
              <nav className={`main-nav ${menuActive ? 'slidenav' : ''}`}>
                <div className="logo mobile-ham-logo d-lg-none d-block text-left">
                  <a href="#" className="mobile-logo">
                    <img src="/assets/images/risk-hub.png" alt="Mobile Logo" />
                  </a>
                </div>
                <ul>
                  <li className="has-child-menu">
                    <a href="javascript:void(0)">FRM I</a>
                    {!menuActive && <i class="bx bx-chevron-down"></i>}
                    <i className="fl flaticon-plus">+</i>
                    <ul className="sub-menu">
                      <li><Link to="/frm-i/foundations-of-risk-management">Foundations of Risk Management</Link></li>
                      <li><a href="#">Quantitative Analysis</a></li>
                      <li><a href="#">Financial Markets and Products</a></li>
                      <li><a href="#">Valuation and Risk Models</a></li>
                      <li><a href="#">FRM I Mentorship and Guidance Program</a></li>
                      <li><Link to="frm-i/frm-i-integrated-test-series">FRM I Integrated Test Series</Link></li> 
                    </ul>
                  </li>
                  <li className="has-child-menu">
                    <a href="javascript:void(0)">FRM II</a>
                    <i class="bx bx-chevron-down"></i>
                    <i className="fl flaticon-plus">+</i>
                    <ul className="sub-menu">
                      <li><a href="#">Market Risk Measurement and Management</a></li>
                      <li><a href="#">Credit Risk Measurement and Management</a></li>
                      <li><a href="#">Operational Risk and Resilience</a></li>
                      <li><a href="#">Liquidity Risk</a></li>
                      <li><a href="#">Risk Management and Investment Management</a></li>
                      <li><a href="#">FRM II Mentorship and Guidance Program</a></li>
                      <li><a href="#">FRM II Integrated Test Series</a></li>
                    </ul>
                  </li>
                  <li className="has-child-menu">
                    <a href="javascript:void(0)">Mentorship Programs</a>
                    <i class="bx bx-chevron-down"></i>
                    <i className="fl flaticon-plus">+</i>
                    <ul className="sub-menu">
                      <li><a href="#">FRM I Mentorship and Guidance Program</a></li>
                      <li><a href="#">FRM II Mentorship and Guidance Program</a></li>
                    </ul>
                  </li>
                  <li className="has-child-menu">
                    <i class="bx bx-chevron-down"></i>
                    <Link to="integrated-test-series">Integrated Test Series</Link>
                    <i className="fl flaticon-plus">+</i>
                    <ul className="sub-menu">
                      <li><Link to="frm-i/frm-i-integrated-test-series">FRM I Integrated Test Series</Link></li>
                      <li><Link to="frm-ii/frm-ii-integrated-test-series">FRM II Integrated Test Series</Link></li>
                    </ul>
                  </li>
                  <li className="has-child-menu">
                    <a href="javascript:void(0)">Free Material</a>
                    <i class="bx bx-chevron-down"></i>
                    <i className="fl flaticon-plus">+</i>
                    <ul className="sub-menu">
                      <li><a href="#" target="_blank">Quant Planner</a></li>
                      <li><a href="#" target="_blank">Non-Quant Planner</a></li>
                      <li><a href="#" target="_blank">LOs Analysis</a></li>
                      <li><a href="#" target="_blank">Calculator Usage Notes</a></li>
                      {/* <li><a href="/assets/images/document/FRM I Quant Planner.xlsx" target="_blank">Quant Planner</a></li>
                      <li><a href="/assets/images/document/FRM I Non-Quant Planner.xlsx" target="_blank">Non-Quant Planner</a></li>
                      <li><a href="/assets/images/document/FRM I LOs Analysis - Important Chapters_PyFin Academy.pdf" target="_blank">LOs Analysis</a></li>
                      <li><a href="/assets/images/document/FRM I LOs Analysis - Important Chapters_PyFin Academy.pdf" target="_blank">Calculator Usage Notes</a></li> */}
                    </ul>
                  </li>
                  <li className="header-login-btn">
                    <div>
                      <Link to="/login">Log In</Link>
                    </div>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </nav>
    </>
  );
};

export default NavBar;

// <nav>
//             <div class="header-menu-area eu-menu">
//                 <div class="container">
//                     <div class="row align-items-center  justify-content-between">
//                         <div class="col-lg-1 col-sm-6 col-6">
//                             <div class="logo text-left">
//                                 <a href="{{route('home')}}"><img src="{{asset('assets/images/risk-hub.png')}}" alt="Risk-Hub Logo" class="main-logo">
//                                     <!-- <p class="logo-text">PyFin Academy</p> -->
//                                 </a>
//                             </div>
//                         </div>
//                         <div class="col-lg-11 col-sm-6 col-6">
//                             <a href="javascript:void(0)" class="hidden-lg hamburger">
//                                 <span class="h-top"></span>
//                                 <span class="h-middle"></span>
//                                 <span class="h-bottom"></span>
//                             </a>
//                             <nav class="main-nav">
//                                 <div class="logo mobile-ham-logo d-lg-none d-block text-left">
//                                     <a href="#" class="mobile-logo"><img src="{{asset('assets/images/risk-hub.png')}}" alt=""></a>
//                                 </div>
//                                 <ul>
//                                     <li class="has-child-menu">
//                                         <a href="javascript:void(0)">FRM I</a>
//                                         <i class="fl flaticon-plus">+</i>
//                                         <ul class="sub-menu">
//                                             <li><a href="{{route('foundationsOfRiskManagement')}}">Foundations of Risk Management</a></li>
//                                             <li><a href="#">Quantitative Analysis</a></li>
//                                             <li><a href="#">Financial Markets and Products</a></li>
//                                             <li><a href="#">Valuation and Risk Models</a></li>
//                                             <li><a href="">FRM I Mentorship and Guidance Program</a></li>
//                                             <li><a href="{{route('frm1')}}">FRM I Integrated Test Series</a></li>
//                                         </ul>
//                                     </li>
//                                     <li class="has-child-menu">
//                                         <a href="javascript:void(0)">FRM II</a>
//                                         <i class="fl flaticon-plus">+</i>
//                                         <ul class="sub-menu">
//                                             <li><a href="">Market Risk Measurement and Management</a></li>
//                                             <li><a href="">Credit Risk Measurement and Management</a></li>
//                                             <li><a href=""> Operational Risk and Resilience</a></li>
//                                             <li><a href="">Liquidity Risk</a></li>
//                                             <li><a href="">Risk Management and Investment Management</a></li>
//                                             <li><a href="">FRM II Mentorship and Guidance Program</a></li>
//                                             <li><a href="#">FRM II Integrated Test Series</a></li>
//                                         </ul>
//                                     </li>
//                                     <li class="has-child-menu">
//                                         <a href="javascript:void(0)">Mentorship Programs</a>
//                                         <i class="fl flaticon-plus">+</i>
//                                         <ul class="sub-menu">
//                                             <li><a href="#">FRM I Mentorship and Guidance Program</a></li>
//                                             <li><a href="#">FRM II Mentorship and Guidance Program</a></li>
//                                         </ul>
//                                     </li>
//                                     <li class="has-child-menu">
//                                         <a href="{{route('testseries')}}">Integrated Test Series</a>
//                                         <i class="fl flaticon-plus">+</i>
//                                         <ul class="sub-menu">
//                                             <li><a href="{{route('frm1')}}">FRM I Integrated Test Series</a></li>
//                                             <li><a href="">FRM II Integrated Test Series</a></li>
//                                         </ul>
//                                     </li>
//                                     <li class="has-child-menu">
//                                         <a href="javascript:void(0)">Free Material</a>
//                                         <i class="fl flaticon-plus">+</i>
//                                         <ul class="sub-menu">
//                                             <li><a href="{{asset('assets/images/document/FRM I Quant Planner.xlsx')}}" target="_blank">Quant Planner</a></li>
//                                             <li><a href="{{asset('assets/images/document/FRM I Non-Quant Planner.xlsx')}}" target="_blank">Non-Quant Planner</a></li>
//                                             <li><a href="{{asset('assets/images/document/FRM I LOs Analysis - Important Chapters_PyFin Academy.pdf')}}" target="_blank">LOs Analysis</a></li>
//                                             <li><a href="{{asset('assets/images/document/FRM I LOs Analysis - Important Chapters_PyFin Academy.pdf')}}" target="_blank">Calculator Usage Notess</a></li>
//                                         </ul>
//                                     </li>
//                                     <li class="header-login-btn">
//                                         <div>
//                                             <a href="{{route('login')}}">Log In</a>
//                                         </div>
//                                     </li>
//                                 </ul>
//                             </nav>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </nav>