import React, { useState } from 'react';

const FAQ = () => {
  // Array of FAQs with question and answer
  const faqData = [
    {
      question: "What is the structure and format of the test series?",
      answer: "Competently foster backward-compatible alignments and multimedia-based resources."
    },
    {
      question: "Can I attempt a test multiple times?",
      answer: "Yes, a user can attempt the test multiple times."
    },
    {
      question: "How often are the tests conducted and what is the schedule?",
      answer: "Competently foster backward-compatible alignments and multimedia-based resources."
    },
    {
      question: "What are the costs involved and are there any discounts or refund policies?",
      answer: "Competently foster backward-compatible alignments and multimedia-based resources."
    }
  ];

  // State to manage which FAQs are open (true for open, false for closed)
  const [openFAQ, setOpenFAQ] = useState(faqData.map(() => false));

  // Function to toggle the clicked FAQ
  const toggleFAQ = (index) => {
    // Toggle the FAQ at the clicked index without affecting others
    const newOpenFAQ = [...openFAQ];
    newOpenFAQ[index] = !newOpenFAQ[index];
    setOpenFAQ(newOpenFAQ);
  };

  return (
    <div className="faq-area">
      <div className="container">
        <div className="col-md-12 col-sm-12 col-12">
          <div className="hrdd-section-title">
            <h1>Frequently Asked Questions</h1>
          </div>
        </div>
        <div className="row align-items-center faq-card-bg">
          {/* Left image section */}
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="platform-img">
              <img src="assets/images/education/instructors/ins1.png" alt="FAQ" />
            </div>
          </div>
          {/* Right FAQ section */}
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mobt-50">
            <div className="platform-work-wrap">
              {/* Mapping through FAQ array */}
              {faqData.map((faq, index) => (
                <div
                  key={index}
                  className="platform-work-box"
                  onClick={() => toggleFAQ(index)}
                >
                  <div className="platform-content">
                    <h3>{faq.question}</h3>
                    <span className={`arrow ${openFAQ[index] ? 'rotate' : ''}`}>
                      <i className="bi bi-chevron-down"></i>
                    </span>
                    <p className={openFAQ[index] ? '' : 'hide'}>{faq.answer}</p>
                    <div style={{ width: '100%', borderBottom: '2px black solid', margin: '10px 0' }}></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
