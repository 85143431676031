import React from "react";
import { List, Datagrid, TextField, DateField, EditButton, TextInput, Filter } from "react-admin";
import { DataGridWithIndex } from "../DataGridWithIndex";

const UpdateContentFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Exam" source="exam_name" />
    <TextInput label="Program" source="program_name" />
    <TextInput label="Module" source="module_name" />
  </Filter>
);

const UpdateContentList = (props) => (
  <List {...props} filters={<UpdateContentFilter />}>
    {/* <Datagrid bulkActionButtons={false}> */}
      <DataGridWithIndex bulkActionButtons={false}>
        {/* <TextField source="id" label="Sl. No." /> */}
        <TextField source="exam_name" label="Exam" />
        <TextField source="program_name" label="Program" />
        <TextField source="module_name" label="Module" />
        <EditButton />
      </DataGridWithIndex>
    {/* </Datagrid> */}
  </List>
);

export default UpdateContentList;
