import React from "react";
import PropTypes from "prop-types";
import { useRecordContext } from "react-admin";
import MathJaxRenderer from "../../MathJaxRenderer/MathJaxRenderer";

const MathJaxField = ({ source }) => {
  const record = useRecordContext();
  if (!record || !record[source]) return null;

  return <MathJaxRenderer content={record[source]} />;
};

MathJaxField.propTypes = {
  source: PropTypes.string.isRequired, // Define the source prop for React-Admin
};

export default MathJaxField;
