import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNotify } from "react-admin";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const baseUrl = process.env.REACT_APP_BASE_URL;
const apiUrl = `${baseUrl}/api/admin`;

const QuestionsEdit = () => {
  const { id } = useParams(); // Get question ID from the route
  const [question, setQuestion] = useState("");
  const [explanation, setExplanation] = useState("");
  const [options, setOptions] = useState({
    optionA: "",
    optionB: "",
    optionC: "",
    optionD: "",
  });
  const [correctOption, setCorrectOption] = useState("");
  const notify = useNotify();

  useEffect(() => {
    const fetchQuestion = async () => {
      try {
        const token = localStorage.getItem('authToken');
        const response = await axios.get(`${apiUrl}/questions/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const question = response.data.data.question;
        const explanation = response.data.data.explanation;
        const data = response.data.data;
        setOptions({
          optionA: data.option_A,
          optionB: data.option_B,
          optionC: data.option_C,
          optionD: data.option_D,
        });
        setCorrectOption(data.correct_option);

        setQuestion(question);
        setExplanation(explanation);
      } catch (error) {
        console.log(error);
      }
    };

    fetchQuestion();
  }, [id]);

  const handleOptionChange = (e) => {
    const { name, value } = e.target;
    setOptions((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    try {
      // Construct the payload to match the API's expected format
      const token = localStorage.getItem('authToken');
      const payload = {
        question,
        explanation,
        correct_option: correctOption,
        option_A: options.optionA,
        option_B: options.optionB,
        option_C: options.optionC,
        option_D: options.optionD,
      };

      // Submit the updated question to the backend
      const response = await axios.put(`${apiUrl}/questions/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        alert("Question updated successfully!");
      } else {
        alert("Failed to update the question.");
      }
    } catch (error) {
      console.error("Error saving question:", error);
      alert("Failed to save the question. Check the console for details.");
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <h2>Edit Question</h2>
      <CKEditor
        editor={ClassicEditor}
        data={question}
        config={{licenseKey:'GPL'}}
        onChange={(event, editor) => {
          const data = editor.getData();
          setQuestion(data);
        }}
      />
      <div style={{ marginTop: "20px" }}>
        <h3>Options</h3>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px" }}>
          <div>
            <label>Option A</label>
            <input
              type="text"
              name="optionA"
              value={options.optionA}
              onChange={handleOptionChange}
              style={{ width: "100%" }}
            />
          </div>
          <div>
            <label>Option B</label>
            <input
              type="text"
              name="optionB"
              value={options.optionB}
              onChange={handleOptionChange}
              style={{ width: "100%" }}
            />
          </div>
          <div>
            <label>Option C</label>
            <input
              type="text"
              name="optionC"
              value={options.optionC}
              onChange={handleOptionChange}
              style={{ width: "100%" }}
            />
          </div>
          <div>
            <label>Option D</label>
            <input
              type="text"
              name="optionD"
              value={options.optionD}
              onChange={handleOptionChange}
              style={{ width: "100%" }}
            />
          </div>
        </div>

        {/* Correct Option Selector */}
        <div style={{ marginTop: "10px" }}>
          <label>Change Correct Option</label>
          <select
            value={correctOption}
            onChange={(e) => setCorrectOption(e.target.value)}
            style={{ width: "100%", padding: "5px" }}
          >
            <option value="A">Option A</option>
            <option value="B">Option B</option>
            <option value="C">Option C</option>
            <option value="D">Option D</option>
          </select>
        </div>
      </div>

      <CKEditor
        editor={ClassicEditor}
        data={explanation}
        config={{licenseKey:'GPL'}}
        onChange={(event, editor) => {
          const data = editor.getData();
          setExplanation(data);
        }}
      />
      <button onClick={handleSave} style={{ marginTop: "20px" }}>
        Save
      </button>
    </div>
  );
};

export default QuestionsEdit;