import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  programName: ''
};

const programReducer = createSlice({
  name: 'program',
  initialState,
  reducers: {
    setProgramDetails: (state, action) => {
      state.programName = action.payload; // Pass user data if needed
    },
    clearProgramName: () => initialState,
  },
});

export const { setProgramDetails, clearProgramName } = programReducer.actions;

export default programReducer.reducer;