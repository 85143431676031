import React, { useState, useEffect } from 'react';
import Footer2 from '../Footer/Footer2';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import MathJaxRenderer from '../MathJaxRenderer/MathJaxRenderer';
import './ResultsPage.css';
import { clearAnswer } from '../../redux/auth/answerSlice';

const ResultsPage = () => {
    
    // const totalQuestions = 15;
    const correctCount = 15;
    const percentage = 75;
    const attemptedQuestions = 18;
    const marks = 75;
    const rank = 1;
    const answer = useSelector((state) => state.answer.answer)
    const baseUrl = process.env.REACT_APP_BASE_URL
    const [questionsPaginated, setQuestionsPaginated] = useState([]);
    // console.log(answer)

    const questionsPerPage = 10;
    const [moduleTotalQuestions, setModuleTotalQuestions] = useState(0);
    const [totalQuestions, setTotalQuestions] = useState(0);
    const { examType, seriesType, id, moduleId } = useParams();
    const [ module, setModule ] = useState({})
    const program_name = useSelector((state) => state.programDetails.programName)
    const [programName, setProgramName] = useState(program_name);
    const [currentPage, setCurrentPage] = useState(1);
    const [showExplanation, setShowExplanation] = useState(Array(questionsPaginated.length).fill(false));
    const [filter, setFilter] = useState('all');
    const [currentQuestions, setCurrentQuestions] = useState(questionsPaginated);
    const [filterQuestions, setFilterQuestions] = useState(questionsPaginated);
    const [result, setResult] = useState({});
    const lastPage = Math.ceil(totalQuestions / questionsPerPage)
    const location = useLocation();
    const dispatch = useDispatch();

    const handlePageChange = (page) => setCurrentPage(page);
    const handleShowExplanation = (index) => {
        const updatedShowExplanation = [...showExplanation]
        updatedShowExplanation[index] = !updatedShowExplanation[index] 
        setShowExplanation(updatedShowExplanation);
    }

    useEffect(()=> {
        const fetchModule = async () => {
          const token = localStorage.getItem('authToken');
          if(token)
          {
            try{
              const response = await axios.get(`${baseUrl}/api/module/${moduleId}/info`, {
                headers: {
                  'x-auth-token': token,
                }
              })
              const moduleData = response.data
              setModule(moduleData);
            //   console.log("Module Data", moduleData);
            } catch(err) {
              console.error(err)
            }
          }
        }
    
        fetchModule()
        console.log("Program Name:", program_name);
        setProgramName(program_name);
      }, [])

    useEffect(() => {
        const fetchResultData = async () => {
            try{
                const token = localStorage.getItem('authToken');
                if(!token)
                {
                    console.log('Token not found');
                }
                const response = await axios.post(`${baseUrl}/api/users/get-results`,{
                        "userAnswersSubmitted": answer,
                        "moduleId": moduleId,
                        },
                        {
                            headers: {
                                'x-auth-token': token,
                            }
                        })
                // console.log('Results Response:', response);
                // console.log('Results Data:', response.data);
                const mergedData = response.data.mergedData
                const userResult = response.data.userResult;

                const sortedMergedData = [...mergedData].sort((a, b) => a.question.id - b.question.id);
                console.log("Merged Data:", mergedData);
                console.log("Sorted Data:", sortedMergedData);
                // console.log('Merged Data:', mergedData);
                setQuestionsPaginated(sortedMergedData);
                // console.log("Total Questions:", mergedData.length);
                setTotalQuestions(mergedData.length);
                setModuleTotalQuestions(mergedData.length);
                setResult(userResult);
                // console.log('Results:', questionsPaginated);
            } catch(err) {
                console.log(err);
            }
        }
        fetchResultData();
    }, [])

    useEffect(() => {
        const updatedFilterQuestions = questionsPaginated.filter((qa) => {
            if(filter === 'correct') return qa.question.correct_option === qa.userAnswer;
            if(filter === 'incorrect') return qa.userAnswer && qa.question.correct_option !== qa.userAnswer;
            if(filter === 'unattempted') return !qa.userAnswer
            return true;
        });

        setFilterQuestions(updatedFilterQuestions);
        setCurrentPage(1);
        console.log("Next CLicked");
        console.log("Updated Filter Questions", updatedFilterQuestions);

        // if(module.module_type === 'test')
        // {
        //     setCurrentQuestions(updatedFilterQuestions.slice(
        //     (currentPage - 1) * questionsPerPage,
        //     currentPage * questionsPerPage)
        //     )
        // } else {
        //     setCurrentQuestions(updatedFilterQuestions);
        // }
        // console.log("Current Questions", currentQuestions);

    // }, [filter, currentPage, questionsPaginated]);
    }, [filter, questionsPaginated]);

    useEffect(() => {
        if(module.module_type === 'test')
            {
                setCurrentQuestions(filterQuestions.slice(
                (currentPage - 1) * questionsPerPage,
                currentPage * questionsPerPage)
                )
                console.log("Current Page", currentPage);
                console.log("Current Questions", currentQuestions);
                setTotalQuestions(filterQuestions.length);
            } else {
                setCurrentQuestions(filterQuestions);
            }
    }, [currentPage, filterQuestions]);

    return (
        <div className="edu-hero-style"> { /* CSS 1 */ }
            <div className="container"> { /* CSS 2 */ }
                <div className="col-lg-5 test">
                    <div className="sub-menu-bar all-test-navtags col-lg-12 justify-content-start"> { /* CSS 3 */ }
                        <ul className="nav-tags"> { /* CSS 4 */ }
                            <li><a className="active"><Link to='/profile'>Home &gt; </Link></a></li>
                            <li><a className="active"><Link to={`/${examType}/${seriesType}/all/${id}`}>{programName} &gt; </Link></a></li>
                            <li>{module.module_name}</li>
                        </ul>
                    </div>
                </div>

                <div className="row justify-content-center align-items-center">
                    <div className="col-lg-10 test-start-hero-content"> { /* CSS 5 */ }
                        <div className="edu-hero-content">
                            <div>
                                <h2 className="edu-section-title text-center">{programName} - {module.module_name}</h2>
                            </div>
                            <div className="test-submited-all-subheding"> { /* CSS 6 */ }
                                <div className="test-submited-subheding"> { /* CSS 7 */ }
                                    <h4>Performance Insights</h4>
                                    <div className="test-submited-subheading"> { /* CSS 8 */ }
                                        <ul>
                                            <li>🔢 Total Questions: {moduleTotalQuestions}</li>
                                            <li>✅ Correct: {correctCount}</li>
                                            <li>📈 Percentile: {result.percentile}%</li>
                                        </ul>
                                        <ul>
                                            <li>📝 Attempted: {answer.length}</li>
                                            <li>🎯 Your Marks: {result.marks}</li>
                                            <li>🏆 Rank: {result.rank}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="test-submit-heading-image">
                                    <img src="/assets/images/pyfinimg/test-cover.png" alt="Test Cover" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="row col-lg-10" >
                        <div className="filter-section"> { /* CSS 10 */ }
                        <label>
                            <input
                            type="radio"
                            checked={filter === 'all'}
                            onChange={() => setFilter('all')}
                            />All
                        </label>
                        <label>
                            <input
                            type="radio"
                            checked={filter === 'correct'}
                            onChange={() => setFilter('correct')}
                            />Correct
                        </label>
                        <label>
                            <input
                            type="radio"
                            checked={filter === 'incorrect'}
                            onChange={() => setFilter('incorrect')}
                            />Incorrect
                        </label>
                        <label>
                            <input
                            type="radio"
                            checked={filter === 'unattempted'}
                            onChange={() => setFilter('unattempted')}
                            />Unattempted
                        </label>
                    </div>
                    </div>
                </div>
            </div>
            {/* Test Answer Key Section */}
            <div className="test-answer-key"> { /* CSS 11 */ }
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="answer-key-border col-lg-10">
                            <div className="test-answer-main-card">
                                <h3 className="test-answer-key-heading">Answer Key</h3> { /* CSS 12 */ }
                                {currentQuestions.map((qa, index) => (
                                    <div key={index} className="test-answer-key-card row"> { /* CSS 13 */ }
                                        <div className="test-answer-key-questions"> { /* CSS 14 */ }
                                            <p className="full-width-text">Q. {(currentPage - 1) * questionsPerPage + index + 1} <MathJaxRenderer content={qa.question.question} /></p> { /* CSS 15 */ }
                                            <div className="test-answer-key-options"> { /* CSS 16 */ }
                                                <div className="test-answer-key-options">
                                                    {['A', 'B', 'C', 'D'].map(optionKey => {
                                                        const option = `option_${optionKey}`;
                                                        const isSelected = (qa.userAnswer === optionKey);
                                                        const isCorrect = (qa.question.correct_option === optionKey);
                                                        const optionClass = isSelected && isCorrect ? 'correct-answer' :
                                                            isSelected && !isCorrect ? 'incorrect-answer' : 
                                                            !isSelected && isCorrect ? 'correct-answer' : ''; { /* CSS 17 */ }

                                                        return (
                                                            <div key={optionKey} className="test-answer-key-options">
                                                                <label key={optionKey} className={`test-answer-key-option ${optionClass}`}>
                                                                    <input key={`${optionKey}_input`} type="radio" name={`answer${index}`} value={optionKey} disabled />
                                                                    <span key={`${optionKey}_span_radio`} className={`custom-radio ${optionClass}`}></span> { /* CSS 18 */ }
                                                                    <span key={`${optionKey}_span_option`}>{qa.question[option]}</span>
                                                                </label>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="hide-answre-btn">  { /* CSS 19 */ }
                                            <button className="toggle-explanation" onClick={() => handleShowExplanation(index)}>
                                                {showExplanation[index] ? 'Hide Explanation' : 'Show Explanation'}
                                            </button>
                                            <div className="hidden-answre" style={showExplanation[index] ? { display: 'block' } : {}} dangerouslySetInnerHTML={{ __html: qa.question.explanation }}></div>
                                        </div>
                                        <div className="answer-key-bottom-line"></div> { /* CSS 21 */ }
                                    </div>
                                ))}
                            </div>                            
                            {(module.module_type === 'test') && <><div className="pagination-details">
                                {currentQuestions.length ? `Showing Questions ${(currentPage-1) * questionsPerPage + 1} to ${(currentPage-1) * questionsPerPage + currentQuestions.length} of ${totalQuestions}` : `No Questions Found`}
                            </div>

                            <div className="pagination"> { /* CSS 22 */ }
                                {lastPage > 1 && (
                                    <ul className="pagination">
                                        {/* Previous Page Link */}
                                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}> { /* CSS 23 */ }
                                            <a
                                                className="page-link"
                                                href="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (currentPage > 1) handlePageChange(currentPage - 1);
                                                }}
                                                aria-label="Previous"
                                            > { /* CSS 24 */ }
                                                <span aria-hidden="true">&laquo;</span>
                                            </a>
                                        </li>

                                        {/* Display pages dynamically */}
                                        {[...Array(lastPage).keys()].map((_, i) => {
                                            const pageNumber = i + 1;
                                            if (
                                                pageNumber <= 5 ||
                                                (pageNumber >= currentPage - 1 && pageNumber <= currentPage + 1) ||
                                                pageNumber === lastPage
                                            ) {
                                                return (
                                                    <li
                                                        key={pageNumber}
                                                        className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}
                                                    >
                                                        <a
                                                            className="page-link"
                                                            href="#"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                handlePageChange(pageNumber);
                                                            }}
                                                        >
                                                            {pageNumber}
                                                        </a>
                                                    </li>
                                                );
                                            } else if (pageNumber === 6 && currentPage < lastPage - 1) {
                                                return (
                                                    <li key="ellipsis" className="page-item disabled">
                                                        <span className="page-link">...</span>
                                                    </li>
                                                );
                                            }
                                            return null;
                                        })}

                                        {/* Next Page Link */}
                                        <li className={`page-item ${currentPage === lastPage ? 'disabled' : ''}`}>
                                            <a
                                                className="page-link"
                                                href="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (currentPage < lastPage) handlePageChange(currentPage + 1);
                                                }}
                                                aria-label="Next"
                                            >
                                                <span aria-hidden="true">&raquo;</span>
                                            </a>
                                        </li>
                                    </ul>
                                )}
                            </div> </>}
                        </div>
                    </div>
                </div>
            </div>
            <Footer2/>
        </div>
    );
};

export default ResultsPage;
