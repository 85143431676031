// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner{
    text-align: center;
    padding: 40px;
  }
  
  .banner h1 {
    font-size: 2.5rem;
    margin-bottom: 5rem;
    font-weight: 700;
  }

  .company-logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
  }

  .company-logo {
    width: calc((100% - 160px)/5);
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .company-logo img {
    max-width: 100%;
    max-height: 100px;
    object-fit: contain;
  }`, "",{"version":3,"sources":["webpack://./src/components/CompanyBanner/CompanyBanner.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;EACf;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,SAAS;EACX;;EAEA;IACE,6BAA6B;IAC7B,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,mBAAmB;EACrB","sourcesContent":[".banner{\n    text-align: center;\n    padding: 40px;\n  }\n  \n  .banner h1 {\n    font-size: 2.5rem;\n    margin-bottom: 5rem;\n    font-weight: 700;\n  }\n\n  .company-logos {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    gap: 40px;\n  }\n\n  .company-logo {\n    width: calc((100% - 160px)/5);\n    height: auto;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n  \n  .company-logo img {\n    max-width: 100%;\n    max-height: 100px;\n    object-fit: contain;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
