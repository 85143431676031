import React, { useState } from 'react';
import './CourseContent.css'
import { FaCheckCircle, FaLock } from 'react-icons/fa';
import { useSelector } from 'react-redux';

const CourseContent = () => {
  // Array of FAQs with question and answer

  const user = useSelector((state) => state.auth.user)

  const faqData = [
    {
      question: "The Building Blocks of Risk Management",
      chapters: [{chapterName: "Risk and Risk Management", completed: false}, {chapterName: "Risk Measures", completed: true}, 
        {chapterName: "Expected Loss and Unexpedcted Loss", completed: false}, {chapterName: "Risks and Rewards", completed: true}, 
        {chapterName: "Types of Risks", completed: false}, {chapterName: "Risk Factors", completed: true}, {chapterName: "Revision", completed: true}
      ]
    },
    {
      question: "Financial Risk Management",
      chapters: [{chapterName: "Risk and Risk Management", completed: true}, {chapterName: "Risk Measures", completed: false}, 
        {chapterName: "Expected Loss and Unexpedcted Loss", completed: true}, {chapterName: "Risks and Rewards", completed: true}, 
        {chapterName: "Types of Risks", completed: true}, {chapterName: "Risk Factors", completed: true}, {chapterName: "Revision", completed: false}
      ]
    },
    {
      question: "Governance of Risk Management",
      chapters: [{chapterName: "Risk and Risk Management", completed: true}, {chapterName: "Risk Measures", completed: true}, 
        {chapterName: "Expected Loss and Unexpedcted Loss", completed: false}, {chapterName: "Risks and Rewards", completed: false}, 
        {chapterName: "Types of Risks", completed: false}, {chapterName: "Risk Factors", completed: false}, {chapterName: "Revision", completed: false}
      ]
    },
    {
      question: "Credit Risk Transfer",
      chapters: [{chapterName: "Risk and Risk Management", completed: false}, {chapterName: "Risk Measures", completed: false}, 
        {chapterName: "Expected Loss and Unexpedcted Loss", completed: true}, {chapterName: "Risks and Rewards", completed: true}, 
        {chapterName: "Types of Risks", completed: true}, {chapterName: "Risk Factors", completed: false}, {chapterName: "Revision", completed: false}
      ]
    }
  ];

  // State to manage which FAQs are open (true for open, false for closed)
  const [openFAQ, setOpenFAQ] = useState(faqData.map(() => false));

  // Function to toggle the clicked FAQ
  const toggleFAQ = (index) => {
    // Toggle the FAQ at the clicked index without affecting others
    console.log("Index:", index);
    const newOpenFAQ = [...openFAQ];
    newOpenFAQ[index] = !newOpenFAQ[index];
    setOpenFAQ(newOpenFAQ);
  };

  return (
    <div className="faq-area">
      <div className="container">
        <div className="col-md-12 col-sm-12 col-12">
          <div className="hrdd-section-title">
            <h1>Course Content</h1>
          </div>
        </div>
        {/* <div className="row align-items-center faq-card-bg" style={{"background-color":"#FDF1DB"}}> */}
        <div className="row align-items-center" style={{"background-color":""}}>
          {/* Right FAQ section */}
          {/* <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mobt-50"> */}
            <div className="platform-work-wrap">
              {/* Mapping through FAQ array */}
              {/* className="platform-work-box" */}
              {faqData.map((faq, index) => (
                <div
                  key={index}
                  // className="platform-work-box"
                  onClick={() => toggleFAQ(index)}
                >
                  <div className="platform-content">
                    <div className="circle-wrapper">
                      <div className="circle">
                        <span className="circle-number">{index + 1}</span>
                      </div>
                    </div>

                    <div className="chapter-content">
                    <h3 className="chapter-content-heading"> 
                      {faq.question}
                    <span className={`arrow ${openFAQ[index] ? 'rotate' : ''}`}>
                      <i className="bi bi-chevron-down"></i>
                    </span></h3>
                    {/* <p className={openFAQ[index] ? '' : 'hide'}>{faq.answer}</p> */}
                    <p className={openFAQ[index] ? '' : 'hide'}>
                    <div>
                      {faq.chapters.map((chapter, index) => (
                        <div key={`chapter-${index}`} className="topics">
                          {user ? (chapter.completed ? (<span className="not-completed-circle"></span>) : (<><FaCheckCircle className="completed-circle"/></>)) : (<><FaLock className='chapter-lock-icon'/></>)} <h4>{chapter.chapterName}</h4>
                        </div>
                      ))}
                    </div>
                    </p>
                    <div style={{ width: '90%', borderBottom: '2px #d6d6d6 solid', margin: '20px 0' }}></div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default CourseContent;
