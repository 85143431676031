import React from 'react';
import Modal from '../common/modal';
import './PaymentModal.css';

function PaymentModal({ isOpen, onClose }) {
    
    const handleClose = () => {
        onClose();
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>  
            <div className="modal-content-split">
                {/* Right side with login form */}
                <div className="login-form-section">
                    <form className="login-page-box-shadow">
                        <div className="payment-details">
                            <h1>Interested in purchasing this course? 🎓</h1> 
                            <h1>For payment details and access, please contact us at <span className='email-highlight'>riskhubsupport@gmail.com</span>. We'll get back to you within 24 hours! 🚀</h1>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    );
}

export default PaymentModal;
