import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { clearAuthState } from '../../redux/auth/authSlice';
import { persistor } from '../../store/store';

const NavBarLogin = () => {
    const dispatach = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.auth.user);
    const profileImage = user?.profileImage || '/assets/images/cart/avatarmenprofile.png';

    const [menuActive, setMenuActive] = useState(false);

    const toggleMenu = () => {
        setMenuActive(!menuActive)
    }

    const handleLogout = async () => {
        persistor.pause();
        persistor.flush().then(() => {
        return persistor.purge();
        });
        localStorage.clear();
        dispatach(clearAuthState());
        navigate('/');
    }

    return (
        <header>
            {/* Menu */}
            <nav>
                <div className="header-menu-area eu-menu">
                    <div className="container">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-xxl-3 col-xl-2 col-lg-2 col-sm-6 col-6">
                                <div className="logo text-left">
                                    <Link to="/public/profile">
                                        <img src="/assets/images/risk-hub.png" alt="PyFin Academy Logo" className="main-logo" />
                                    </Link>
                                </div>
                            </div>
                            <div className="col-xl-8 col-xl-9 col-lg-10 col-sm-6 col-6">
                                <button className={`hidden-lg hamburger ${menuActive ? 'h-active' : ''}`} onClick={toggleMenu}>
                                    <span className="h-top"></span>
                                    <span className="h-middle"></span>
                                    <span className="h-bottom"></span>
                                </button>
                                <nav className={`main-nav ${menuActive ? 'slidenav' : ''}`}>
                                    <div className="logo mobile-ham-logo d-lg-none d-block text-left">
                                        {/* <Link to="/" className="mobile-logo"> */}
                                            <img src="/assets/images/risk-hub.png" alt="Mobile Logo" />
                                        {/* </Link> */}
                                    </div>
                                    <ul>
                                        <li className="has-child-menu profile-image">
                                            <a href="#">
                                                <img 
                                                    src={profileImage} 
                                                    alt="Profile" 
                                                    style={{
                                                        maxWidth: '50px',
                                                        width: '50px',
                                                        height: '50px',
                                                        borderRadius: '50%',
                                                        objectFit: 'cover'
                                                    }}
                                                />
                                            </a>
                                            {!menuActive && <i class="bx bx-chevron-down" style={{'margin-top':'8px'}}></i>}
                                            <i className="fl flaticon-plus">+</i>
                                            <ul className="sub-menu">
                                                {/* <li><Link to="/settings">Settings</Link></li> */}
                                                {/* <li><Link to="/profile">My Profile</Link></li> */}
                                                {/* <li><Link to="/logout">Log Out</Link></li> */}
                                                <li><a href="#">Settings</a></li>
                                                <li><a href="#">My Profile</a></li>
                                                <li><a href="#" onClick={handleLogout}>Log Out</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            {/* Menu end */}
        </header>
    );
};

export default NavBarLogin;
