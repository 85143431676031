import React from 'react';
import { Admin, CustomRoutes, Resource } from 'react-admin';
import { Route } from 'react-router-dom';
import UserList from './Users/UserList';
import ResultList from './Results/ResultList';
import UpdateContentList from './UpdateContent/UpdateContentList';
import TransactionList from './Transactions/TransactionList';
import QuestionsList from './Questions/QuestionsList';
import QuestionsEdit from './Questions/QuestionsEdit';
import dataProvider from './dataProvider';
import authProvider from './authProvider';
import { FaPlus, FaHome, FaList, FaDollarSign } from 'react-icons/fa';

const AdminApp = () => {
  return (
    <Admin  basename="/admin" dataProvider={dataProvider} authProvider={authProvider}>
      <Resource name="users" list={UserList} icon={FaHome} />
      <Resource name="results" list={ResultList} icon={FaList} />
      <Resource name="contents" list={UpdateContentList} options={{ label: "Update Contents" }} icon={FaPlus} />
      <Resource name="transactions" list={TransactionList} icon={FaDollarSign} />
      <CustomRoutes>
        <Route path="/admin/contents/:id" element={<QuestionsList />} />
        <Route path="/admin/questions/:id" element={<QuestionsEdit />} />
      </CustomRoutes>
    </Admin>
  );
};

export default AdminApp;