import React from "react";
import { Datagrid, List, TextField, useRecordContext, TextInput, Filter, CreateButton } from "react-admin";
import { useNavigate, useParams } from "react-router-dom";
import MathJaxField from "../Users/MathJaxRendererAdmin";
import { Button } from "react-bootstrap";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { DataGridWithIndex } from "../DataGridWithIndex";
import axios from "axios";

const ResultFilter = (props) => (
    <Filter {...props}>
      <TextInput label="Lessons" source="lesson" />
    </Filter>
  );

const EditButton = () => {
    const record = useRecordContext();
    const navigate = useNavigate();
    const handleEditClick = (chapterId) => {
        console.log("Checking from chapters");
        navigate(`/admin/chapters/${chapterId}`);
    };

    return (
        <IconButton onClick={() => handleEditClick(record.id)} color="primary">
            <EditIcon />
        </IconButton>
    )
}

const ChaptersList = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const record = useRecordContext();
    const baseUrl = process.env.REACT_APP_BASE_URL;

    const handleCreateClick = async () => {
        try{
            const response = await axios.post(`${baseUrl}/api/chapter/create-chapter`, { lesson_id: id });
            window.location.reload();
        } catch(error) {
            console.error(error);
        }
    };

    return (
        <>
        <List resource={`/lessons/${id}`} title={`Chapters`} filters={<ResultFilter />} >
            {/* <Datagrid bulkActionButtons={false}> */}
            <DataGridWithIndex bulkActionButtons={false}>
                {/* <TextField source="id" label="Sl. No."/> */}
                <TextField source="chapter_name" label="Chapter Name"/>
                <MathJaxField source="content" label="Chapter Content" />
                <EditButton />
            </DataGridWithIndex>
            {/* </Datagrid> */}
        </List>
        <div style={{ marginBottom: "1rem" }}>
            <Button onClick={handleCreateClick} variant="primary">
                Create Chapter
            </Button>
        </div>
        </>
    )
}

export default ChaptersList;