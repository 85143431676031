import React from 'react'
import Footer2 from '../Footer/Footer2'

function FRM_II_Test_Series() {
  return (
    <div>
      {/* @extends('layouts.frontend.logout')
        @section('section', 'FRM I')
        @section('content') */}
        <div className="edu-hero-style">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <ul className="nav-tags">
                            <li><a href="{{route('home')}}" className="active">Home {'>'} </a></li>
                            <li><a href="#" className="active">FRM II {'>'} </a></li>
                            <li>FRM II Integrated Test Series</li>
                        </ul>
                    </div>
                    <div className="col-lg-4 hidden-content-value">
                        <li>Hidden</li>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className=" col-lg-6  order-1 order-lg-0">
                        <div className="edu-hero-content ">
                            <h2 className="edu-section-title text-start frm-i-heading">FRM II Integrated Test Series: 2025</h2>
                            <div className="exam-info-card">
                                <ul className="exam-infos">
                                    <h3 className="exam-info">Exam <br/>FRM II</h3>
                                    <h3 className="exam-info">Tests<br/> 10</h3>
                                    <h3 className="exam-info">Quizzes<br/> 50</h3>
                                </ul>
                                <div className="exam-more-info-card">
                                    <p className="exam-more-info-card-head">Test Questions</p>
                                    <ul className="exam-more-info-cards">
                                        <li>Format</li>
                                        <li>: MCQ with single Option Correct</li>
                                    </ul>
                                    <ul className="exam-more-info-cards-2">
                                        <li>Negative Marking </li>
                                        <li> : No</li>
                                    </ul>
                                </div>
                            </div>
                            <h2 className="edu-section-title text-start mt-26  frm-i-heading">What’s Included </h2>
                            <ul className="what-include-content">
                                <li>Curated questions with detailed explanations </li>
                                <li>Timed tests that simulate the actual exam environment</li>
                                <li>Immediate feedback and performance analysis</li>
                                <li>Online forums, Webinars and Doubt-clearing sessions</li>
                            </ul>
                            <h2 className="edu-section-title text-start mt-26 frm-i-heading">Prime Features</h2>
                            <div className="prime-feature-bg">
                                <div className="prime-features mt-26">
                                    <div className="prime-feature">
                                        <h2>Performance Analysis</h2>
                                        <ul>
                                            <li>Discover a treasure of study material for the FRM 2 Exam.</li>
                                            <li>Meticulously curated by industry professionals and designed for </li>
                                        </ul>
                                    </div>
                                    <div className="prime-feature-image">
                                        <img src="assets/images/pyfinimg/performance-analysis.png" alt="Test Anylysis" srcset=""/>
                                    </div>
                                </div>
                                <div className="prime-features mt-26">
                                    <div className="prime-feature">
                                        <h2>Meticulously Designed </h2>
                                        <ul>
                                            <li>Crafted by professionals with experience at JP Morgan, EY, and PwC.</li>
                                            <li>Adapts to yearly changes in the FRM syllabus for up-to-date preparation.</li>
                                        </ul>
                                    </div>
                                    <div className="prime-feature-image">
                                        <img src="assets/images/pyfinimg/expert-design.png" alt="Test Anylysis" srcset=""/>
                                    </div>
                                </div>
                                <div className="prime-features mt-26">
                                    <div className="prime-feature">
                                        <h2>Flexibility</h2>
                                        <ul>
                                            <li>Experience unparalleled support with our expert guidance</li>
                                            <li>Access course content anytime, anywhere and at any device with connectivity</li>
                                        </ul>
                                    </div>
                                    <div className="prime-feature-image">
                                        <img src="assets/images/pyfinimg/flexible.png" alt="Test Anylysis" srcset=""/>
                                    </div>
                                </div>
                                <div className="prime-features mt-26">
                                    <div className="prime-feature">
                                        <h2>24X7 Whatsapp Support</h2>
                                        <ul>
                                            <li>Experience unparalleled support with our expert guidance and</li>
                                            <li>Mental well-being coaching, tailored specifically for FRM exam preparation.</li>
                                        </ul>
                                    </div>
                                    <div className="prime-feature-image">
                                        <img src="assets/images/pyfinimg/whatsapp.png" alt="Test Anylysis" srcset=""/>
                                    </div>
                                </div>
                            </div>
                            <div className="download-syllabus-btn mt-26">
                                <a href="{{asset('assets/pdf/FRM I- ITS- Syllabus and Schedule - Nov 2024.pdf')}} " target="_blank">Download Syllabus <i className="bi bi-download"></i> </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 order-0 order-lg-1">
                        <div className="ag-hero-figure ">
                            <div className="test-fees-card">
                                <ul className="test-fees-card-heading">
                                    <li>Test Fee </li>
                                    <li>₹9,999</li>
                                </ul>
                                <div className="test-fees-card-outer">
                                    <div className="test-fees-card-inner">
                                        <h4>Master FRM II with Integrated Test Series!</h4>
                                        <img src="assets/images/testimonial-img-1.png" alt="" srcset=""/>
                                    </div>
                                </div>
                                <div className="test-fees-btn mt-24">
                                    <a href="#">Enroll Now</a>
                                    {/* <a href="{{route('login')}}">Enroll Now</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* @endsection

        @section('scripts')
        <!-- Additional scripts specific to this view -->
        @endsection */}
        <Footer2/>
    </div>
  )
}

export default FRM_II_Test_Series
