import React, { useState } from 'react';
import Footer2 from '../Footer/Footer2';

function Settings({ user }) {
  const [formData, setFormData] = useState({
    name: user?.name || '',
    user_name: user?.user_name || '',
    contact_number: user?.contact_number || '',
    country: user?.country || '',
    exam_date: user?.exam_date || '',
    email: user?.email || '',
    profile_image: user?.profile_image || 'assets/images/cart/avatarmenprofile.png',
  });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [editingField, setEditingField] = useState('');

  const editField = (field) => {
    setEditingField(field);
  };

  const saveField = (field) => {
    // Handle save logic, for example submitting form data to server
    setEditingField('');
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setFormData({ ...formData, profile_image: reader.result });
      // Optionally, you can submit the form here
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const toggle = (e) => {
    const buttons = e.target.parentElement.querySelectorAll('button');
    buttons.forEach((btn) => btn.classList.remove('active'));
    e.target.classList.add('active');
  };

  const handleDeleteAccount = () => {
    if (window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
      // Submit the delete form
      console.log('Account deleted');
    }
  };

  return (
    <>
      <div className="edu-hero-style">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <ul className="nav-tags">
                <li><a href="/profile" className="active">Home {'>'} </a></li>
                <li>Settings</li>
              </ul>
            </div>
            <div className="col-lg-2 hidden-content-value">
              <li>Hidden</li>
            </div>
          </div>

          <div className="row justify-content-center mt-24">
            <div className="col-2 account-bg">
              <h2>Settings</h2>
              <div className="account-manage-card">
                <h4><a href="#">Account</a></h4>
                <br />
                <h4><a href="#manage-updates">Manage Updates</a></h4>
              </div>
            </div>

            <div className="col-lg-6">
              <h4>Account</h4>
              <div className="edit-profile-card">
                <div className="setting-cards">
                  <form>
                    {/* Profile Image */}
                    <div className="form-group-container">
                      <div className="form-group">
                        <img src={formData.profile_image} alt="Profile" id="profile-image-preview" style={{ maxWidth: '50px' }} />
                      </div>
                      <div className="form-group">
                        <input type="file" accept="image/*" id="profile_image" onChange={handleImageChange} style={{ display: 'none' }} />
                        <button type="button" className="edit-profile-pic-btn" onClick={() => document.getElementById('profile_image').click()}>Edit Profile Pic</button>
                      </div>
                    </div>
                    <div className="setting-profile-bottom-line"></div>

                    {/* Name Field */}
                    <div className='form-group-container'>
                    <div className="form-group">
                      <p><b>Name</b></p>
                      {editingField === 'name' ? (
                        <>
                        <p>{formData.name}</p>
                        <input type="text" name="name" className="form-control" value={formData.name} onChange={handleInputChange} />
                        </>
                      ) : (
                        <p>{formData.name}</p>
                      )}
                    </div>
                    <div className="form-group">
                      {editingField === 'name' ? (
                        <button type="button" className="save-profile-btn" onClick={() => saveField('name')}>Save Name</button>
                      ) : (
                        <button type="button" className="edit-profile-pic-btn" onClick={() => editField('name')}>Edit Name</button>
                      )}
                    </div>
                    </div>
                    <div className="setting-profile-bottom-line"></div>

                    {/* Username Field */}
                    <div className="form-group-container">
                      <div className="form-group">
                        <p><b>Username</b></p>
                        {editingField === 'user_name' ? (
                          <>
                          <p>{formData.user_name}</p>
                          <input type="text" name="user_name" className="form-control" value={formData.user_name} onChange={handleInputChange} />
                          </>
                        ) : (
                          <p>{formData.user_name}</p>
                        )}
                      </div>
                      <div className="form-group">
                        {editingField === 'user_name' ? (
                          <button type="button" className="save-profile-btn" onClick={() => saveField('user_name')}>Save Username</button>
                        ) : (
                          <button type="button" className="edit-profile-pic-btn" onClick={() => editField('user_name')}>Edit Username</button>
                        )}
                      </div>
                    </div>
                    <div className="setting-profile-bottom-line"></div>

                    <div className="form-group-container">
                      <div className="form-group">
                        <p><b>Email</b></p>
                        <p>{formData.email}</p>
                      </div>
                    </div>
                    <div className="setting-profile-bottom-line"></div>

                    <div className="form-group-container">
                      <div className="form-group">
                        <p><b>Country</b></p>
                        {editingField === 'country' ? (
                          <>
                          <p>{formData.country}</p>
                          <input type="text" name="country" className="form-control" value={formData.country} onChange={handleInputChange} />
                          </>
                        ) : (
                          <p>{formData.country}</p>
                        )}
                      </div>
                      <div className="form-group">
                        {editingField === 'country' ? (
                          <button type="button" className="save-profile-btn" onClick={() => saveField('country')}>Save Country</button>
                        ) : (
                          <button type="button" className="edit-profile-pic-btn" onClick={() => editField('country')}>Edit Country</button>
                        )}
                      </div>
                    </div>
                    <div className="setting-profile-bottom-line"></div>
                    
                    <div className="form-group-container">
                      <div className="form-group">
                        <p><b>Exam Date</b></p>
                        {editingField === 'exam_date' ? (
                          <>
                          <p>{formData.exam_date}</p>
                          <input type="text" name="exam_date" className="form-control" value={formData.exam_date} onChange={handleInputChange} />
                          </>
                        ) : (
                          <p>{formData.exam_date}</p>
                        )}
                      </div>
                      <div className="form-group">
                        {editingField === 'exam_date' ? (
                          <button type="button" className="save-profile-btn" onClick={() => saveField('exam_date')}>Save Exam Date</button>
                        ) : (
                          <button type="button" className="edit-profile-pic-btn" onClick={() => editField('exam_date')}>Edit Exam Date</button>
                        )}
                      </div>
                    </div>
                    <div className="setting-profile-bottom-line"></div>

                    {/* Delete Account */}
                    <div className="form-group delete-account-text">
                      <button type="button" className="btn btn-danger" onClick={handleDeleteAccount}>
                        Delete Account
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="manage-upates-section" id="manage-updates">
                <h4>Manage Updates</h4>
                <div className="manage-upates-card">
                  <div className="toggle-group">
                    <label>Email</label>
                    <div className="toggle-switch">
                      <button className="active" onClick={toggle}>ON</button>
                      <button onClick={toggle}>OFF</button>
                    </div>
                  </div>
                  <div className="toggle-group">
                    <label>Push notifications</label>
                    <div className="toggle-switch">
                      <button className="active" onClick={toggle}>ON</button>
                      <button onClick={toggle}>OFF</button>
                    </div>
                  </div>
                  <div className="toggle-group">
                    <label>SMS</label>
                    <div className="toggle-switch">
                      <button className="active" onClick={toggle}>ON</button>
                      <button onClick={toggle}>OFF</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer2/>
    </>
  );
}

export default Settings;
